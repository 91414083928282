import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { useSelector } from "react-redux";
import Icon from "../../components/Icon";
import { Link } from "react-router-dom";

const Footer = ({ shop }) => {
	const language = useSelector((state) => state.language.isVietnamese);

	return (
		<footer>
			<section className="footer-extra p-3">
				<h6 className="text-center">
					{language ? shop.sloganVi : shop.sloganEn}
				</h6>
			</section>

			<section className="footer-main py-4">
				<Container>
					<Row>
						<Col
							xs="12"
							md="8"
							className="d-flex flex-column justify-content-between"
						>
							<div>
								<div
									style={{ fontSize: "1.2rem" }}
									className="text-uppercase mb-3"
								>
									{language
										? "Liên hệ chúng tôi"
										: "Contact us"}
								</div>
								<div>
									<Icon icon="location-dot" /> {shop.address}
								</div>
								<div className="my-2">
									<Icon icon="phone" /> {shop.phonenumber}
								</div>
								<div className="my-2">
									<Icon icon="envelope" /> {shop.email}
								</div>
								<div className="my-2">
									<Icon icon="clock" />{" "}
									{language
										? shop.openningHourVi
										: shop.openningHourEn}
								</div>
							</div>
						</Col>
						<Col xs="12" md="4">
							<iframe
								title="BONNIEKIDS"
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.783082636787!2d150.9352777!3d-33.89524000000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12960c0c5ef0c7%3A0x5546919735508012!2s86%20John%20St%2C%20Cabramatta%20NSW%202166%2C%20Australia!5e0!3m2!1sen!2s!4v1697532241616!5m2!1sen!2s"
								width="100%"
								height="200"
								allowFullScreen=""
								loading="lazy"
								referrerPolicy="no-referrer-when-downgrade"
							/>
						</Col>
					</Row>
				</Container>

				<Link to={"https://www.facebook.com/messages/t/115095618339699"} target="_blank">
					<div class="wrapper">
						<div class="ring">
							<div class="coccoc-alo-phone coccoc-alo-green coccoc-alo-show">
								<div class="coccoc-alo-ph-circle"></div>
								<div class="coccoc-alo-ph-circle-fill"></div>
								<div class="coccoc-alo-ph-img-circle"></div>
							</div>
						</div>
					</div>
				</Link>
			</section>

			<section className="footer-extra py-3">
				<h6 className="text-center">Developed by Katec</h6>
			</section>
		</footer>
	);
};

export default Footer;
