import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Icon from "./Icon";
import {
	EN_EMAIL_FORMAT,
	EN_ERROR_SEND_FORM,
	EN_FIELD_REQUIRE,
	EN_LOGOUT_SUCCESS,
	EN_PHONE_FORMAT,
	VI_EMAIL_FORMAT,
	VI_ERROR_SEND_FORM,
	VI_FIELD_REQUIRE,
	VI_LOGOUT_SUCCESS,
	VI_PHONE_FORMAT,
} from "../utils/message";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import yup from "../utils/yupGlobal";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const userInformationFormItems = [
	{
		id: 1,
		placeholderVi: "Họ và tên",
		placeholderEn: "Fullname",
		name: "fullname",
	},
	{
		id: 2,
		placeholderVi: "Email",
		placeholderEn: "Email",
		name: "email",
	},
	{
		id: 3,
		placeholderVi: "Số điện thoại",
		placeholderEn: "Phone number",
		name: "phonenumber",
	},
	{
		id: 4,
		placeholderVi: "Địa chỉ",
		placeholderEn: "Address",
		name: "address",
	},
];

const UserProfile = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const [showModal, setShowModal] = useState(false);
	const [username, setUsername] = useState(false);

	const [errMessage, setErrMessage] = useState({
		nameVi: undefined,
		nameEn: undefined,
		slug: undefined,
	});

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(language ? SchemaVi : SchemaEn),
	});

	useEffect(() => {
		getDetailUser();
	}, [showModal]); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getDetailUser = async () => {
		await axios
			.get(`/user`)
			.then((res) => {
				if (res.data.errCode === 0) {
					reset({
						fullname: res.data.user.fullname,
						email: res.data.user.email,
						phonenumber: res.data.user.phonenumber,
						address: res.data.user.address,
					});

					setUsername(res.data.user.username);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateUser = async (data) => {
		setErrMessage({
			nameVi: undefined,
			nameEn: undefined,
			slug: undefined,
		});
		await axios
			.put(`/user/update`, {
				fullname: data.fullname,
				email: data.email,
				phonenumber: data.phonenumber,
				address: data.address,
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					localStorage.setItem("fullname", data.fullname);

					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});
				} else {
					setErrMessage(res.data.errMessage);
					toast(language ? VI_ERROR_SEND_FORM : EN_ERROR_SEND_FORM, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	const handleLogout = () => {
		localStorage.removeItem("fullname");
		localStorage.removeItem("role");
		localStorage.removeItem("access_token");
		localStorage.removeItem("username");

		window.location.href = "/";

		toast(language ? VI_LOGOUT_SUCCESS : EN_LOGOUT_SUCCESS, {
			type: "success",
			autoClose: 1000,
		});
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handleShowModal = () => {
		setShowModal(true);
	};

	return (
		<>
			<Dropdown className="me-3">
				<Dropdown.Toggle
					style={{
						background: "none",
						border: "none",
						color: "black",
					}}
				>
					<Icon icon="user" />
					<span className="limited-text ps-2">
						{localStorage.getItem("fullname")}
					</span>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item onClick={() => handleShowModal()}>
						<Icon className="me-2" icon="user" />
						{language ? "Thông tin" : "Profile"}
					</Dropdown.Item>

					<Dropdown.Item as={Link} to={`/orderHistory`}>
						<Icon className="me-2" icon="bag-shopping" />
						{language ? "Đơn mua" : "My purchase"}
					</Dropdown.Item>

					{Number(localStorage.getItem("role")) === 1 ||
					Number(localStorage.getItem("role")) === 2 ? (
						<>
							<Dropdown.Item as={Link} to={`/admin`}>
								<Icon className="me-2" icon="bars-progress" />
								{language ? "Quản lí" : "Manage"}
							</Dropdown.Item>
							<Dropdown.Item as={Link} to={`/`}>
								<Icon className="me-2" icon="shop" />
								{language ? "Cửa hàng" : "Shop"}
							</Dropdown.Item>
						</>
					) : (
						""
					)}

					<Dropdown.Divider />
					<Dropdown.Item onClick={() => handleLogout()}>
						<Icon className="me-2" icon="right-from-bracket" />
						{language ? "Đăng xuất" : "Logout"}
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>

			{showModal ? (
				<Modal show={showModal} onHide={handleCloseModal}>
					<Modal.Header closeButton>
						<Modal.Title>
							{language
								? "Thông tin người dùng"
								: "User information"}
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Form
							id="update-user-hook-form"
							onSubmit={handleSubmit((data) => {
								updateUser(data);
							})}
						>
							{userInformationFormItems.map((formItem) => {
								return (
									<div key={formItem.id} className="mb-3">
										<Form.Floating>
											<Form.Control
												type="text"
												placeholder={
													language
														? formItem.placeholderVi
														: formItem.placeholderEn
												}
												name={formItem.name}
												{...register(formItem.name)}
											/>

											<Form.Label>
												{language
													? formItem.placeholderVi
													: formItem.placeholderEn}
												<i className="text-danger">*</i>
											</Form.Label>
										</Form.Floating>

										{errors[formItem.name] && (
											<p
												style={{
													fontSize: 13,
													color: "red",
													marginTop: 6,
												}}
											>
												{errors[formItem.name].message}
											</p>
										)}

										{errMessage[formItem.name] && (
											<p
												style={{
													fontSize: 13,
													color: "red",
													marginTop: 6,
												}}
											>
												{errMessage[formItem.name]}
											</p>
										)}
									</div>
								);
							})}
						</Form>

						<Form.Floating>
							<Form.Control
								type="text"
								value={username}
								placeholder={
									language ? "Tên đăng nhập" : "Username"
								}
								disabled
							/>

							<Form.Label>
								{language ? "Tên đăng nhập" : "Username"}
							</Form.Label>
						</Form.Floating>
					</Modal.Body>

					<Modal.Footer>
						<Button
							type="button"
							variant="secondary"
							onClick={handleCloseModal}
						>
							{language ? "Đóng" : "Close"}
						</Button>
						<Button
							type="submit"
							form="update-user-hook-form"
							variant="dark"
						>
							{language ? "Cập nhật" : "Update"}
						</Button>
					</Modal.Footer>
				</Modal>
			) : (
				""
			)}
		</>
	);
};

const SchemaVi = yup.object().shape({
	fullname: yup.string().required(VI_FIELD_REQUIRE),
	phonenumber: yup.string().required(VI_FIELD_REQUIRE).phone(VI_PHONE_FORMAT),
	email: yup.string().email(VI_EMAIL_FORMAT).required(VI_FIELD_REQUIRE),
	address: yup.string().required(VI_FIELD_REQUIRE),
});

const SchemaEn = yup.object().shape({
	fullname: yup.string().required(EN_FIELD_REQUIRE),
	phonenumber: yup.string().required(EN_FIELD_REQUIRE).phone(EN_PHONE_FORMAT),
	email: yup.string().email(EN_EMAIL_FORMAT).required(EN_FIELD_REQUIRE),
	address: yup.string().required(EN_FIELD_REQUIRE),
});

export default UserProfile;
