import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import Select from "react-select";

import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import yup from "../../../utils/yupGlobal";
import convertToSlug from "../../../utils/slugFormatter";
import TextEditor from "../../../components/TextEditor";
import Icon from "../../../components/Icon";
import CurrencyFormat from "react-currency-format";
import {
	EN_ERROR_SEND_FORM,
	EN_FIELD_REQUIRE,
	EN_FILE_REQUIRE_2_10,
	EN_FILE_SIZE,
	VI_ERROR_SEND_FORM,
	VI_FIELD_REQUIRE,
	VI_FILE_REQUIRE_2_10,
	VI_FILE_SIZE,
} from "../../../utils/message";

const addProductFormItems = [
	{
		id: 1,
		placeholderVi: "Tên sản phẩm Tiếng việt",
		placeholderEn: "Vietnamese Product name",
		name: "nameVi",
	},
	{
		id: 2,
		placeholderVi: "Tên sản phẩm Tiếng anh",
		placeholderEn: "English Product name",
		name: "nameEn",
	},
];

const ProductAddModal = ({ handleCloseModal, showModal }) => {
	const language = useSelector((state) => state.language.isVietnamese);

	const [slug, setSlug] = useState("");
	const [colors, setColors] = useState([]);
	const [sizeOptions, setSizeOptions] = useState([]);

	const [imageId, setImageId] = useState(0);

	const [productImages, setProductImages] = useState([]);
	const [productImageFiles, setProductImageFiles] = useState([]);

	const [productColors, setProductColors] = useState([]);
	const [productSizes, setProductSizes] = useState([]);

	const [isSelectSize, setIsSelectSize] = useState(false);
	const [isSelecColor, setIsSelecColor] = useState(false);

	const [contentVi, setContentVi] = useState("");
	const [contentEn, setContentEn] = useState("");

	const [selectedCategory, setSelectedCategory] = useState();
	const [categoryOptions, setCategoryOptions] = useState([{ value: 0, label: "" }]);

	const [errMessage, setErrMessage] = useState({
		nameVi: undefined,
		nameEn: undefined,
		slug: undefined,
	});

	const [isSendForm, setIsSendForm] = useState(false);

	const {
		control,
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(language ? SchemaVi : SchemaEn),
	});

	useEffect(() => {
		getAllOptionCategories();
		getAllColors();
		getAllSizes();
	}, []);

	// Api
	const getAllOptionCategories = () => {
		axios
			.get(`/category/option`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setCategoryOptions(res.data.categories);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllColors = async () => {
		await axios
			.get(`/color/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setColors(res.data.colors);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllSizes = async () => {
		await axios.get(`/size/list`)
			.then((res) => {
				if (res.status === 200) {
					let sizeDatas = [];
					if (res.data.data.sizes.length > 0) {
						sizeDatas = res.data.data.sizes.map(item => ({
							id: item.id,
							name: item.nameVi
						}));
					}

					setSizeOptions(sizeDatas);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const createNewProduct = async (data) => {
		setIsSendForm(true);

		var bodyFormData = new FormData();

		bodyFormData.append("categoryId", selectedCategory.id);
		bodyFormData.append("nameVi", data.nameVi);
		bodyFormData.append("nameEn", data.nameEn);
		bodyFormData.append("priceVi", data.priceVi ? Number(data.priceVi.replace(/,/g, "").replace("AUD", "")) : null);
		bodyFormData.append("priceEn", data.priceEn ? Number(data.priceEn.replace(/,/g, "").replace("USD", "")) : null);
		bodyFormData.append("salePriceVi", data.salePriceVi ? Number(data.salePriceVi.replace(/,/g, "").replace("AUD", "")) : null);
		bodyFormData.append("salePriceEn", data.salePriceEn ? Number(data.salePriceEn.replace(/,/g, "").replace("USD", "")) : null);
		bodyFormData.append("contentVi", contentVi);
		bodyFormData.append("contentEn", contentEn);
		bodyFormData.append("slug", slug);
		bodyFormData.append(
			"colors",
			JSON.stringify(
				productColors.map((color) => {
					return color.id;
				}),
			),
		);
		bodyFormData.append(
			"sizes",
			JSON.stringify(
				productSizes.map((size) => {
					return size.id;
				}),
			),
		);

		productImageFiles.map((file) => bodyFormData.append("images", file.src));

		await axios({
			method: "post",
			url: `product/create`,
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then((res) => {
				setIsSendForm(false);

				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});
					handleCloseModal(true);
				} else {
					setErrMessage(res.data.errMessage);
					toast(language ? VI_ERROR_SEND_FORM : EN_ERROR_SEND_FORM, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleUploadImage = (event) => {
		setImageId(imageId + 1);

		try {
			if (!event.target.files[0].type.match(/image.*/) || event.target.files[0].size > 2097152) {
				toast(language ? VI_FILE_SIZE : EN_FILE_SIZE, {
					type: "error",
					autoClose: 1000,
				});

				return;
			}

			setProductImageFiles([
				...productImageFiles,
				{
					id: imageId,
					src: event.target.files[0],
				},
			]);

			setProductImages([
				...productImages,
				{
					id: imageId,
					src: URL.createObjectURL(event.target.files[0]),
				},
			]);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Modal size="lg" show={showModal} onHide={() => handleCloseModal(false)} backdrop="static">
			<Modal.Header closeButton={!isSendForm}>
				<Modal.Title>{language ? "Thêm mới sản phẩm" : "Add new product"}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="mb-3">
					<Form.Label>{language ? "Chọn danh mục" : "Select category"}</Form.Label>

					<Select
						options={categoryOptions}
						placeholder={<div>{language ? "Chọn danh mục" : "Select category"}</div>}
						noOptionsMessage={() => (language ? "Không có lựa chọn" : "No options")}
						getOptionLabel={(option) => (language ? option.nameVi : option.nameEn)}
						getOptionValue={(option) => option.id}
						value={selectedCategory}
						onChange={(choice) => {
							setSelectedCategory(choice);
						}}
						menuPortalTarget={document.body}
						styles={{
							menuPortal: (base) => ({ ...base, zIndex: 9999 }),
						}}
					/>
				</div>

				<Form
					id="add-product-hook-form"
					onSubmit={handleSubmit((data) => {
						if (productImages.length < 1 || productImages.length > 10) {
							toast(language ? VI_FILE_REQUIRE_2_10 : EN_FILE_REQUIRE_2_10, {
								type: "error",
								autoClose: 1000,
							});
							return;
						}

						setIsSelectSize(false);
						setIsSelecColor(false);

						if (productSizes.length === 0) {
							setIsSelectSize(true);
							return;
						}

						if (productColors.length === 0) {
							setIsSelecColor(true);
							return;
						}

						createNewProduct(data);
					})}
					className="mb-5"
				>
					{addProductFormItems.map((formItem) => {
						return (
							<div key={formItem.id} className="mb-3">
								<Form.Floating>
									<Form.Control
										type="text"
										placeholder={language ? formItem.placeholderVi : formItem.placeholderEn}
										name={formItem.name}
										{...register(formItem.name, {
											onChange: () => {
												if (formItem.id === 2) {
													setSlug(convertToSlug(getValues("nameEn")));
												}
											},
										})}
									/>

									<Form.Label>
										{language ? formItem.placeholderVi : formItem.placeholderEn}
										<i className="text-danger">*</i>
									</Form.Label>
								</Form.Floating>

								{errors[formItem.name] && (
									<p
										style={{
											fontSize: 13,
											color: "red",
											marginTop: 6,
										}}
									>
										{errors[formItem.name].message}
									</p>
								)}

								{errMessage[formItem.name] && (
									<p
										style={{
											fontSize: 13,
											color: "red",
											marginTop: 6,
										}}
									>
										{errMessage[formItem.name]}
									</p>
								)}
							</div>
						);
					})}

					<div className="mb-3">
						<Form.Floating>
							<Form.Control type="text" placeholder="Slug" name="slug" disabled value={slug} />

							<Form.Label>
								Slug
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>

						{errMessage.slug && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errMessage.slug}
							</p>
						)}
					</div>

					<Row className="mb-3">
						<Col xs="12" md="6" className="mb-3 mb-md-0">
							<Form.Floating>
								<Controller
									control={control}
									name="priceVi"
									render={({ field: { onChange, name, value } }) => (
										<CurrencyFormat
											className="form-control text-end"
											name={name}
											value={value}
											thousandSeparator={true}
											onChange={onChange}
											suffix=" AUD"
											allowNegative={false}
										/>
									)}
								/>

								<Form.Label>
									{language ? "Giá (AUD)" : "Price (AUD)"}
									<i className="text-danger">*</i>
								</Form.Label>
							</Form.Floating>
							{errors.priceVi && (
								<p
									style={{
										fontSize: 13,
										color: "red",
										marginTop: 6,
									}}
								>
									{errors.priceVi.message}
								</p>
							)}

							{errMessage.priceVi && (
								<p
									style={{
										fontSize: 13,
										color: "red",
										marginTop: 6,
									}}
								>
									{errMessage.priceVi}
								</p>
							)}
						</Col>
						<Col xs="12" md="6">
							<Form.Floating>
								<Controller
									control={control}
									name="priceEn"
									render={({ field: { onChange, name, value } }) => (
										<CurrencyFormat
											className="form-control text-end"
											name={name}
											value={value}
											thousandSeparator={true}
											onChange={onChange}
											suffix=" USD"
											allowNegative={false}
										/>
									)}
								/>

								<Form.Label>
									{language ? "Giá (USD)" : "Price (USD)"}
									<i className="text-danger">*</i>
								</Form.Label>
							</Form.Floating>
							{errors.priceEn && (
								<p
									style={{
										fontSize: 13,
										color: "red",
										marginTop: 6,
									}}
								>
									{errors.priceEn.message}
								</p>
							)}

							{errMessage.priceEn && (
								<p
									style={{
										fontSize: 13,
										color: "red",
										marginTop: 6,
									}}
								>
									{errMessage.priceEn}
								</p>
							)}
						</Col>
					</Row>

					<Row className="mb-3">
						<Col xs="12" md="6" className="mb-3 mb-md-0">
							<Form.Floating>
								<Controller
									control={control}
									name="salePriceVi"
									render={({ field: { onChange, name, value } }) => (
										<CurrencyFormat
											className="form-control text-end"
											name={name}
											value={value}
											thousandSeparator={true}
											onChange={onChange}
											suffix=" AUD"
											allowNegative={false}
										/>
									)}
								/>

								<Form.Label>{language ? "Giá khuyến mãi (AUD)" : "Sale price (AUD)"}</Form.Label>
							</Form.Floating>
						</Col>
						<Col xs="12" md="6">
							<Form.Floating>
								<Controller
									control={control}
									name="salePriceEn"
									render={({ field: { onChange, name, value } }) => (
										<CurrencyFormat
											className="form-control text-end"
											name={name}
											value={value}
											thousandSeparator={true}
											onChange={onChange}
											suffix=" USD"
											allowNegative={false}
										/>
									)}
								/>

								<Form.Label>{language ? "Giá khuyến mãi (USD)" : "Sale price (USD)"}</Form.Label>
							</Form.Floating>
						</Col>
					</Row>

					<Select
						options={sizeOptions}
						placeholder={<div>{language ? "Chọn kích cỡ" : "Select size"}</div>}
						noOptionsMessage={() => (language ? "Không có lựa chọn" : "No options")}
						getOptionLabel={(option) => (language ? option.name : option.name)}
						getOptionValue={(option) => option.id}
						value={productSizes}
						onChange={(choice) => {
							setProductSizes(choice);
						}}
						className="mb-3"
						isMulti
						menuPortalTarget={document.body}
						styles={{
							menuPortal: (base) => ({ ...base, zIndex: 9999 }),
						}}
					/>

					{isSelectSize && (
						<p
							style={{
								fontSize: 13,
								color: "red",
								marginTop: 6,
							}}
						>
							{language ? "Vui lòng chọn kích cỡ" : "Please select size"}
						</p>
					)}

					<Select
						options={colors}
						placeholder={<div>{language ? "Chọn màu" : "Select color"}</div>}
						noOptionsMessage={() => (language ? "Không có lựa chọn" : "No options")}
						getOptionLabel={(option) => (language ? option.nameVi : option.nameEn)}
						getOptionValue={(option) => option.id}
						value={productColors}
						onChange={(choice) => {
							setProductColors(choice);
						}}
						className="mb-3"
						isMulti
						menuPortalTarget={document.body}
						styles={{
							menuPortal: (base) => ({ ...base, zIndex: 9999 }),
						}}
					/>

					{isSelecColor && (
						<p
							style={{
								fontSize: 13,
								color: "red",
								marginTop: 6,
							}}
						>
							{language ? "Vui lòng chọn màu" : "Please select color"}
						</p>
					)}

					{/* Content */}
					<div className="mb-3">
						<Form.Label>{language ? "Thông tin sản phẩm Tiếng việt" : "Vietnamese Product information"}</Form.Label>
						<TextEditor
							setContents={contentVi}
							onChange={(value) => setContentVi(value)}
							placeholder={language ? "Thông tin sản phẩm Tiếng việt" : "Vietnamese Product information"}
						/>
					</div>

					<div className="mb-3">
						<Form.Label>{language ? "Thông tin sản phẩm Tiếng anh" : "English Product information"}</Form.Label>
						<TextEditor
							setContents={contentEn}
							onChange={(value) => setContentEn(value)}
							placeholder={language ? "Thông tin sản phẩm Tiếng anh" : "English Product information"}
						/>
					</div>
				</Form>

				{/* Images */}
				<div>
					<div className="d-flex justify-content-evenly flex-wrap">
						{productImages.length !== 0
							? productImages.map((imageItem) => {
								return (
									<div
										key={imageItem.id}
										style={{
											height: 300,
											width: 300,
											backgroundImage: `url("${imageItem.src}")`,
											backgroundSize: "cover",
										}}
										className="image-default position-relative mb-5"
									>
										<button
											style={{
												height: "1.5rem",
												width: "1.5rem",
												border: "none",
											}}
											className="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger"
											onClick={() => {
												setProductImages(productImages.filter((item) => item.id !== imageItem.id));
												setProductImageFiles(productImageFiles.filter((item) => item.id !== imageItem.id));
											}}
										>
											<Icon icon="x" />
										</button>
									</div>
								);
							})
							: ""}
					</div>

					<input type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e) => handleUploadImage(e)} required />
					<div>
						<div className="d-inline fw-bold">Note: </div>
						{language
							? "Chọn ảnh với tỉ lệ 300:300 để được chất lượng tốt nhất."
							: "Please select images with a 300:300 ratio for the best quality."}
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<Button type="button" variant="secondary" onClick={() => handleCloseModal(false)} disabled={isSendForm}>
					{language ? "Đóng" : "Close"}
				</Button>
				<Button type="submit" form="add-product-hook-form" variant="dark" disabled={isSendForm}>
					{language ? "Lưu" : "Save"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const SchemaVi = yup.object().shape({
	nameVi: yup.string().required(VI_FIELD_REQUIRE),
	nameEn: yup.string().required(VI_FIELD_REQUIRE),
	priceVi: yup.string().required(VI_FIELD_REQUIRE),
	priceEn: yup.string().required(VI_FIELD_REQUIRE),
});

const SchemaEn = yup.object().shape({
	nameVi: yup.string().required(EN_FIELD_REQUIRE),
	nameEn: yup.string().required(EN_FIELD_REQUIRE),
	priceVi: yup.string().required(EN_FIELD_REQUIRE),
	priceEn: yup.string().required(EN_FIELD_REQUIRE),
});

export default ProductAddModal;
