import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { EN_ERROR_SEND_FORM, EN_FIELD_REQUIRE, EN_PHONE_FORMAT, VI_ERROR_SEND_FORM, VI_FIELD_REQUIRE, VI_PHONE_FORMAT } from "../../../utils/message";
import Input from "../../../components/Input";
import yup from "../../../utils/yupGlobal";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import DataTables from "../../../components/DataTables";
import MoneyFormat from "../../../components/MoneyFormat";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import ProductEditModal from "../Product/ProductEditModal";
import Icon from "../../../components/Icon";

const orderDetailFormItems = [
	{
		id: 1,
		placeholderVi: "Tên khách hàng",
		placeholderEn: "Customer name",
		name: "fullname",
	},
	{
		id: 2,
		placeholderVi: "Số điện thoại",
		placeholderEn: "Phone number",
		name: "phonenumber",
	},
	{
		id: 3,
		placeholderVi: "Địa chỉ",
		placeholderEn: "Address",
		name: "address",
	},
	{
		id: 4,
		placeholderVi: "Lời nhắn",
		placeholderEn: "Message",
		name: "message",
	},
];

const orderStatuses = [
	{ id: 1, nameVi: "Chưa xác nhận", nameEn: "Not confirmed yet" },
	{ id: 2, nameVi: "Đã xác nhận", nameEn: "Confirmed" },
	{ id: 3, nameVi: "Đang vận chuyển", nameEn: "Is shipping" },
	{ id: 4, nameVi: "Hoàn thành", nameEn: "Completed" },
	{ id: 5, nameVi: "Hủy", nameEn: "Cancel" },
];

const OrderDetail = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	let { id } = useParams();

	const [order, setOrder] = useState([]);
	const [listProduct, setListProduct] = useState([]);

	const [paymentMethods, setPaymentMethods] = useState([]);
	const [selectedPayment, setSelectedPayment] = useState(1);
	const [orderStatus, setOrderStatus] = useState(1);

	const [showProductEditModal, setShowProductEditModal] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(0);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(language ? SchemaVi : SchemaEn),
	});

	useEffect(() => {
		getAllPaymentMethods();
	}, []);

	useEffect(() => {
		getOrderDetail(id);
	}, [id]); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getOrderDetail = async (orderId) => {
		await axios
			.get(`/order/${orderId}`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setOrder(res.data.order);

					setListProduct(
						res.data.order.orderDetails.map((orderDetail) => {
							return {
								productId: orderDetail.product.id,
								productImage: orderDetail.product.images[0].src,
								productNameVi: orderDetail.product.nameVi,
								productNameEn: orderDetail.product.nameEn,
								productPriceVi: orderDetail.priceVi,
								productPriceEn: orderDetail.priceEn,
								categoryNameVi: orderDetail.product.category.nameVi,
								categoryNameEn: orderDetail.product.category.nameEn,
								quantity: orderDetail.quantity,
								color: orderDetail.color,
								size: orderDetail.size,
							};
						}),
					);

					setSelectedPayment(res.data.order.paymentMethodId);
					setOrderStatus(res.data.order.orderStatusId);

					reset({
						fullname: res.data.order.fullname,
						phonenumber: res.data.order.phonenumber,
						address: res.data.order.address,
						message: res.data.order.message,
					});
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllPaymentMethods = () => {
		axios
			.get(`/payment/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setPaymentMethods(res.data.payments);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateOrder = async (data) => {
		await axios
			.put(`/order/update/${id}`, {
				paymentMethod: selectedPayment,
				orderStatus: orderStatus,
				fullname: data.fullname,
				phonenumber: data.phonenumber,
				address: data.address,
				message: data.message,
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});
					window.location.reload();
				} else {
					toast(language ? VI_ERROR_SEND_FORM : EN_ERROR_SEND_FORM, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	// Logic
	const handleCloseEditModal = (isReload) => {
		setShowProductEditModal(false);
		if (isReload) {
			getOrderDetail(id);
		}
	};
	const handleShowEditModal = (productId) => {
		setShowProductEditModal(true);
		setSelectedProduct(productId);
	};
	// Logic

	const columns = [
		{
			name: language ? "Hình ảnh" : "Image",
			selector: (row) => (
				<div
					style={{
						width: "3rem",
						height: "3rem",
						backgroundImage: `url("${row.productImage}")`,
					}}
					className="image-default"
				/>
			),
			center: true
		},
		{
			name: language ? "Tên sản phẩm" : "Product name",
			selector: (row) => <div className="fw-bold">{language ? row.productNameVi : row.productNameEn}</div>,
			wrap: true,
		},
		{
			name: language ? "Loại sản phẩm" : "Category",
			selector: (row) => <div className="fw-bold">{language ? row.categoryNameVi : row.categoryNameEn}</div>,
			wrap: true,
		},
		{
			name: language ? "Đơn giá" : "Price",
			style: {
				display: "flex",
				justifyContent: "end",
			},
			selector: (row) => <MoneyFormat money={language ? row.productPriceVi : row.productPriceEn} className="fw-bold" />,
			wrap: true,
		},
		{
			name: language ? "Màu sắc" : "Color",
			selector: (row) => <div className="fw-bold">{language ? row.color?.nameVi : row.color?.nameEn}</div>,
			wrap: true,
		},
		{
			name: language ? "Kích cỡ" : "Size",
			selector: (row) => <div className="fw-bold">{row.size?.name}</div>,
			wrap: true,
		},
		{
			name: language ? "Số lượng" : "Quantity",
			selector: (row) => (
				<CurrencyFormat value={row.quantity} displayType={"text"} thousandSeparator={true} allowNegative={false} isNumericString className="fw-bold" />
			),
			wrap: true,
			right: true
		},
		{
			name: language ? "Thành tiền" : "Total price",
			style: {
				display: "flex",
				justifyContent: "end",
			},
			selector: (row) => (
				<MoneyFormat money={language ? Number(row.productPriceVi * row.quantity) : Number(row.productPriceEn * row.quantity)} className="fw-bold" />
			),
		},
		{
			name: language ? "Phương thức" : "Method",
			style: {
				display: "flex",
				justifyContent: "center",
			},
			selector: (row) => (
				<Button size="sm" variant="warning me-2" type="button" onClick={() => handleShowEditModal(row.productId)} title="Chi tiết">
					<Icon icon="pencil" />
				</Button>
			),
		},
	];

	const totalPriceOrder = () => {
		let totalVi = 0;
		let totalEn = 0;

		order.orderDetails?.map((orderDetail) => {
			totalVi += Number(orderDetail.quantity * orderDetail.priceVi);
			totalEn += Number(orderDetail.quantity * orderDetail.priceEn);

			return orderDetail;
		});

		return <MoneyFormat money={language ? totalVi : totalEn} className="fw-bold" />;
	};

	return (
		<Container fluid>
			<Card className="mt-3">
				<Card.Header>
					<Card.Title>{language ? "Chi tiết đơn hàng" : "Order detail"}</Card.Title>
				</Card.Header>

				<Card.Body>
					<Row className="mb-3">
						<Col sm="3" className="fw-bold">
							{language ? "Thanh toán" : "Payment"}
						</Col>
						<Col sm="9" className="d-flex justify-content-start">
							{paymentMethods.map((payment) => {
								return (
									<Form.Check
										value={payment.id}
										checked={selectedPayment === payment.id}
										onChange={() => setSelectedPayment(payment.id)}
										key={payment.id}
										type={"radio"}
										label={`${language ? payment.nameVi : payment.nameEn}`}
										name="payment"
										className="me-3"
									/>
								);
							})}
						</Col>
					</Row>

					<Row className="mb-3">
						<Col sm="3" className="fw-bold">
							{language ? "Trạng thái" : "Status"}
						</Col>
						<Col sm="9" className="d-flex justify-content-start">
							{orderStatuses.map((status) => {
								return (
									<Form.Check
										value={status.id}
										checked={orderStatus === status.id}
										onChange={() => setOrderStatus(status.id)}
										key={status.id}
										type={"radio"}
										label={`${language ? status.nameVi : status.nameEn}`}
										name="order"
										className="me-3"
									/>
								);
							})}
						</Col>
					</Row>

					<Form
						id="update-order-hook-form"
						onSubmit={handleSubmit((data) => {
							updateOrder(data);
						})}
					>
						{orderDetailFormItems.map((formItem) => {
							return (
								<Form.Group key={formItem.id} as={Row}>
									<Form.Label column sm="3" className="fw-bold">
										{language ? formItem.placeholderVi : formItem.placeholderEn}
									</Form.Label>
									<Col sm="6">
										<Input
											placeholder={language ? formItem.placeholderVi : formItem.placeholderEn}
											name={formItem.name}
											register={register(formItem.name)}
											errors={errors[formItem.name]}
											className="mb-3"
											as={formItem.id === 4 ? "textarea" : "input"}
											rows={3}
										/>
									</Col>
								</Form.Group>
							);
						})}
					</Form>

					<DataTables data={listProduct} columns={columns} className="my-4" pagination={false} />

					<Row className="mb-3">
						<Col sm="4">
							<div className="d-flex justify-content-between">
								<div className="fw-bold">{language ? "Ngày tạo:" : "Created date:"}</div>
								<div>
									{language ? moment(order.createdAt).format("DD-MM-YYYY, hh:mm") : moment(order.createdAt).format("YYYY-MM-DD, hh:mm a")}
								</div>
							</div>

							<div className="d-flex justify-content-between">
								<div className="fw-bold">{language ? "Ngày cập nhật:" : "Updated date:"}</div>
								<div>
									{language ? moment(order.updatedAt).format("DD-MM-YYYY, hh:mm") : moment(order.updatedAt).format("YYYY-MM-DD, hh:mm a")}
								</div>
							</div>
						</Col>
						<Col sm="4"></Col>
						<Col sm="4">
							<div className="d-flex justify-content-between mb-3">
								<div className="fw-bold">{language ? "Tổng cộng:" : "Total:"}</div>
								<div>{totalPriceOrder()}</div>
							</div>
						</Col>
					</Row>
				</Card.Body>

				<Card.Footer>
					<Link to="/admin/order">
						<Button type="button" variant="secondary" className="me-3">
							{language ? "Quay lại" : "Back"}
						</Button>
					</Link>
					<Button form="update-order-hook-form" type="submit" variant="dark">
						{language ? "Cập nhật" : "Update"}
					</Button>
				</Card.Footer>
			</Card>

			{showProductEditModal ? (
				<ProductEditModal handleCloseModal={handleCloseEditModal} showModal={showProductEditModal} selectedProduct={selectedProduct} />
			) : (
				""
			)}
		</Container>
	);
};

const SchemaVi = yup.object().shape({
	fullname: yup.string().required(VI_FIELD_REQUIRE),
	phonenumber: yup.string().required(VI_FIELD_REQUIRE).phone(VI_PHONE_FORMAT),
	address: yup.string().required(VI_FIELD_REQUIRE),
});

const SchemaEn = yup.object().shape({
	fullname: yup.string().required(EN_FIELD_REQUIRE),
	phonenumber: yup.string().required(EN_FIELD_REQUIRE).phone(EN_PHONE_FORMAT),
	address: yup.string().required(EN_FIELD_REQUIRE),
});

export default OrderDetail;
