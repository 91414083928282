const Section = ({ title, style, className, children }) => {
	return (
		<div style={style} className={`mb-5 ${className}`}>
			<div className="title mb-3">{title}</div>
			<div>{children}</div>
		</div>
	);
};

export default Section;
