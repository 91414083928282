import { Form } from "react-bootstrap";

const Input = ({ className, style, register, errors, ...props }) => {
	return (
		<div className={`${className}`} style={style}>
			<Form.Control
				size="md"
				type="text"
				{...register}
				{...props}
				style={{ borderRadius: 0 }}
			/>
			{errors && (
				<p
					style={{
						fontSize: 13,
						color: "red",
						marginTop: 6,
					}}
				>
					{errors.message}
				</p>
			)}
		</div>
	);
};

export default Input;
