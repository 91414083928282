import { useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import axios from "axios";
import { toast } from "react-toastify";

import Icon from "../../../components/Icon";
import DataTables from "../../../components/DataTables";
import Loading from "../../../components/Loading";
import { messages } from "../../../utils/Messages";
import SizeAddModal from "./SizeAddModal";
import SizeEditModal from "./SizeEditModal";
import { Link } from "react-router-dom";

const SizeIndex = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const lang = useSelector((state) => state.language.isVietnamese) ? 'vi' : 'en';
	const [sizes, setSizes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [sizeData, setSizeData] = useState({});

	useEffect(() => {
		getAllsizes();
	}, []);

	// Api
	const getAllsizes = async () => {
		setIsLoading(true);
		await axios.get(`/size/list`)
			.then((res) => {
				setIsLoading(false);
				if (res.status === 200) {
					setSizes(res.data.data.sizes);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deletesize = async (sizeId) => {
		await axios
			.delete(`/size/delete?id=${sizeId}`)
			.then((res) => {
				if (res.status === 200) {
					toast(res.data.messages, { type: "success", autoClose: 1000, });
					getAllsizes();
				}
			})
			.catch((err) => {
				toast(err.response?.data.messages, { type: "error", autoClose: 1000, });
			});
	};
	// Api

	const handleDeletesize = (sizeId) => {
		swal({
			title: messages('youWantToDelete', lang),
			icon: "warning",
			buttons: [messages('close', lang), messages('delete', lang)],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				deletesize(sizeId);
			}
		});
	};

	const handleCloseAddModal = (isReload) => {
		setShowAddModal(false);
		if (isReload) {
			getAllsizes();
		}
	};
	const handleShowAddModal = () => {
		setShowAddModal(true);
	};

	const handleCloseEditModal = (isReload) => {
		setShowEditModal(false);
		if (isReload) {
			getAllsizes();
		}
	};

	const handleShowEditModal = (item) => {
		setSizeData({
			id: item.id,
			nameVi: item.nameVi,
			nameEn: item.nameEn,
		});

		setShowEditModal(true);
	};

	const columns = [
		{
			name: language ? "Tên kích thước" : "Size name",
			selector: (row) => language ? row.nameVi : row.nameEn,
			sortable: true,
		},
		{
			name: language ? "Phương thức" : "Method",
			style: {
				display: "flex",
				justifyContent: "center",
			},
			selector: (row) => (
				<>
					<Button size="sm" variant="warning me-2" type="button" onClick={() => handleShowEditModal(row)}>
						<Icon icon="pencil" />
					</Button>

					<Button size="sm" variant="danger" type="button" onClick={() => handleDeletesize(row.id)}>
						<Icon icon="trash" />
					</Button>
				</>
			),
		},
	];

	return (
		<Container fluid>
			{language ? (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item linkProps={{ to: '/admin' }}><Link to="/admin">Tổng quan</Link></Breadcrumb.Item>
					<Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
					<Breadcrumb.Item active>Kích thước</Breadcrumb.Item>
				</Breadcrumb>
			) : (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item linkProps={{ to: '/admin' }}><Link to="/admin">Dashboard</Link></Breadcrumb.Item>
					<Breadcrumb.Item active>Setting</Breadcrumb.Item>
					<Breadcrumb.Item active>Size</Breadcrumb.Item>
				</Breadcrumb>
			)}

			<div className="d-block d-md-flex justify-content-between mb-3">
				<div className="mt-3 mt-md-0">
					<Button variant="primary" className="mt-2 mt-md-0" onClick={() => handleShowAddModal()}>
						<Icon icon="plus" /> {language ? "Thêm mới" : "Add new"}
					</Button>
				</div>
			</div>

			<Row>
				<Col xs={12} md={6}>
					{isLoading ? <Loading /> : <DataTables data={sizes} columns={columns} />}
				</Col>
			</Row>

			{showAddModal ? <SizeAddModal handleCloseModal={handleCloseAddModal} showModal={showAddModal} /> : ""}

			{showEditModal ? <SizeEditModal handleCloseModal={handleCloseEditModal} showModal={showEditModal} sizeData={sizeData} /> : ""}
		</Container>
	);
};

export default SizeIndex;
