import { Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const NotFound = ({ path }) => {
	const language = useSelector((state) => state.language.isVietnamese);
	const navigate = useNavigate();

	return (
		<Container className="d-flex justify-content-center align-items-center">
			<div className="text-center py-5">
				<h2>{language ? "Không tìm thấy trang" : "Page not found"}</h2>

				<Button
					size="lg"
					variant="secondary"
					className="mt-3"
					onClick={() => {
						navigate(path);
					}}
				>
					{language ? "Trở lại" : "Back"}
				</Button>
			</div>
		</Container>
	);
};

export default NotFound;
