import axios from "axios";
import { useEffect, useReducer } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";

import { INITIAL_STATE, fetchReducer } from "../../reducer/fetchReducer";
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from "../../reducer/dataAction";

const Collection = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const [state, dispatch] = useReducer(fetchReducer, INITIAL_STATE);

	useEffect(() => {
		document.title = language ? "Bộ sưu tập" : "Collection";

		getAllCollections();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getAllCollections = async () => {
		dispatch({ type: FETCH_START });
		await axios
			.get(`/combo/client`)
			.then((res) => {
				if (res.data.errCode === 0) {
					dispatch({ type: FETCH_SUCCESS, payload: res.data.combos });
				} else {
					dispatch({ type: FETCH_ERROR });

					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				dispatch({ type: FETCH_ERROR });

				console.log(err);
			});
	};
	// API
	return (
		<Container>
			<div className="title text-center mt-3">{language ? "Bộ sưu tập" : "Collection"}</div>

			{state.loading ? (
				<Loading />
			) : (
				<Row className="my-3">
					{state.data.map((collection) => {
						return (
							<Col xs="6" md="4" key={collection.id} className="mb-3">
								<Link
									to={`/collection/${collection.slug}`}
									style={{
										textDecoration: "none",
										color: "black",
									}}
								>
									<div
										style={{
											aspectRatio: "600/900",
											backgroundImage: `url("${collection.image}")`,
										}}
										className="image-default mb-3"
									/>

									<div style={{ height: "3rem" }} className="fw-bold text-overflow-2-line">
										{language ? collection.nameVi : collection.nameEn}
									</div>
								</Link>
							</Col>
						);
					})}
				</Row>
			)}
		</Container>
	);
};

export default Collection;
