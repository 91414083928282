import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import Select from "react-select";

import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";

import yup from "../../../utils/yupGlobal";
import convertToSlug from "../../../utils/slugFormatter";
import {
	EN_ERROR_SEND_FORM,
	EN_FIELD_REQUIRE,
	EN_FILE_REQUIRE,
	EN_FILE_SIZE,
	EN_PRODUCT_REQUIRE,
	VI_ERROR_SEND_FORM,
	VI_FIELD_REQUIRE,
	VI_FILE_REQUIRE,
	VI_FILE_SIZE,
	VI_PRODUCT_REQUIRE,
} from "../../../utils/message";
import Icon from "../../../components/Icon";

const addCollectionFormItems = [
	{
		id: 1,
		placeholderVi: "Tên bộ sưu tập Tiếng việt",
		placeholderEn: "Vietnamese Collection name",
		name: "nameVi",
	},
	{
		id: 2,
		placeholderVi: "Tên bộ sưu tập Tiếng anh",
		placeholderEn: "English Collection name",
		name: "nameEn",
	},
];

const CollectionEditModal = ({
	handleCloseModal,
	showModal,
	selectedCollection,
}) => {
	const language = useSelector((state) => state.language.isVietnamese);

	const [slug, setSlug] = useState("");

	const [imageId, setImageId] = useState(0);
	const [collectionImage, setCollectionImage] = useState();

	const [selectedProducts, setSelectedProducts] = useState([]);
	const [productOptions, setProductOptions] = useState([]);

	const [errMessage, setErrMessage] = useState({
		nameVi: undefined,
		nameEn: undefined,
		slug: undefined,
	});

	const [isSendForm, setIsSendForm] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const {
		reset,
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(language ? SchemaVi : SchemaEn),
	});

	useEffect(() => {
		getAllOptionProduct();
		getDetailCollection(selectedCollection);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Api
	const getDetailCollection = async (collectionId) => {
		setIsLoading(true);
		await axios
			.get(`/combo/${collectionId}`)
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					reset({
						nameVi: res.data.combo.nameVi,
						nameEn: res.data.combo.nameEn,
					});
					setSlug(res.data.combo.slug);

					setSelectedProducts(
						res.data.combo.productCombos.map((productCombo) => {
							return productCombo.product;
						}),
					);

					setCollectionImage(res.data.combo.image);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllOptionProduct = () => {
		axios
			.get(`/product/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setProductOptions(res.data.products);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateCollection = async (data) => {
		setIsSendForm(true);
		await axios
			.put(`/combo/update/${selectedCollection}`, {
				nameVi: data.nameVi,
				nameEn: data.nameEn,
				isShow: true,
				slug: slug,
				products: selectedProducts.map((product) => product.id),
				image: collectionImage.split(",")[1],
			})
			.then((res) => {
				setIsSendForm(false);

				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});
					handleCloseModal(true);
				} else {
					setErrMessage(res.data.errMessage);
					toast(language ? VI_ERROR_SEND_FORM : EN_ERROR_SEND_FORM, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleUploadImage = (event) => {
		setImageId(imageId + 1);

		try {
			if (
				!event.target.files[0].type.match(/image.*/) ||
				event.target.files[0].size > 2097152
			) {
				toast(language ? VI_FILE_SIZE : EN_FILE_SIZE, {
					type: "error",
					autoClose: 1000,
				});

				return;
			}
			let reader = new FileReader();
			reader.onload = function (e) {
				setCollectionImage(e.target.result);
			};
			reader.readAsDataURL(event.target.files[0]);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Modal
			size="lg"
			show={showModal}
			onHide={() => handleCloseModal(false)}
			backdrop="static"
		>
			<Modal.Header closeButton={!isSendForm}>
				<Modal.Title>
					{language ? "Cập nhật bộ sưu tập" : "Update collection"}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form
					id="update-collection-hook-form"
					onSubmit={handleSubmit((data) => {
						if (selectedProducts.length === 0) {
							toast(
								language
									? VI_PRODUCT_REQUIRE
									: EN_PRODUCT_REQUIRE,
								{
									type: "error",
									autoClose: 1000,
								},
							);

							return;
						}

						if (!collectionImage) {
							toast(
								language ? VI_FILE_REQUIRE : EN_FILE_REQUIRE,
								{
									type: "error",
									autoClose: 1000,
								},
							);

							return;
						}

						updateCollection(data);
					})}
					className="mb-3"
				>
					{addCollectionFormItems.map((formItem) => {
						return (
							<div key={formItem.id} className="mb-3">
								<Form.Floating>
									<Form.Control
										type="text"
										placeholder={
											language
												? formItem.placeholderVi
												: formItem.placeholderEn
										}
										name={formItem.name}
										{...register(formItem.name, {
											onChange: () => {
												if (formItem.id === 2) {
													setSlug(
														convertToSlug(
															getValues("nameEn"),
														),
													);
												}
											},
										})}
									/>

									<Form.Label>
										{language
											? formItem.placeholderVi
											: formItem.placeholderEn}
										<i className="text-danger">*</i>
									</Form.Label>
								</Form.Floating>

								{errors[formItem.name] && (
									<p
										style={{
											fontSize: 13,
											color: "red",
											marginTop: 6,
										}}
									>
										{errors[formItem.name].message}
									</p>
								)}

								{errMessage[formItem.name] && (
									<p
										style={{
											fontSize: 13,
											color: "red",
											marginTop: 6,
										}}
									>
										{errMessage[formItem.name]}
									</p>
								)}
							</div>
						);
					})}

					<div className="mb-3">
						<Form.Floating>
							<Form.Control
								type="text"
								placeholder="Slug"
								name="slug"
								disabled
								value={slug}
							/>

							<Form.Label>
								Slug
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>

						{errMessage.slug && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errMessage.slug}
							</p>
						)}
					</div>
				</Form>

				{/* Select Products */}
				<div className="mb-3">
					<Form.Label>
						{language ? "Chọn sản phẩm" : "Select products"}
					</Form.Label>

					<Select
						options={productOptions}
						placeholder={
							<div>
								{language ? "Chọn sản phẩm" : "Select products"}
							</div>
						}
						noOptionsMessage={() =>
							language ? "Không có lựa chọn" : "No options"
						}
						getOptionLabel={(option) =>
							language ? option.nameVi : option.nameEn
						}
						getOptionValue={(option) => option.id}
						onChange={(choice) => {
							setSelectedProducts([...selectedProducts, choice]);
							setProductOptions(
								productOptions.filter(
									(product) => product.id !== choice.id,
								),
							);
						}}
					/>

					{selectedProducts.length !== 0 ? (
						<Table className="mt-3">
							<thead className="text-center align-middle">
								<tr>
									<th>{language ? "Hình ảnh" : "Image"}</th>
									<th>{language ? "Sản phẩm" : "Product"}</th>
									<th>
										{language ? "Danh mục" : "Category"}
									</th>
									<th>
										{language ? "Phương thức" : "Method"}
									</th>
								</tr>
							</thead>
							<tbody className=" align-middle">
								{selectedProducts?.map((product) => {
									return (
										<tr key={product.id}>
											<td className="d-flex justify-content-center">
												<div
													style={{
														width: "3rem",
														height: "3rem",
														backgroundImage: `url("${product.images[0].src}")`,
													}}
													className="image-default"
												/>
											</td>
											<td style={{ maxWidth: 200 }}>
												<div className="text-overflow-2-line">
													{language
														? product.nameVi
														: product.nameEn}
												</div>
											</td>
											<td className="text-center">
												{language
													? product.category.nameVi
													: product.category.nameEn}
											</td>
											<td className="text-center">
												<Button
													size="sm"
													variant="danger"
													type="button"
													onClick={() => {
														setProductOptions([
															...productOptions,
															product,
														]);
														setSelectedProducts(
															selectedProducts.filter(
																(productItem) =>
																	productItem.id !==
																	product.id,
															),
														);
													}}
												>
													<Icon icon="trash" />
												</Button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					) : (
						<div className="text-center mt-3">
							{language
								? "Chưa chọn sản phẩm nào"
								: "No products selected"}
						</div>
					)}
				</div>

				{/* Images */}
				<div>
					<input
						type="file"
						accept="image/x-png,image/gif,image/jpeg"
						onChange={(e) => handleUploadImage(e)}
						required
					/>
					<div>
						<div className="d-inline fw-bold">Note: </div>
						{language
							? "Chọn ảnh với tỉ lệ 600:900 để được chất lượng tốt nhất."
							: "Please select images with a 600:900 ratio for the best quality."}
					</div>

					{collectionImage ? (
						<Row className="justify-content-center">
							<Col xs="12" md="6">
								<div
									style={{
										aspectRatio: "600/900",
										backgroundImage: `url("${collectionImage}")`,
										backgroundSize: "cover",
									}}
									className="image-default mt-3"
								/>
							</Col>
						</Row>
					) : (
						""
					)}
				</div>
			</Modal.Body>

			<Modal.Footer>
				<Button
					type="button"
					variant="secondary"
					onClick={() => handleCloseModal(false)}
					disabled={isSendForm}
				>
					{language ? "Đóng" : "Close"}
				</Button>
				<Button
					type="submit"
					form="update-collection-hook-form"
					variant="dark"
					disabled={isSendForm || isLoading}
				>
					{language ? "Cập nhật" : "Update"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const SchemaVi = yup.object().shape({
	nameVi: yup.string().required(VI_FIELD_REQUIRE),
	nameEn: yup.string().required(VI_FIELD_REQUIRE),
});

const SchemaEn = yup.object().shape({
	nameVi: yup.string().required(EN_FIELD_REQUIRE),
	nameEn: yup.string().required(EN_FIELD_REQUIRE),
});

export default CollectionEditModal;
