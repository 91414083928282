import { Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Icon from "../../components/Icon";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { CATEGORY } from "../../routes/routerUrl";

const OrderSuccess = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const [buyMoreLink, setBuyMoreLink] = useState("");

	useEffect(() => {
		document.title = language ? "Đơn hàng" : "Order";

		getAllCategories();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const getAllCategories = () => {
		axios
			.get(`/category/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setBuyMoreLink(res.data.categories[0].slug);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Container>
			<div className="text-center my-5">
				<div style={{ fontSize: "1.5rem" }}>
					<Icon icon="circle-check" />{" "}
					{language ? "Đặt hàng thành công" : "Order successfully"}
				</div>

				<div className="mt-3">
					{language
						? "Đơn hàng của bạn đã được tạo thành công, chủ cửa hàng sẽ liên lạc với bạn để xác nhận lại thông tin."
						: "Your order has been successfully created, the store owner will contact you to confirm the information."}
				</div>

				<div className="mt-2">
					{language
						? "Cảm ơn vì đã đặt hàng. Chúc bạn một ngày tốt lành. "
						: "Thank you. Have a nice day "}{" "}
					&#129392;
				</div>

				<Row className="justify-content-center mt-5">
					<Col xs="6" md="3">
						<Link to={`${CATEGORY}/${buyMoreLink}`}>
							<Button size="md" className="mlb-button me-2 w-100">
								<Icon icon="cart-plus" className="me-2" />
								{language ? "Mua thêm" : "Buy more"}
							</Button>
						</Link>
					</Col>
					<Col xs="6" md="3">
						<Link to={`/orderHistory`}>
							<Button size="md" className="mlb-button me-2 w-100">
								<Icon icon="bag-shopping" className="me-2" />
								{language ? "Đơn mua của bạn" : "Your purchase"}
							</Button>
						</Link>
					</Col>
				</Row>
			</div>
		</Container>
	);
};

export default OrderSuccess;
