import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";

import yup from "../../../utils/yupGlobal";

import {
	EN_EMAIL_FORMAT,
	EN_FIELD_REQUIRE,
	EN_PASSWORD_LENGTH,
	EN_PASSWORD_MATCH,
	EN_PHONE_FORMAT,
	EN_USER_LENGTH,
	VI_EMAIL_FORMAT,
	VI_FIELD_REQUIRE,
	VI_PASSWORD_LENGTH,
	VI_PASSWORD_MATCH,
	VI_PHONE_FORMAT,
	VI_USER_LENGTH,
} from "../../../utils/message";
import Input from "../../../components/Input";

const registerFormItems = [
	{
		id: 1,
		placeholderVi: "Tên đăng nhập",
		placeholderEn: "Username",
		name: "username",
	},
	{
		id: 2,
		placeholderVi: "Mật khẩu",
		placeholderEn: "Password",
		name: "password",
	},
	{
		id: 3,
		placeholderVi: "Nhập lại mật khẩu",
		placeholderEn: "Confirm Password",
		name: "confirmPassword",
	},
	{
		id: 4,
		placeholderVi: "Họ và tên",
		placeholderEn: "Fullname",
		name: "fullname",
	},
	{
		id: 5,
		placeholderVi: "Email",
		placeholderEn: "Email",
		name: "email",
	},
	{
		id: 6,
		placeholderVi: "Số điện thoại",
		placeholderEn: "Phone number",
		name: "phonenumber",
	},
	{
		id: 7,
		placeholderVi: "Địa chỉ",
		placeholderEn: "Address",
		name: "address",
	},
];

const StaffAddModal = ({ handleCloseModal, showModal }) => {
	const language = useSelector((state) => state.language.isVietnamese);
	const [isSendForm, setIsSendForm] = useState(false);
	const [errMessage, setErrMessage] = useState({
		username: undefined,
		password: undefined,
		email: undefined,
		phonenumber: undefined,
		address: undefined,
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(language ? SchemaVi : SchemaEn),
	});

	// Api
	const registerUser = (data) => {
		setIsSendForm(true);
		axios
			.post(`/staff/create`, {
				username: data.username,
				password: data.password,
				fullname: data.fullname,
				email: data.email,
				phonenumber: data.phonenumber,
				address: data.address,
			})
			.then((res) => {
				setIsSendForm(false);
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});

					handleCloseModal(true);
				} else {
					setErrMessage(res.data.errMessage);
				}
			});
	};
	// Api

	return (
		<Modal
			size="lg"
			show={showModal}
			onHide={() => handleCloseModal(false)}
			backdrop="static"
		>
			<Modal.Header closeButton={!isSendForm}>
				<Modal.Title>
					{language ? "Thêm mới nhân viên" : "Add new staff"}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form
					id="add-staff-hook-form"
					onSubmit={handleSubmit((data) => {
						registerUser(data);
					})}
				>
					{registerFormItems.map((item) => {
						return (
							<React.Fragment key={item.id}>
								<Input
									type={
										item.id === 2 || item.id === 3
											? "password"
											: "text"
									}
									placeholder={
										language
											? item.placeholderVi
											: item.placeholderEn
									}
									name={item.name}
									register={register(item.name)}
									errors={errors[item.name]}
									className="mb-3"
								/>

								{errMessage[item.name] && (
									<p
										style={{
											fontSize: 13,
											color: "red",
											marginTop: 6,
										}}
									>
										{errMessage[item.name]}
									</p>
								)}
							</React.Fragment>
						);
					})}
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button
					type="button"
					variant="secondary"
					onClick={() => handleCloseModal(false)}
					disabled={isSendForm}
				>
					{language ? "Đóng" : "Close"}
				</Button>
				<Button
					type="submit"
					form="add-staff-hook-form"
					variant="dark"
					disabled={isSendForm}
				>
					{language ? "Lưu" : "Save"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const SchemaVi = yup.object().shape({
	password: yup
		.string()
		.required(VI_FIELD_REQUIRE)
		.test(
			"len",
			VI_PASSWORD_LENGTH,
			(val) => val.length >= 6 && val.length <= 32,
		),
	username: yup
		.string()
		.required(VI_FIELD_REQUIRE)
		.test(
			"len",
			VI_USER_LENGTH,
			(val) => val.length >= 6 && val.length <= 32,
		),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), null], VI_PASSWORD_MATCH),
	fullname: yup.string().required(VI_FIELD_REQUIRE),
	phonenumber: yup.string().required(VI_FIELD_REQUIRE).phone(VI_PHONE_FORMAT),
	email: yup.string().email(VI_EMAIL_FORMAT).required(VI_FIELD_REQUIRE),
	address: yup.string().required(VI_FIELD_REQUIRE),
});

const SchemaEn = yup.object().shape({
	password: yup
		.string()
		.required(EN_FIELD_REQUIRE)
		.test(
			"len",
			EN_PASSWORD_LENGTH,
			(val) => val.length >= 6 && val.length <= 32,
		),
	username: yup
		.string()
		.required(EN_FIELD_REQUIRE)
		.test(
			"len",
			EN_USER_LENGTH,
			(val) => val.length >= 6 && val.length <= 32,
		),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), null], EN_PASSWORD_MATCH),
	fullname: yup.string().required(EN_FIELD_REQUIRE),
	phonenumber: yup.string().required(EN_FIELD_REQUIRE).phone(EN_PHONE_FORMAT),
	email: yup.string().email(EN_EMAIL_FORMAT).required(EN_FIELD_REQUIRE),
	address: yup.string().required(EN_FIELD_REQUIRE),
});

export default StaffAddModal;
