import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
	name: "cart",
	initialState: {
		cart: JSON.parse(localStorage.getItem("cart")) || [],
	},
	reducers: {
		addToCart: (state, action) => {
			if (
				state.cart.some((item) => item.id === action.payload.id && item.color.id === action.payload.color.id && item.size.id === action.payload.size.id)
			) {
				const newCart = state.cart.map((item) => {
					if (item.id === action.payload.id && item.color.id === action.payload.color.id && item.size.id === action.payload.size.id) {
						return {
							...item,
							quantity: item.quantity + action.payload.quantity,
						};
					}
					return item;
				});

				state.cart = newCart;
				localStorage.setItem("cart", JSON.stringify(newCart));
			} else {
				const newCart = [...state.cart, action.payload];

				state.cart = newCart;
				localStorage.setItem("cart", JSON.stringify(newCart));
			}
		},
		removeFromCart: (state, action) => {
			const newCart = state.cart.filter((item) => {
				if (item.id === action.payload.id && item.color.id === action.payload.color.id && item.size.id === action.payload.size.id) {
					return false;
				}
				return true;
			});

			state.cart = newCart;
			localStorage.setItem("cart", JSON.stringify(newCart));
		},
		updateCart: (state, action) => {
			const newCart = state.cart.map((item) => {
				if (item.id === action.payload.id && item.color.id === action.payload.color.id && item.size.id === action.payload.size.id) {
					return {
						...item,
						quantity: item.quantity + action.payload.amount !== 0 ? item.quantity + action.payload.amount : item.quantity,
					};
				}
				return item;
			});

			state.cart = newCart;
			localStorage.setItem("cart", JSON.stringify(newCart));
		},
		removeCart: (state) => {
			state.cart = [];
			localStorage.setItem("cart", JSON.stringify([]));
		},
	},
});

export const { addToCart, removeFromCart, updateCart, removeCart } = cartSlice.actions;

export default cartSlice.reducer;
