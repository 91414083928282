import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Dropdown, NavDropdown, Navbar } from "react-bootstrap";
import Icon from "../../components/Icon";
import { useSelector } from "react-redux";

import Logo from "../../assets/icon/logo.png";

import { CART, CATEGORY, LOGIN, REGISTER } from "../../routes/routerUrl";

import LanguageToggle from "../../components/LanguageToggle";
import UserProfile from "../../components/UserProfile";

function UserNavBar({ setShowSideBar, showSideBar, shop, categories }) {
	const cart = useSelector((state) => (localStorage.getItem("access_token") ? state.userCart.userCart : state.cart.cart));
	const language = useSelector((state) => state.language.isVietnamese);
	const navigate = useNavigate();

	// const [selectedCategory, setSelectedCategory] = useState(0);
	const [showDropdown, setShowDropdown] = useState(0);

	console.log(categories);

	return (
		<>
			<div className="header py-2">
				<Container fluid className="d-flex justify-content-between">
					<div>
						<Icon icon="location-dot" /> {language ? "Địa chỉ" : "Address"}: {shop.address}
					</div>

					<div className="d-flex">
						<div className="me-2">
							<Icon icon="phone" /> {language ? "Số điện thoại" : "Phone number"}: {shop.phonenumber}
						</div>
						<div>
							<Icon icon="envelope" /> Email: {shop.email}
						</div>
					</div>
				</Container>
			</div>

			<Navbar expand="lg" className="nav-height px-0">
				<Container className="desktop-nav w-100">
					<div className="d-flex align-items-center w-75">
						<Link to="/">
							<img src={Logo} alt="BonnieKids" className="logo " />
						</Link>

						{/* {categories.map((category) => {
							return (
								<div key={category.id} onMouseEnter={() => setSelectedCategory(category.id)} onMouseLeave={() => setSelectedCategory(0)}>
									<Link
										to={`${CATEGORY}/${category.slug}`}
										className="nav-text me-3"
										style={{
											minWidth: "fit-content",
											maxWidth: "fit-content",
										}}
									>
										{language ? category.nameVi : category.nameEn}
									</Link>

									{category.categories.length > 0 && selectedCategory === category.id ? (
										<div className="nav-menu">
											<div className="space-nav-menu" />
											<div
												style={{ backgroundColor: "rgba(255, 255, 255, 0.85)", width: "100vw" }}
												className="d-flex justify-content-center p-4"
											>
												{category.categories?.map((categoryItem) => {
													return (
														<Col md={3} key={categoryItem.id} className="d-flex justify-content-center">
															<div>
																<div style={{ fontWeight: "bold" }}>
																	<Link className="fw-bold link-text" to={`${CATEGORY}/${categoryItem.slug}`}>
																		{language ? categoryItem.nameVi : categoryItem.nameEn}
																	</Link>
																</div>

																{categoryItem.categories?.map((categoryObj) => {
																	return (
																		<div key={categoryObj.id} className="my-2">
																			<Link to={`${CATEGORY}/${categoryObj.slug}`} className="link-text">
																				{language ? categoryObj.nameVi : categoryObj.nameEn}
																			</Link>
																		</div>
																	);
																})}
															</div>
														</Col>
													);
												})}
											</div>
										</div>
									) : (
										""
									)}
								</div>
							);
						})} */}

						<div className="d-flex flex-wrap">
							{categories?.map((categoryItem) => {
								return (
									<NavDropdown
										key={categoryItem.id}
										title={
											<div style={{ fontSize: "1.1rem" }} className="nav-text" onClick={() => navigate(`${CATEGORY}/${categoryItem.slug}`)}>
												{language ? categoryItem.nameVi : categoryItem.nameEn}
											</div>
										}
										className="pe-4"
										show={showDropdown === categoryItem.id}
										onMouseEnter={() => {
											if (categoryItem.categories.length > 0) {
												setShowDropdown(categoryItem.id);
											}
										}}
										onMouseLeave={() => {
											if (categoryItem.categories.length > 0) {
												setShowDropdown(0);
											}
										}}
									>
										{categoryItem.categories?.map((subItem) => {
											return (
												<NavDropdown.Item as={Link} key={subItem.id} to={`${CATEGORY}/${subItem.slug}`} className="py-2">
													{language ? subItem.nameVi : subItem.nameEn}
												</NavDropdown.Item>
											);
										})}
									</NavDropdown>
								);
							})}
						</div>

						{/* <div style={{ width: "1px", height: "1.5rem", backgroundColor: "black", }} className="mx-3" />
						<Link to={`/collection`} className="nav-text fw-normal me-3" style={{ minWidth: "fit-content", maxWidth: "fit-content", }} >
							{language ? "Bộ sưu tập" : "Collection"}
						</Link> */}
					</div>

					<div className="d-flex justify-content-end align-items-center w-25">
						<Link to={"/search"} className="position-relative nav-text me-3">
							<Icon icon="magnifying-glass" size="lg" />
						</Link>

						<Link to={CART} className="position-relative nav-text me-2">
							<Icon icon="cart-shopping" size="lg" />
							{cart.length !== 0 ? (
								<span className="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger">{cart.length}</span>
							) : (
								""
							)}
						</Link>

						<LanguageToggle />
						{localStorage.getItem("access_token") ? (
							<UserProfile />
						) : (
							<Dropdown className="me-3">
								<Dropdown.Toggle
									style={{
										background: "none",
										border: "none",
										color: "black",
									}}
								>
									<Icon icon="user" size="lg" />
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item as={Link} to={LOGIN}>
										<Icon icon="circle-user" /> {language ? "Đăng nhập" : "Login"}
									</Dropdown.Item>

									<Dropdown.Item as={Link} to={REGISTER}>
										<Icon icon="registered" /> {language ? "Đăng ký" : "Register"}
									</Dropdown.Item>

									<Dropdown.Item as={Link} to={`/orderHistory`}>
										<Icon className="me-2" icon="bag-shopping" />
										{language ? "Đơn mua" : "My purchase"}
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						)}
					</div>
				</Container>

				<Container className="mobile-nav justify-content-between align-items-center w-100">
					<Link to="/" className="logo">
						<img src={Logo} alt="BonnieKids" className="logo" />
					</Link>

					<div className="d-flex align-items-center">
						<Link to={"/search"} className="position-relative nav-text me-3">
							<Icon icon="magnifying-glass" size="lg" />
						</Link>

						<Link to={CART} className="position-relative nav-text me-3">
							<Icon icon="cart-shopping" size="lg" />
							{cart.length !== 0 ? (
								<span className="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger">{cart.length}</span>
							) : (
								""
							)}
						</Link>

						<div
							onClick={() => {
								setShowSideBar(!showSideBar);
							}}
							className="toggle-button"
						>
							<Icon icon="bars" />
						</div>
					</div>
				</Container>
			</Navbar>
		</>
	);
}

export default UserNavBar;
