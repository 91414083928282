import React from "react";
import { Link } from "react-router-dom";
import { CATEGORY, LOGIN } from "../../routes/routerUrl";
import { useSelector } from "react-redux";
import Icon from "../../components/Icon";
import LanguageToggle from "../../components/LanguageToggle";
import UserProfile from "../../components/UserProfile";

const Sidebar = ({ setShowSideBar, showSideBar, shop, categories }) => {
	const language = useSelector((state) => state.language.isVietnamese);

	return (
		<nav
			style={{
				fontSize: "0.9rem",
			}}
			className={`${
				showSideBar ? "sidebar-menu active" : "sidebar-menu"
			} p-4`}
		>
			<div>
				{localStorage.getItem("access_token") ? (
					""
				) : (
					<Link
						to={LOGIN}
						style={{
							textDecoration: "none",
							fontWeight: 600,
							textTransform: "uppercase",
						}}
						onClick={() => setShowSideBar(false)}
						className="product-item"
					>
						{language ? "Đăng nhập" : "Login"}
					</Link>
				)}

				<div className="pb-3 my-3 border-bottom">
					{categories?.map((category) => {
						return (
							<div key={category.id}>
								<div
									style={{
										fontWeight: 600,
										textTransform: "uppercase",
									}}
									className="d-flex justify-content-between align-items-center mb-2"
								>
									<Link
										to={`${CATEGORY}/${category.slug}`}
										style={{ textDecoration: "none" }}
										onClick={() => setShowSideBar(false)}
									>
										<div className="product-item">
											{language
												? category.nameVi
												: category.nameEn}
										</div>
									</Link>

									{category.categories.length !== 0 ? (
										<button
											style={{
												backgroundColor: "var(--white)",
												border: "none",
											}}
											data-bs-toggle="collapse"
											href={`#${category.slug}`}
											className="p-0 m-0"
										>
											<Icon icon="chevron-down" />
										</button>
									) : (
										""
									)}
								</div>

								<div
									className="collapse ps-3"
									id={category.slug}
								>
									{category.categories.map(
										(firstSubCategory) => {
											return (
												<div key={firstSubCategory.id}>
													<div
														style={{
															fontWeight: 600,
															textTransform:
																"uppercase",
														}}
														className="d-flex justify-content-between align-items-center"
													>
														<Link
															to={`${CATEGORY}/${firstSubCategory.slug}`}
															style={{
																textDecoration:
																	"none",
															}}
															onClick={() =>
																setShowSideBar(
																	false,
																)
															}
														>
															<div className="product-item mb-2">
																{language
																	? firstSubCategory.nameVi
																	: firstSubCategory.nameEn}
															</div>
														</Link>

														{firstSubCategory
															.categories
															.length !== 0 ? (
															<button
																style={{
																	backgroundColor:
																		"var(--white)",
																	border: "none",
																}}
																data-bs-toggle="collapse"
																href={`#${firstSubCategory.slug}`}
																className="p-0 m-0"
															>
																<Icon icon="chevron-down" />
															</button>
														) : (
															""
														)}
													</div>

													<div
														className="collapse ps-3"
														id={
															firstSubCategory.slug
														}
													>
														{firstSubCategory.categories.map(
															(
																secondSubCategory,
															) => {
																return (
																	<Link
																		key={
																			secondSubCategory.id
																		}
																		to={`${CATEGORY}/${secondSubCategory.slug}`}
																		style={{
																			textDecoration:
																				"none",
																		}}
																		onClick={() =>
																			setShowSideBar(
																				false,
																			)
																		}
																	>
																		<div className="product-item mb-2">
																			{language
																				? secondSubCategory.nameVi
																				: secondSubCategory.nameEn}
																		</div>
																	</Link>
																);
															},
														)}
													</div>
												</div>
											);
										},
									)}
								</div>
							</div>
						);
					})}
				</div>

				<div className="mt-4">
					<div className="mb-2">
						<Icon icon="phone" /> Số điện thoại: {shop.phonenumber}
					</div>
					<div>
						<Icon icon="envelope" /> Email: {shop.email}
					</div>
				</div>
			</div>

			<div
				style={{ marginLeft: "-0.8rem" }}
				className="d-flex justify-content-between mt-3"
			>
				<LanguageToggle />

				{localStorage.getItem("access_token") ? <UserProfile /> : ""}
			</div>
		</nav>
	);
};

export default Sidebar;
