import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap";
import swal from "sweetalert";

import DataTables from "../../../components/DataTables";
import Icon from "../../../components/Icon";
import CategoryAddModal from "./CategoryAddModal";
import CategoryEditModal from "./CategoryEditModal";
import Loading from "../../../components/Loading";

const Category = () => {
	const language = useSelector((state) => state.language.isVietnamese);

	const [categories, setCategories] = useState([]);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [selectedCategory, setSelectedCategory] = useState({
		id: 0,
		nameVi: "",
		nameEn: "",
	});

	useEffect(() => {
		getAllCategories();
	}, []);

	// Api
	const getAllCategories = () => {
		setIsLoading(true);

		axios
			.get(`/category/list`)
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setCategories(res.data.categories);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteCategory = async (categoryId) => {
		await axios
			.delete(`/category/delete/${categoryId}`)
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});

					getAllCategories();
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleDeleteCategory = (categoryId) => {
		swal({
			title: language ? `Bạn muốn xóa danh mục sản phẩm này` : "You want to delete this category",
			icon: "warning",
			buttons: [language ? "Đóng" : "Close", language ? "Xóa" : "Delete"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				deleteCategory(categoryId);
			}
		});
	};

	const handleCloseAddModal = (isReload) => {
		setShowAddModal(false);
		if (isReload) {
			getAllCategories();
		}
	};
	const handleShowAddModal = (categoryId, nameVi, nameEn) => {
		setShowAddModal(true);
		setSelectedCategory({
			id: categoryId,
			nameVi: nameVi,
			nameEn: nameEn,
		});
	};

	const handleCloseEditModal = (isReload) => {
		setShowEditModal(false);
		if (isReload) {
			getAllCategories();
		}
	};
	const handleShowEditModal = (categoryId, nameVi, nameEn) => {
		setShowEditModal(true);
		setSelectedCategory({
			id: categoryId,
			nameVi: nameVi,
			nameEn: nameEn,
		});
	};

	const columns = [
		{
			name: language ? "Tên danh mục" : "Category Name",
			selector: (row) => (language ? row.nameVi : row.nameEn),
			wrap: true,
		},
		{
			name: language ? "Phương thức" : "Method",
			center: true,
			cell: (row) => (
				<>
					<Button size="sm" variant="warning me-2" type="button" onClick={() => handleShowEditModal(row.id, row.nameVi, row.nameEn)}>
						<Icon icon="pencil" />
					</Button>
					<Button size="sm" variant="primary me-2" type="button" onClick={() => handleShowAddModal(row.id, row.nameVi, row.nameEn)}>
						<Icon icon="plus" />
					</Button>
					<Button size="sm" variant="danger" type="button" onClick={() => handleDeleteCategory(row.id)}>
						<Icon icon="trash" />
					</Button>
				</>
			),
		},
	];

	const columnSenSubs = [
		{
			name: language ? "Tên danh mục" : "Category Name",
			selector: (row) => (language ? row.nameVi : row.nameEn),
			wrap: true,
		},
		{
			name: language ? "Phương thức" : "Method",
			center: true,
			cell: (row) => (
				<>
					<Button size="sm" variant="warning me-2" type="button" onClick={() => handleShowEditModal(row.id, row.nameVi, row.nameEn)}>
						<Icon icon="pencil" />
					</Button>
					<Button size="sm" variant="danger" type="button" onClick={() => handleDeleteCategory(row.id)}>
						<Icon icon="trash" />
					</Button>
				</>
			),
		},
	];

	const FirstSubCategory = ({ data }) => {
		if (!data) {
			return <i className="text-muted ms-5">Không có dữ liệu!</i>;
		}

		return <DataTables className="ms-5" data={data.categories} columns={columns} expandableRows expandableRowsComponent={SecondSubCategory} />;
	};

	const SecondSubCategory = ({ data }) => {
		if (!data) {
			return <i className="text-muted ms-5"> {language ? "Không có dữ liệu!" : "No data"}</i>;
		}

		return <DataTables className="ms-5" data={data.categories} columns={columnSenSubs} />;
	};

	return (
		<Container fluid>
			{language ? (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item active>Tổng quan</Breadcrumb.Item>
					<Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
					<Breadcrumb.Item active>Danh mục sản phẩm</Breadcrumb.Item>
				</Breadcrumb>
			) : (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item active>Setting</Breadcrumb.Item>
					<Breadcrumb.Item active>Category</Breadcrumb.Item>
				</Breadcrumb>
			)}

			<Row className="mb-3">
				<Col xs="12" md="2">
					<Button variant="primary" className="mt-2 mt-md-0" onClick={() => handleShowAddModal(0, "", "")}>
						<Icon icon="plus" /> {language ? "Thêm mới" : "Add new"}
					</Button>
				</Col>
			</Row>
			<Row>
				<Col xs={12} md={6}>
					{isLoading ? <Loading /> : <DataTables data={categories} columns={columns} expandableRows expandableRowsComponent={FirstSubCategory} />}
				</Col>
			</Row>

			{showAddModal ? <CategoryAddModal handleCloseModal={handleCloseAddModal} showModal={showAddModal} parentCategory={selectedCategory} /> : ""}

			{showEditModal ? <CategoryEditModal handleCloseModal={handleCloseEditModal} showModal={showEditModal} selectedCategory={selectedCategory} /> : ""}
		</Container>
	);
};

export default Category;
