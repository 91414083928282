import { createSlice } from "@reduxjs/toolkit";

export const languageSlice = createSlice({
	name: "language",
	initialState: {
		isVietnamese: localStorage.getItem("language") === "true",
	},
	reducers: {
		vietnamese: (state) => {
			state.isVietnamese = true;
			localStorage.setItem("language", true);
		},
		english: (state) => {
			state.isVietnamese = false;
			localStorage.setItem("language", false);
		},
	},
});

export const { vietnamese, english } = languageSlice.actions;

export default languageSlice.reducer;
