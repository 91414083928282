import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

import "./styles.css";

function MasterLayout() {
	const navigate = useNavigate();

	const sidenavToggle = () => {
		const sbNavFixed = document.getElementById("sb-nav-fixed");

		let testClass = sbNavFixed.className;

		if (
			testClass === "sb-nav-fixed sb-sidenav-toggled" &&
			window.innerWidth < 992
		) {
			sbNavFixed.classList.remove("sb-sidenav-toggled");
		}
	};

	useEffect(() => {
		if (
			Number(localStorage.getItem("role")) !== 1 &&
			Number(localStorage.getItem("role")) !== 2
		) {
			navigate("/");
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="sb-nav-fixed" id="sb-nav-fixed">
			<Navbar />

			<div id="layoutSidenav">
				<div id="layoutSidenav_nav">
					<Sidebar />
				</div>

				<div id="layoutSidenav_content" onClick={sidenavToggle}>
					<main>
						<Outlet />
					</main>
					<Footer />
				</div>
			</div>
		</div>
	);
}

export default MasterLayout;
