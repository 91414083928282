import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { toast } from "react-toastify";

import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "../../store/slices/cartSlice";
import { addToUserCart } from "../../store/slices/userCartSlice";

import MoneyFormat from "../../components/MoneyFormat";
import Icon from "../../components/Icon";

import axios from "axios";
import TextEditor from "../../components/TextEditor";
import { EN_CART_CREATE, VI_CART_CREATE } from "../../utils/message";

const responsiveProduct = {
	large: {
		breakpoint: { max: 3000, min: 1200 },
		items: 1,
		slidesToSlide: 1,
	},
	desktop: {
		breakpoint: { max: 1200, min: 768 },
		items: 1,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 768, min: 576 },
		items: 1,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 576, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};

const CustomDot = ({ onClick, carouselItems, ...rest }) => {
	const { index, active } = rest;
	return (
		<div
			onClick={() => onClick()}
			style={active ? { border: "2px solid var(--black)" } : { border: "2px solid var(--white)" }}
			className={`d-none d-md-block mx-2`}
		>
			{React.Children.toArray(carouselItems)[index]}
		</div>
	);
};

const Product = () => {
	let { slug } = useParams();

	const dispatch = useDispatch();
	const language = useSelector((state) => state.language.isVietnamese);

	const [quantity, setQuantity] = useState(1);
	const [product, setProduct] = useState({
		category: {
			nameVi: "",
			nameEn: "",
		},
		images: [],
		nameVi: "",
		nameEn: "",
	});
	const [productSizes, setProductSizes] = useState([]);
	const [productColors, setProductColors] = useState([]);

	const [selectedColor, setSelectedColor] = useState({});
	const [selectedSize, setSelectedSize] = useState({});
	const [isNotSelectVariation, setIsNotSelectVariation] = useState(false);

	useEffect(() => {
		getDetailProduct(slug);
	}, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		document.title = language ? product.nameVi : product.nameEn;
	}, [language]); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getDetailProduct = async (productSlug) => {
		await axios
			.get(`/product/client/${productSlug}`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setProduct(res.data.product);

					setProductSizes(res.data.product.sizes.map((size) => size.size));
					setProductColors(res.data.product.colors.map((color) => color.color));

					document.title = language ? res.data.product.nameVi : res.data.product.nameEn;
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<Container>
			<Row className="mt-5">
				<Col xs="12" md="7" className="position-relative justify-content-center">
					{product.images.length !== 0 ? (
						<div style={{ marginBottom: "15%" }}>
							<Carousel
								responsive={responsiveProduct}
								removeArrowOnDeviceType={["tablet", "mobile"]}
								infinite={true}
								showDots
								customDot={
									<CustomDot
										carouselItems={product.images?.map((image, index) => {
											return (
												<div
													key={index}
													style={{
														height: "4rem",
														width: "4rem",
														backgroundImage: `url("${image.src}")`,
														backgroundSize: "cover",
													}}
													className="image-default"
												/>
											);
										})}
									/>
								}
								renderDotsOutside
							>
								{product.images?.map((image, index) => {
									return (
										<div
											key={index}
											style={{
												aspectRatio: 1,
												backgroundImage: `url("${image.src}")`,
												backgroundSize: "cover",
											}}
											className="image-default"
										/>
									);
								})}
							</Carousel>
						</div>
					) : (
						""
					)}
				</Col>

				<Col xs="12" md="5" className="mt-3 mt-md-0">
					<div style={{ fontWeight: "600" }} className="text-uppercase mb-3">
						{language ? product.category.nameVi : product.category.nameEn}
					</div>

					<div style={{ fontSize: "1.5rem" }} className="fw-bold">
						<div className="text-overflow-2-line">{language ? product.nameVi : product.nameEn}</div>
						<div className="d-flex align-items-center my-2">
							<MoneyFormat
								money={
									language
										? !isNaN(product.salePriceVi)
											? product.salePriceVi
											: product.priceVi
										: !isNaN(product.salePriceEn)
											? product.salePriceEn
											: product.priceEn
								}
								className="me-2"
							/>
							{!isNaN(product.salePriceVi) ?
								<MoneyFormat
									money={language ? (product.salePriceVi ? product.priceVi : "") : product.salePriceEn ? product.priceEn : ""}
									style={{ fontSize: "1rem", color: "#929292", textDecoration: "line-through" }}
								/>
								: <></>
							}
						</div>
					</div>

					<div className="my-4 border-top" />

					<div className="mb-5">
						<Row className="mb-3">
							<Col xs="4">{language ? "Màu sắc" : "Color"}</Col>
							<Col xs="8" className="d-flex flex-wrap">
								{productColors?.map((color) => {
									return (
										<button
											style={
												selectedColor.id === color.id
													? {
														fontSize: "0.8rem",
														borderRadius: 0,
														color: "white",
														backgroundColor: "black",
													}
													: {
														fontSize: "0.8rem",
														borderRadius: 0,
														backgroundColor: "white",
													}
											}
											key={color.id}
											className="border py-1 px-2 me-2 mb-2"
											onClick={() => setSelectedColor(color)}
										>
											{language ? color.nameVi : color.nameEn}
										</button>
									);
								})}
							</Col>
						</Row>

						<Row className="mb-3">
							<Col xs="4">{language ? "Kích thước" : "Size"}</Col>
							<Col xs="8" className="d-flex flex-wrap">
								{productSizes.map((size) => {
									return (
										<button
											style={
												selectedSize.id === size.id
													? {
														fontSize: "0.8rem",
														borderRadius: 0,
														color: "white",
														backgroundColor: "black",
													}
													: {
														fontSize: "0.8rem",
														borderRadius: 0,
														backgroundColor: "white",
													}
											}
											key={size.id}
											className="border py-1 px-2 me-2 mb-2"
											onClick={() => setSelectedSize(size)}
										>
											{size.name}
										</button>
									);
								})}
							</Col>
						</Row>
						<div className="text-danger mb-3">
							{isNotSelectVariation ? (language ? "Vui lòng chọn phân loại hàng" : "Please select variation") : ""}
						</div>

						<Row className="mb-3">
							<Col xs="4">{language ? "Số lượng" : "Quantity"}</Col>
							<Col xs="8" className="d-flex">
								<button
									style={{ backgroundColor: "white" }}
									className="border py-1 px-2"
									onClick={() => {
										if (quantity > 1) {
											setQuantity(quantity - 1);
										}
									}}
								>
									<Icon icon="minus" />
								</button>
								<div className="border py-1 px-2">{quantity}</div>
								<button style={{ backgroundColor: "white" }} className="border py-1 px-2" onClick={() => setQuantity(quantity + 1)}>
									<Icon icon="plus" />
								</button>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col xs="4"></Col>
							<Col xs="8" className="text-end">
								{language ? "Tổng cộng:" : "Total:"}{" "}
								<MoneyFormat
									money={
										language
											? !isNaN(product.salePriceVi)
												? Number(product.salePriceVi * quantity)
												: Number(product.priceVi * quantity)
											: !isNaN(product.salePriceEn)
												? Number(product.salePriceEn * quantity)
												: Number(product.priceEn * quantity)
									}
									style={{ fontWeight: 600 }}
								/>
							</Col>
						</Row>
					</div>

					<Button
						className="mlb-button px-4 py-3"
						onClick={() => {
							setIsNotSelectVariation(false);

							if (Object.keys(selectedColor).length === 0 || Object.keys(selectedSize).length === 0) {
								setIsNotSelectVariation(true);
								return;
							}

							if (localStorage.getItem("access_token")) {
								dispatch(
									addToUserCart({
										id: product.id,
										image: product.images[0].src,
										nameVi: product.nameVi,
										nameEn: product.nameEn,
										priceVi: !isNaN(product.salePriceVi) ? product.salePriceVi : product.priceVi,
										priceEn: !isNaN(product.salePriceEn) ? product.salePriceEn : product.priceEn,
										color: selectedColor,
										size: selectedSize,
										quantity: quantity,
									}),
								);
							} else {
								dispatch(
									addToCart({
										id: product.id,
										image: product.images[0].src,
										nameVi: product.nameVi,
										nameEn: product.nameEn,
										priceVi: !isNaN(product.salePriceVi) ? product.salePriceVi : product.priceVi,
										priceEn: !isNaN(product.salePriceEn) ? product.salePriceEn : product.priceEn,
										color: selectedColor,
										size: selectedSize,
										quantity: quantity,
									}),
								);
							}

							toast(language ? VI_CART_CREATE : EN_CART_CREATE, {
								type: "success",
								autoClose: 2000,
							});
						}}
					>
						{language ? "Thêm Vào Giỏ Hàng" : "Add To Cart"}
					</Button>
				</Col>
			</Row>

			<div className="my-5">
				{language ? (
					product.contentVi ? (
						<TextEditor disable hideToolbar={true} setContents={product.contentVi} onChange={() => { }} />
					) : (
						""
					)
				) : product.contentEn ? (
					<TextEditor disable hideToolbar={true} setContents={product.contentEn} onChange={() => { }} />
				) : (
					""
				)}
			</div>

			{/* <Section
				title={language ? "Sản phẩm liên quan" : "RELATED PRODUCTS"}
			>
				<Carousel
					responsive={responsiveRelatedProduct}
					removeArrowOnDeviceType={["tablet", "mobile"]}
					infinite={true}
					className="mb-5"
				>
					{others.map((item, index) => {
						return (
							<ProductItem
								key={item.id}
								index={index + 1}
								className="me-3"
								item={item}
								isSwapImage={true}
							/>
						);
					})}
				</Carousel>
			</Section> */}
		</Container>
	);
};

export default Product;
