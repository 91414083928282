import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const StaffAddModal = ({ handleCloseModal, showModal, staffId }) => {
	const language = useSelector((state) => state.language.isVietnamese);

	const [isSendForm, setIsSendForm] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [permissions, setPermissions] = useState([]);
	const [staffPermissions, setStaffPermissions] = useState([]);

	useEffect(() => {
		getAllPermission();
		getStaffPermission();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getStaffPermission = async () => {
		await axios
			.get(`/permission/staff/${staffId}`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setStaffPermissions(
						res.data.permissions.map((permission) => permission.id),
					);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllPermission = async () => {
		setIsLoading(true);
		await axios
			.get(`/permission/list`)
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setPermissions(res.data.permissions);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateUserPermission = () => {
		setIsSendForm(true);
		axios
			.put(`/permission/update/${staffId}`, {
				permissions: staffPermissions,
			})
			.then((res) => {
				setIsSendForm(false);
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});

					handleCloseModal(true);
				} else {
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	return (
		<Modal
			size="lg"
			show={showModal}
			onHide={() => handleCloseModal(false)}
			backdrop="static"
		>
			<Modal.Header closeButton={!isSendForm}>
				<Modal.Title>
					{language
						? "Cài đặt quyền nhân viên"
						: "Staff permission setting"}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form
					id="update-staff-permission-hook-form"
					onSubmit={(e) => {
						e.preventDefault();
						updateUserPermission();
					}}
				>
					<Row>
						{permissions.map((permission) => {
							return (
								<Col md="6" xl="4" key={permission.id}>
									<Form.Check
										type={`checkbox`}
										className="me-1"
										name="permissions"
										value={permission.id}
										checked={staffPermissions.some(
											(staffPermission) =>
												staffPermission ===
												permission.id,
										)}
										onChange={(e) => {
											if (e.target.checked) {
												setStaffPermissions([
													...staffPermissions,
													Number(e.target.value),
												]);
											} else {
												setStaffPermissions(
													staffPermissions.filter(
														(permission) =>
															permission !==
															Number(
																e.target.value,
															),
													),
												);
											}
										}}
										label={
											language
												? permission.nameVi
												: permission.nameEn
										}
									/>
								</Col>
							);
						})}
					</Row>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button
					type="button"
					variant="secondary"
					onClick={() => handleCloseModal(false)}
					disabled={isSendForm}
				>
					{language ? "Đóng" : "Close"}
				</Button>
				<Button
					type="submit"
					form="update-staff-permission-hook-form"
					variant="dark"
					disabled={isLoading || isSendForm}
				>
					{language ? "Cập nhật" : "Update"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default StaffAddModal;
