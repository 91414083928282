import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";

import yup from "../../../utils/yupGlobal";
import convertToSlug from "../../../utils/slugFormatter";
import { EN_FIELD_REQUIRE, EN_FILE_REQUIRE_2_10, EN_FILE_SIZE, VI_FIELD_REQUIRE, VI_FILE_REQUIRE_2_10, VI_FILE_SIZE } from "../../../utils/message";
import Icon from "../../../components/Icon";

const addCategoryFormItems = [
	{
		id: 1,
		placeholderVi: "Tên danh mục Tiếng việt",
		placeholderEn: "Vietnamese Category name",
		name: "nameVi",
	},
	{
		id: 2,
		placeholderVi: "Tên danh mục Tiếng anh",
		placeholderEn: "English Category name",
		name: "nameEn",
	},
];

const CategoryAddModal = ({ handleCloseModal, showModal, parentCategory }) => {
	const language = useSelector((state) => state.language.isVietnamese);

	const [imageId, setImageId] = useState(0);
	const [categoryImages, setCategoryImages] = useState([]);

	const [slug, setSlug] = useState("");
	const [errMessage, setErrMessage] = useState({
		nameVi: undefined,
		nameEn: undefined,
		slug: undefined,
	});

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(language ? SchemaVi : SchemaEn),
	});

	const createNewCategory = async (data) => {
		await axios
			.post(`/category/create`, {
				categoryId: parentCategory.id,
				nameVi: data.nameVi,
				nameEn: data.nameEn,
				slug: slug,
				images: categoryImages.map((imageItem) => {
					return imageItem.src.split(",")[1];
				}),
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});
					handleCloseModal(true);
				} else {
					setErrMessage(res.data.errMessage);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleUploadImage = (event) => {
		setImageId(imageId + 1);

		try {
			if (!event.target.files[0].type.match(/image.*/) || event.target.files[0].size > 2097152) {
				toast(language ? VI_FILE_SIZE : EN_FILE_SIZE, {
					type: "error",
					autoClose: 1000,
				});

				return;
			}
			let reader = new FileReader();
			reader.onload = function (e) {
				setCategoryImages([
					...categoryImages,
					{
						id: imageId,
						src: e.target.result,
					},
				]);
			};
			reader.readAsDataURL(event.target.files[0]);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Modal show={showModal} onHide={() => handleCloseModal(false)} backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>{language ? "Thêm mới danh mục" : "Add new category"}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					id="add-category-hook-form"
					onSubmit={handleSubmit((data) => {
						if (categoryImages.length > 10) {
							toast(language ? VI_FILE_REQUIRE_2_10 : EN_FILE_REQUIRE_2_10, {
								type: "error",
								autoClose: 1000,
							});
							return;
						}

						createNewCategory(data);
					})}
				>
					{parentCategory.id !== 0 ? (
						<Form.Group className="mb-3">
							<Form.Label className="fw-bold">{language ? "Tên danh mục cha" : "Parent category name"}</Form.Label>
							<Form.Floating>
								<Form.Control size="md" type="text" placeholder={language ? "Tên danh mục cha" : "Parent category name"} disabled />
								<Form.Label>{language ? parentCategory.nameVi : parentCategory.nameEn}</Form.Label>
							</Form.Floating>
						</Form.Group>
					) : (
						""
					)}

					{addCategoryFormItems.map((formItem) => {
						return (
							<div key={formItem.id} className="mb-3">
								<Form.Floating>
									<Form.Control
										type="text"
										placeholder={language ? formItem.placeholderVi : formItem.placeholderEn}
										name={formItem.name}
										{...register(formItem.name, {
											onChange: () => {
												if (formItem.id === 2) {
													setSlug(convertToSlug(getValues("nameEn")));
												}
											},
										})}
									/>

									<Form.Label>
										{language ? formItem.placeholderVi : formItem.placeholderEn}
										<i className="text-danger">*</i>
									</Form.Label>
								</Form.Floating>

								{errors[formItem.name] && (
									<p
										style={{
											fontSize: 13,
											color: "red",
											marginTop: 6,
										}}
									>
										{errors[formItem.name].message}
									</p>
								)}

								{errMessage[formItem.name] && (
									<p
										style={{
											fontSize: 13,
											color: "red",
											marginTop: 6,
										}}
									>
										{errMessage[formItem.name]}
									</p>
								)}
							</div>
						);
					})}

					<div className="mb-3">
						<Form.Floating>
							<Form.Control type="text" placeholder="Slug" name="slug" disabled value={slug} />

							<Form.Label>
								Slug
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>

						{errMessage.slug && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errMessage.slug}
							</p>
						)}
					</div>
				</Form>

				{/* Images */}
				<div>
					<div className="d-flex justify-content-evenly flex-wrap">
						{categoryImages.length !== 0
							? categoryImages.map((imageItem) => {
									return (
										<div
											key={imageItem.id}
											style={{
												aspectRatio: "1430/640",
												backgroundImage: `url("${imageItem.src}")`,
												backgroundSize: "cover",
											}}
											className="image-default position-relative mb-5"
										>
											<button
												style={{
													height: "1.5rem",
													width: "1.5rem",
													border: "none",
												}}
												className="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger"
												onClick={() => {
													setCategoryImages(categoryImages.filter((item) => item.id !== imageItem.id));
												}}
											>
												<Icon icon="x" />
											</button>
										</div>
									);
							  })
							: ""}
					</div>

					<input type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e) => handleUploadImage(e)} required />
					<div>
						<div className="d-inline fw-bold">Note: </div>
						{language
							? "Chọn ảnh với tỉ lệ 1430:640 để được chất lượng tốt nhất."
							: "Please select images with a 1430:640 ratio for the best quality."}
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<Button type="button" variant="secondary" onClick={() => handleCloseModal(false)}>
					{language ? "Đóng" : "Close"}
				</Button>
				<Button type="submit" form="add-category-hook-form" variant="dark">
					{language ? "Lưu" : "Save"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const SchemaVi = yup.object().shape({
	nameVi: yup.string().required(VI_FIELD_REQUIRE),
	nameEn: yup.string().required(VI_FIELD_REQUIRE),
});

const SchemaEn = yup.object().shape({
	nameVi: yup.string().required(EN_FIELD_REQUIRE),
	nameEn: yup.string().required(EN_FIELD_REQUIRE),
});

export default CategoryAddModal;
