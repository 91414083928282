import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../utils/yupGlobal";

import { useDispatch, useSelector } from "react-redux";
import { removeCart, removeFromCart, updateCart } from "../../store/slices/cartSlice";

import { Button, Card, Col, Container, Form, ProgressBar, Row, Table } from "react-bootstrap";

import Icon from "../../components/Icon";
import Input from "../../components/Input";
import MoneyFormat from "../../components/MoneyFormat";
import { toast } from "react-toastify";
import { CATEGORY } from "../../routes/routerUrl";
import axios from "axios";
import {
	EN_CART_REMOVE,
	EN_EMAIL_FORMAT,
	EN_ERROR_SEND_FORM,
	EN_FIELD_REQUIRE,
	EN_PHONE_FORMAT,
	VI_CART_REMOVE,
	VI_EMAIL_FORMAT,
	VI_ERROR_SEND_FORM,
	VI_FIELD_REQUIRE,
	VI_PHONE_FORMAT,
} from "../../utils/message";
import swal from "sweetalert";
import { removeFromUserCart, removeUserCart, updateUserCart } from "../../store/slices/userCartSlice";

const Cart = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const language = useSelector((state) => state.language.isVietnamese);
	const cart = useSelector((state) => (localStorage.getItem("access_token") ? state.userCart.userCart : state.cart.cart));

	const [currentStep, setCurrentStep] = useState(1);
	const [totalMoneyVi, setTotalMoneyVi] = useState(0);
	const [totalMoneyEn, setTotalMoneyEn] = useState(0);

	const [paymentMethods, setPaymentMethods] = useState([]);
	const [selectedPayment, setSelectedPayment] = useState(1);

	const [buyMoreLink, setBuyMoreLink] = useState("");

	const {
		getValues,
		register,
		handleSubmit,
		reset,
		formState: { errors, isValid },
	} = useForm({
		mode: "onChange",
		resolver: yupResolver(language ? SchemaVi : SchemaEn),
	});

	useEffect(() => {
		document.title = language ? "Giỏ hàng" : "Shopping Cart";

		getAllPaymentMethods();
		getAllCategories();

		if (localStorage.getItem("access_token")) {
			getDetailUser();
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let moneyVi = 0;
		let moneyEn = 0;

		cart.map((item) => {
			moneyVi += item.priceVi * item.quantity;
			moneyEn += item.priceEn * item.quantity;

			return item;
		});

		setTotalMoneyVi(moneyVi);
		setTotalMoneyEn(moneyEn);
	}, [cart]);

	// API
	const getDetailUser = async () => {
		await axios
			.get(`/user`)
			.then((res) => {
				if (res.data.errCode === 0) {
					reset({
						fullname: res.data.user.fullname,
						email: res.data.user.email,
						phonenumber: res.data.user.phonenumber,
						address: res.data.user.address,
					});
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllCategories = () => {
		axios
			.get(`/category/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setBuyMoreLink(res.data.categories[0].slug);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllPaymentMethods = () => {
		axios
			.get(`/payment/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setPaymentMethods(res.data.payments);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const createNewOrder = async (data) => {
		await axios
			.post(`/order/create`, {
				paymentMethod: selectedPayment,
				phonenumber: data.phonenumber,
				fullname: data.fullname,
				address: data.address,
				message: data.message,
				products: cart.map((item) => {
					return {
						productId: item.id,
						quantity: item.quantity,
						priceVi: item.priceVi,
						priceEn: item.priceEn,
						sizeId: item.size.id,
						colorId: item.color.id,
					};
				}),
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					if (localStorage.getItem("access_token")) {
						dispatch(removeUserCart());
					} else {
						// Store order of anonymous user
						const oldOrder = JSON.parse(localStorage.getItem("anonymousUserOrder")) || [];

						const newOrder = [...oldOrder, res.data.orderId];
						localStorage.setItem("anonymousUserOrder", JSON.stringify(newOrder));

						dispatch(removeCart());
					}

					navigate("/orderSuccess");
				} else {
					toast(language ? VI_ERROR_SEND_FORM : EN_ERROR_SEND_FORM, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log('err>>', err);
			});
	};

	const RenderNavigateButton = () => {
		if (currentStep === 1) {
			return (
				<Row>
					<Col xs="6">
						<Button size="md" className="mlb-button me-2 w-100" onClick={() => setCurrentStep(2)} disabled={cart.length === 0}>
							<Icon icon="arrow-right" className="me-2" />
							{language ? "Tiếp theo" : "Next"}
						</Button>
					</Col>
					<Col xs="6">
						<Link to={`${CATEGORY}/${buyMoreLink}`}>
							<Button size="md" className="mlb-button me-2 w-100">
								<Icon icon="cart-plus" className="me-2" />
								{language ? "Mua thêm" : "Buy more"}
							</Button>
						</Link>
					</Col>
				</Row>
			);
		}

		if (currentStep === 2) {
			return (
				<Row>
					<Col xs="6">
						<Button size="md" className="mlb-button me-2 w-100" onClick={() => setCurrentStep(1)}>
							<Icon icon="cart-shopping" className="me-2" />

							{language ? "Giỏ hàng" : "Cart"}
						</Button>
					</Col>
					<Col xs="6">
						<Button disabled={!isValid} size="md" className="mlb-button me-2 w-100" onClick={() => setCurrentStep(3)}>
							<Icon icon="arrow-right" className="me-2" />
							{language ? "Tiếp theo" : "Next"}
						</Button>
					</Col>
				</Row>
			);
		}

		if (currentStep === 3) {
			return (
				<div className="d-flex">
					<Button size="md" className="mlb-button me-2 w-100" onClick={() => setCurrentStep(1)}>
						<Icon icon="cart-shopping" className="me-2" />
						{language ? "Giỏ hàng" : "Cart"}
					</Button>
					<Button size="md" className="mlb-button me-2 w-100" onClick={() => setCurrentStep(2)}>
						<Icon icon="arrow-left" className="me-2" />
						{language ? "Quay lại" : "Back"}
					</Button>
					<Button form="add-order-hook-form" type="submit" size="md" className="mlb-button me-2 w-100">
						<Icon icon="money-bill-1" className="me-2" />
						{language ? "Đặt hàng" : "Place order"}
					</Button>
				</div>
			);
		}
	};

	return (
		<Container>
			<div className="title text-center my-3">{language ? "Giỏ hàng" : "Cart"}</div>

			<ProgressBar className="cart-progress-bar mb-4">
				<ProgressBar
					label={language ? "Giỏ hàng" : "Cart"}
					className={currentStep === 1 ? "cart-progress-item-active" : "cart-progress-item"}
					now={100 / 3}
					key={1}
				/>
				<ProgressBar
					label={language ? "Đặt hàng" : "Order"}
					className={currentStep === 2 ? "cart-progress-item-active" : "cart-progress-item"}
					now={100 / 3}
					key={2}
				/>
				<ProgressBar
					label={language ? "Hoàn thành" : "Complete"}
					className={currentStep === 3 ? "cart-progress-item-active" : "cart-progress-item"}
					now={100 / 3}
					key={3}
				/>
			</ProgressBar>

			<Table responsive>
				<thead>
					<tr className="align-middle">
						<th>{language ? "Hình ảnh" : "Image"}</th>
						<th>{language ? "Tên sản phẩm" : "Product name"}</th>
						<th>{language ? "Giá tiền" : "Price"}</th>
						<th>{language ? "Màu" : "Color"}</th>
						<th>{language ? "Kích cỡ" : "Size"}</th>
						<th>{language ? "Số lượng" : "Quantity"}</th>
						<th>{language ? "Tổng" : "Total"}</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{cart.length !== 0 ? (
						cart.map((item, index) => {
							return (
								<tr key={index} className="align-middle">
									<td>
										<div
											style={{
												height: "4rem",
												width: "4rem",
												backgroundImage: `url("${item.image}")`,
												backgroundSize: "cover",
											}}
											className="image-default"
										/>
									</td>
									<td>
										<div className="fw-bold">{language ? item.nameVi : item.nameEn}</div>
									</td>
									<td className="fw-bold">
										<MoneyFormat money={language ? item.priceVi : item.priceEn} />
									</td>
									<td>{language ? item.color.nameVi : item.color.nameEn}</td>
									<td>{item.size.name}</td>
									<td>
										{currentStep === 1 ? (
											<div className="d-flex">
												<button
													style={{
														backgroundColor: "white",
													}}
													className="border py-1 px-2"
													onClick={() => {
														if (localStorage.getItem("access_token")) {
															dispatch(
																updateUserCart({
																	id: item.id,
																	color: item.color,
																	size: item.size,
																	amount: -1,
																}),
															);
														} else {
															dispatch(
																updateCart({
																	id: item.id,
																	color: item.color,
																	size: item.size,
																	amount: -1,
																}),
															);
														}
													}}
												>
													<Icon icon="minus" />
												</button>
												<div className="border py-1 px-2">{item.quantity}</div>
												<button
													style={{
														backgroundColor: "white",
													}}
													className="border py-1 px-2"
													onClick={() => {
														if (localStorage.getItem("access_token")) {
															dispatch(
																updateUserCart({
																	id: item.id,
																	color: item.color,
																	size: item.size,
																	amount: 1,
																}),
															);
														} else {
															dispatch(
																updateCart({
																	id: item.id,
																	color: item.color,
																	size: item.size,
																	amount: 1,
																}),
															);
														}
													}}
												>
													<Icon icon="plus" />
												</button>
											</div>
										) : (
											item.quantity
										)}
									</td>
									<td className="fw-bold">
										<MoneyFormat money={language ? Number(item.priceVi * item.quantity) : Number(item.priceEn * item.quantity)} />
									</td>
									<td>
										{currentStep === 1 ? (
											<button
												style={{
													backgroundColor: "white",
													border: "none",
												}}
												onClick={() => {
													if (localStorage.getItem("access_token")) {
														dispatch(
															removeFromUserCart({
																id: item.id,
																color: item.color,
																size: item.size,
															}),
														);
													} else {
														dispatch(
															removeFromCart({
																id: item.id,
																color: item.color,
																size: item.size,
															}),
														);
													}

													toast(language ? VI_CART_REMOVE : EN_CART_REMOVE, {
														type: "success",
														autoClose: 2000,
													});
												}}
											>
												<Icon icon="xmark" />
											</button>
										) : (
											""
										)}
									</td>
								</tr>
							);
						})
					) : (
						<tr>
							<td colSpan={6} className="text-center py-4">
								{language ? "Giỏ hàng đang trống" : "Your cart is empty"}
							</td>
						</tr>
					)}
				</tbody>
			</Table>

			{currentStep === 1 && cart.length !== 0 ? (
				<div className="d-flex justify-content-end my-3">
					<Button
						size="md"
						style={{
							borderRadius: 0,
						}}
						className="mlb-button me-2"
						onClick={() => {
							if (localStorage.getItem("access_token")) {
								dispatch(removeUserCart());
							} else {
								dispatch(removeCart());
							}

							toast(language ? VI_CART_REMOVE : EN_CART_REMOVE, {
								type: "success",
								autoClose: 2000,
							});
						}}
					>
						<Icon icon="xmark" /> {language ? "Hủy" : "Cancel"}
					</Button>
				</div>
			) : (
				""
			)}

			<Form
				id="add-order-hook-form"
				onSubmit={handleSubmit((data) => {
					swal({
						title: language ? `Xác nhận` : "Confirm",
						text: language
							? `Bấm đặt hàng nghĩa là bạn đã xác định chính xác thông tin đặt hàng và hoàn toàn đồng ý tạo đơn hàng.`
							: "Clicking to place an order means you have correctly identified the order information and fully agree to create the order.",
						icon: "warning",
						buttons: [language ? "Đóng" : "Close", language ? "Xác nhận" : "Confirm"],
						successMode: true,
					}).then((ok) => {
						if (ok) {
							createNewOrder(data);
						}
					});
				})}
			>
				<Card className="p-3 mb-3">
					<Row>
						<Col xs="12" md="6">
							{currentStep === 2 ? (
								<section>
									<h5 className="fw-bold mb-3">{language ? "Thông tin khách hàng" : "Customer information"}</h5>
									<Input
										placeholder={language ? "Số điện thoại:" : "Telephone Number:"}
										name="phonenumber"
										register={register("phonenumber")}
										errors={errors.phonenumber}
										className="mb-3"
									/>
									<Input
										placeholder={language ? "Tên khách hàng:" : "Fullname:"}
										name="fullname"
										register={register("fullname")}
										errors={errors.fullname}
										className="mb-3"
									/>
									<Input
										placeholder={language ? "Nhập địa chỉ nhà:" : "Address:"}
										name="address"
										register={register("address")}
										errors={errors.address}
										className="mb-3"
									/>
									<Input
										as="textarea"
										rows={5}
										placeholder={language ? "Lời nhắn:" : "Message:"}
										name="message"
										register={register("message")}
										errors={errors.message}
										className="mb-3"
									/>
									<div className="mb-3">
										{paymentMethods.map((payment) => {
											return (
												<Form.Check
													value={payment.id}
													checked={selectedPayment === payment.id}
													onChange={() => setSelectedPayment(payment.id)}
													key={payment.id}
													type={"radio"}
													label={`${language ? payment.nameVi : payment.nameEn}`}
													name="payment"
												/>
											);
										})}
									</div>
								</section>
							) : (
								""
							)}

							{currentStep === 3 ? (
								<section>
									<h5 className="fw-bold mb-3">{language ? "Thông tin khách hàng" : "Customer information"}</h5>

									<div className="mb-2">
										<div className="d-inline fw-bold">{language ? "Số điện thoại:" : "Telephone Number:"}</div> {getValues("phonenumber")}
									</div>
									<div className="mb-2">
										<div className="d-inline fw-bold">{language ? "Họ và tên:" : "Fullname:"}</div> {getValues("fullname")}
									</div>
									<div className="mb-2">
										<div className="d-inline fw-bold">{language ? "Địa chỉ:" : "Address:"}</div> {getValues("address")}
									</div>

									<div className="mb-2">
										<div className="d-inline fw-bold">{language ? "Hình thức thanh toán:" : "Payment method:"}</div>{" "}
										{language
											? paymentMethods.find((payment) => payment.id === selectedPayment)?.nameVi
											: paymentMethods.find((payment) => payment.id === selectedPayment)?.nameEn}
									</div>
								</section>
							) : (
								""
							)}
						</Col>
						<Col xs="12" md="6">
							<h5 className="fw-bold mb-3">{language ? "Đơn hàng của bạn" : "Your cart"}</h5>

							<Button
								size="md"
								style={{
									borderRadius: 0,
								}}
								className="mlb-button w-100 my-3 d-flex justify-content-between"
								disabled
							>
								{language ? "Tổng tiền hàng:" : "Total: "}

								<MoneyFormat money={language ? totalMoneyVi : totalMoneyEn} />
							</Button>

							{RenderNavigateButton()}
						</Col>
					</Row>
				</Card>
			</Form>
		</Container>
	);
};

const SchemaVi = yup.object().shape({
	fullname: yup.string().required(VI_FIELD_REQUIRE),
	phonenumber: yup.string().required(VI_FIELD_REQUIRE).phone(VI_PHONE_FORMAT),
	email: yup.string().email(VI_EMAIL_FORMAT),
	address: yup.string().required(VI_FIELD_REQUIRE),
});

const SchemaEn = yup.object().shape({
	fullname: yup.string().required(EN_FIELD_REQUIRE),
	phonenumber: yup.string().required(EN_FIELD_REQUIRE).phone(EN_PHONE_FORMAT),
	email: yup.string().email(EN_EMAIL_FORMAT),
	address: yup.string().required(EN_FIELD_REQUIRE),
});

export default Cart;
