import CurrencyFormat from "react-currency-format";
import { useSelector } from "react-redux";

const MoneyFormat = ({ money, style, className }) => {
	const language = useSelector((state) => state.language.isVietnamese);

	return (
		<CurrencyFormat
			value={money}
			displayType={"text"}
			thousandSeparator={true}
			allowNegative={false}
			isNumericString
			prefix={language ? "" : ""}
			suffix={language ? " AUD" : " USD"}
			style={style}
			className={`${className}`}
		/>
	);
};

export default MoneyFormat;
