import { useEffect, useState } from "react";
import axios from "axios";
import {
	Breadcrumb,
	Button,
	Col,
	Container,
	Form,
	Row,
	Table,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import Icon from "../../../components/Icon";
import DataTables from "../../../components/DataTables";
import Loading from "../../../components/Loading";
import swal from "sweetalert";

const User = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const [users, setUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [keyword, setKeyword] = useState("");

	useEffect(() => {
		getAllUsers();
	}, []);

	// Api
	const getAllUsers = async (keyword) => {
		setIsLoading(true);
		await axios
			.get(`/user/list`, {
				params: {
					role: 3,
					keyword: keyword,
				},
			})
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					setUsers(res.data.users);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteUser = async (userId) => {
		await axios
			.delete(`/user/delete/${userId}`)
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});

					getAllUsers("");
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleDeleteUser = (userId) => {
		swal({
			title: language
				? `Bạn muốn xóa tài khoản này`
				: "You want to delete this account",
			icon: "warning",
			buttons: [language ? "Đóng" : "Close", language ? "Xóa" : "Delete"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				deleteUser(userId);
			}
		});
	};

	const columns = [
		{
			name: language ? "Họ và tên" : "Fullname",
			selector: (row) => row.fullname,
			wrap: true,
		},
		{
			name: language ? "Số điện thoại" : "Phone number",
			selector: (row) => row.phonenumber,
			wrap: true,
		},
		{
			name: "Email",
			selector: (row) => row.email,
			wrap: true,
		},
		{
			name: language ? "Địa chỉ" : "Address",
			selector: (row) => row.address,
			wrap: true,
		},
		{
			name: language ? "Phương thức" : "Method",
			style: {
				display: "flex",
				justifyContent: "center",
			},
			selector: (row) => (
				<Button
					size="sm"
					variant="danger"
					type="button"
					onClick={() => handleDeleteUser(row.id)}
					title="Xóa"
				>
					<Icon icon="trash" />
				</Button>
			),
		},
	];

	return (
		<Container fluid>
			{language ? (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item active>Tổng quan</Breadcrumb.Item>
					<Breadcrumb.Item active>Khách hàng</Breadcrumb.Item>
				</Breadcrumb>
			) : (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item active>Customer</Breadcrumb.Item>
				</Breadcrumb>
			)}

			<Row className="justify-content-between mb-3">
				<Col xs="12" md="4">
					<Form
						className="d-flex"
						onSubmit={(e) => {
							e.preventDefault();
							getAllUsers(keyword);
						}}
					>
						<Form.Control
							type="search"
							placeholder={
								language
									? "Nhập tên khách hàng"
									: "Enter the customer name"
							}
							className="me-2"
							aria-label="Search"
							value={keyword}
							onChange={(e) => setKeyword(e.target.value)}
						/>
						<Button type="submit" variant="outline-secondary">
							<Icon icon="magnifying-glass" />
						</Button>
					</Form>
				</Col>

				<Col
					xs="12"
					md="4"
					className="text-start text-md-end mt-3 mt-md-0"
				></Col>
			</Row>

			{isLoading ? (
				<Loading />
			) : (
				<DataTables data={users} columns={columns} />
			)}

			<Table id="customers-table" className="d-none">
				<thead>
					<tr className="text-center align-middle">
						<th>{language ? "STT" : "No"}</th>
						<th>{language ? "Họ và tên" : "Fullname"}</th>
						<th>{language ? "Số điện thoại" : "Phone number"}</th>
						<th>Email</th>
						<th>{language ? "Địa chỉ" : "Address"}</th>
					</tr>
				</thead>
				<tbody>
					{users.map((user, index) => {
						return (
							<tr key={user.id}>
								<td>{index + 1}</td>
								<td>
									{user.fullname ? user.fullname : "empty"}
								</td>
								<td>
									{user.phonenumber
										? user.phonenumber
										: "empty"}
								</td>
								<td>{user.email ? user.email : "empty"}</td>
								<td>{user.address ? user.address : "empty"}</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</Container>
	);
};

export default User;
