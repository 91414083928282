import * as XLSX from "xlsx-js-style";

const fontStyle = {
	name: "Times New Roman",
};

const borderStyle = {
	top: { style: "thin" },
	bottom: { style: "thin" },
	left: { style: "thin" },
	right: { style: "thin" },
};

const exportProductList = (productList) => {
	const table = document.getElementById("product-table");

	var ws = XLSX.utils.table_to_sheet(table);

	const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
	const moneyColAlpha = ["D", "E", "F", "G"];

	// Header
	colAlpha.map((alpha) => {
		ws[`${alpha}1`].s = {
			font: {
				...fontStyle,
				bold: true,
			},
			border: borderStyle,
			alignment: {
				vertical: "center",
				horizontal: "center",
			},
		};

		return alpha;
	});

	colAlpha.map((alpha) => {
		ws[`${alpha}2`].s = {
			font: {
				...fontStyle,
				bold: true,
			},
			border: borderStyle,
			alignment: {
				vertical: "center",
				horizontal: "center",
			},
		};

		return alpha;
	});
	// Header

	let index = 3;
	productList.map((item) => {
		colAlpha.map((alpha) => {
			if (ws[`${alpha}${index}`].v === "empty") {
				ws[`${alpha}${index}`].v = " ";
			}

			ws[`${alpha}${index}`].s = {
				font: fontStyle,
				border: borderStyle,
				alignment: {
					vertical: "center",
					wrapText: alpha === "H" || alpha === "I" ? false : true,
				},
			};

			return alpha;
		});

		moneyColAlpha.map((alpha) => {
			ws[`${alpha}${index}`].z = "#,###,###,###";
			delete ws[`${alpha}${index}`].w;
			XLSX.utils.format_cell(ws[`${alpha}${index}`]);

			return alpha;
		});

		index += 1;

		return item;
	});

	var wscols = [{ wch: 5 }, { wch: 25 }, { wch: 25 }, { wch: 12 }, { wch: 12 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 30 }];
	ws["!cols"] = wscols;
	ws["!rows"] = [{ hpt: 20 }, { hpt: 1 }];

	return ws;
};

export { exportProductList };
