import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";

import Select from "react-select";
import { EN_FILE_SIZE, VI_FILE_SIZE } from "../../../utils/message";

const sizeOptions = [
	{ id: 1, name: "XXS" },
	{ id: 2, name: "XS" },
	{ id: 3, name: "S" },
	{ id: 4, name: "M" },
	{ id: 5, name: "L" },
	{ id: 6, name: "XL" },
	{ id: 7, name: "XXL" },
];

const ProductImportModal = ({ handleCloseModal, showModal }) => {
	const language = useSelector((state) => state.language.isVietnamese);

	const [productFile, setProductFile] = useState(null);

	const [colors, setColors] = useState([]);

	const [productColors, setProductColors] = useState([]);
	const [productSizes, setProductSizes] = useState([]);

	const [isSelectSize, setIsSelectSize] = useState(false);
	const [isSelecColor, setIsSelecColor] = useState(false);

	const [selectedCategory, setSelectedCategory] = useState();
	const [categoryOptions, setCategoryOptions] = useState([{ value: 0, label: "" }]);

	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		getAllOptionCategories();
		getAllColors();
	}, []);

	// Api
	const getAllOptionCategories = async () => {
		await axios
			.get(`/category/option`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setCategoryOptions(res.data.categories);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllColors = async () => {
		await axios
			.get(`/color/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setColors(res.data.colors);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const importProduct = async () => {
		setIsSendForm(true);

		var bodyFormData = new FormData();
		bodyFormData.append("productFile", productFile);
		bodyFormData.append("category", selectedCategory?.id);
		bodyFormData.append("sizes", JSON.stringify(productSizes?.map((size) => size.id)));
		bodyFormData.append("colors", JSON.stringify(productColors?.map((color) => color.id)));

		await axios({
			method: "post",
			url: `product/import`,
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then((res) => {
				setIsSendForm(false);

				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});
					handleCloseModal(true);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 3000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleUploadFile = (event) => {
		try {
			if (event.target.files[0].size > 2097152) {
				toast(language ? VI_FILE_SIZE : EN_FILE_SIZE, {
					type: "error",
					autoClose: 1000,
				});

				return;
			}

			setProductFile(event.target.files[0]);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Modal size="lg" show={showModal} onHide={() => handleCloseModal(false)} backdrop="static">
			<Modal.Header closeButton={!isSendForm}>
				<Modal.Title>{language ? "Thêm mới sản phẩm" : "Add new product"}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="mb-3">
					<Form.Label>{language ? "Chọn danh mục" : "Select category"}</Form.Label>

					<Select
						options={categoryOptions}
						placeholder={<div>{language ? "Chọn danh mục" : "Select category"}</div>}
						noOptionsMessage={() => (language ? "Không có lựa chọn" : "No options")}
						getOptionLabel={(option) => (language ? option.nameVi : option.nameEn)}
						getOptionValue={(option) => option.id}
						value={selectedCategory}
						onChange={(choice) => {
							setSelectedCategory(choice);
						}}
						menuPortalTarget={document.body}
						styles={{
							menuPortal: (base) => ({ ...base, zIndex: 9999 }),
						}}
					/>
				</div>

				<Form
					id="import-product-hook-form"
					onSubmit={(e) => {
						e.preventDefault();

						setIsSelectSize(false);
						setIsSelecColor(false);

						if (productSizes.length === 0) {
							setIsSelectSize(true);
							return;
						}

						if (productColors.length === 0) {
							setIsSelecColor(true);
							return;
						}

						importProduct();
					}}
					className="mb-5"
				>
					<Select
						options={sizeOptions}
						placeholder={<div>{language ? "Chọn kích cỡ" : "Select size"}</div>}
						noOptionsMessage={() => (language ? "Không có lựa chọn" : "No options")}
						getOptionLabel={(option) => (language ? option.name : option.name)}
						getOptionValue={(option) => option.id}
						value={productSizes}
						onChange={(choice) => {
							setProductSizes(choice);
						}}
						className="mb-3"
						isMulti
						menuPortalTarget={document.body}
						styles={{
							menuPortal: (base) => ({ ...base, zIndex: 9999 }),
						}}
					/>

					{isSelectSize && (
						<p
							style={{
								fontSize: 13,
								color: "red",
								marginTop: 6,
							}}
						>
							{language ? "Vui lòng chọn kích cỡ" : "Please select size"}
						</p>
					)}

					<Select
						options={colors}
						placeholder={<div>{language ? "Chọn màu" : "Select color"}</div>}
						noOptionsMessage={() => (language ? "Không có lựa chọn" : "No options")}
						getOptionLabel={(option) => (language ? option.nameVi : option.nameEn)}
						getOptionValue={(option) => option.id}
						value={productColors}
						onChange={(choice) => {
							setProductColors(choice);
						}}
						className="mb-3"
						isMulti
						menuPortalTarget={document.body}
						styles={{
							menuPortal: (base) => ({ ...base, zIndex: 9999 }),
						}}
					/>

					{isSelecColor && (
						<p
							style={{
								fontSize: 13,
								color: "red",
								marginTop: 6,
							}}
						>
							{language ? "Vui lòng chọn màu" : "Please select color"}
						</p>
					)}

					<input
						id="fileSelect"
						type="file"
						accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						onChange={(e) => handleUploadFile(e)}
						required
					/>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button type="button" variant="secondary" onClick={() => handleCloseModal(false)} disabled={isSendForm}>
					{language ? "Đóng" : "Close"}
				</Button>
				<Button type="submit" form="import-product-hook-form" variant="dark" disabled={isSendForm}>
					{language ? "Lưu" : "Save"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ProductImportModal;
