import { Breadcrumb, Button, Container, Form, Table } from "react-bootstrap";
import DataTables from "../../../components/DataTables";

import Icon from "../../../components/Icon";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import MoneyFormat from "../../../components/MoneyFormat";
import { useSelector } from "react-redux";
import moment from "moment";

import swal from "sweetalert";

import ProductAddModal from "./ProductAddModal";
import ProductEditModal from "./ProductEditModal";
import ProductImportModal from "./ProductImportModal";
import Loading from "../../../components/Loading";
import * as XLSX from "xlsx-js-style";
import { exportProductList } from "./exportFuntions";

const Product = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const [products, setProducts] = useState([]);

	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showImportModal, setShowImportModal] = useState(false);

	const [selectedProduct, setSelectedProduct] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [keyword, setKeyword] = useState("");

	useEffect(() => {
		getAllProducts("");
	}, []);

	// Api
	const getAllProducts = async (keyword) => {
		setIsLoading(true);
		await axios
			.get(`/product/list`, {
				params: {
					keyword: keyword,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setProducts(res.data.products);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteProduct = async (productId) => {
		await axios
			.delete(`/product/delete/${productId}`)
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});

					getAllProducts("");
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const exportExcel = () => {
		const wb = XLSX.utils.book_new();

		const ws = exportProductList(products);
		XLSX.utils.book_append_sheet(wb, ws, language ? "Sản phẩm" : "Product");

		XLSX.writeFile(wb, language ? `Danh sách sản phẩm.xlsx` : `Product list.xlsx`);
	};

	const handleXportProductList = () => {
		swal({
			title: language ? `Bạn muốn xuất file danh sách sản phẩm` : `You want to export product list file`,
			icon: "info",
			buttons: [language ? "Đóng" : "Close", language ? "Xuất" : "Export"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				exportExcel();
			}
		});
	};

	const handleDeleteProduct = (productId) => {
		swal({
			title: language ? `Bạn muốn xóa sản phẩm này` : "You want to delete this product",
			icon: "warning",
			buttons: [language ? "Đóng" : "Close", language ? "Xóa" : "Delete"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				deleteProduct(productId);
			}
		});
	};

	const handleCloseAddModal = (isReload) => {
		setShowAddModal(false);
		if (isReload) {
			getAllProducts("");
		}
	};
	const handleShowAddModal = () => {
		setShowAddModal(true);
		setSelectedProduct();
	};

	const handleCloseEditModal = (isReload) => {
		setShowEditModal(false);
		if (isReload) {
			getAllProducts("");
		}
	};
	const handleShowEditModal = (productId) => {
		setShowEditModal(true);
		setSelectedProduct(productId);
	};

	const handleCloseImportModal = (isReload) => {
		setShowImportModal(false);
		if (isReload) {
			getAllProducts("");
		}
	};
	const handleShowImportModal = () => {
		setShowImportModal(true);
	};

	const columns = [
		{
			name: language ? "Hình ảnh" : "Image",
			style: {
				display: "flex",
				justifyContent: "center",
			},
			selector: (row) => (
				<div
					style={{
						width: "3rem",
						height: "3rem",
						backgroundImage: `url("${row.images[0].src}")`,
					}}
					className="image-default"
				/>
			),
		},
		{
			name: language ? "Tên sản phẩm" : "Product Name",
			selector: (row) => (language ? row.nameVi : row.nameEn),
			wrap: true,
		},
		{
			name: language ? "Tên danh mục" : "Category Name",
			selector: (row) => (language ? row.category.nameVi : row.category.nameEn),
			wrap: true,
		},
		{
			name: language ? "Giá" : "Price",
			style: {
				display: "flex",
				justifyContent: "end",
			},
			selector: (row) => <MoneyFormat money={language ? row.priceVi : row.priceEn} />,
			wrap: true,
		},
		{
			name: "Slug",
			selector: (row) => row.slug,
			wrap: true,
		},
		{
			name: language ? "Cập nhật" : "Update",
			selector: (row) => (language ? moment(row.updatedAt).format("YYYY-MM-DD, hh:mm a") : moment(row.updatedAt).format("DD-MM-YYYY, hh:mm")),
		},
		{
			name: language ? "Phương thức" : "Method",
			style: {
				display: "flex",
				justifyContent: "center",
			},
			selector: (row) => (
				<>
					<Button size="sm" variant="warning me-2" type="button" onClick={() => handleShowEditModal(row.id)} title="Chi tiết">
						<Icon icon="pencil" />
					</Button>

					<Button size="sm" variant="danger" type="button" onClick={() => handleDeleteProduct(row.id)} title="Xóa">
						<Icon icon="trash" />
					</Button>
				</>
			),
		},
	];

	const RenderProductExportTable = () => {
		return (
			<Table id="product-table" className="d-none">
				<thead>
					{language ? (
						<tr>
							<th>STT</th>
							<th>Tên sản phẩm Tiếng việt</th>
							<th>Tên sản phẩm Tiếng anh</th>
							<th>Giá(AUD)</th>
							<th>Giá(USD)</th>
							<th>Giá khuyến mãi(AUD)</th>
							<th>Giá khuyến mãi(USD)</th>
							<th>Thông tin sản phẩm Tiếng việt</th>
							<th>Thông tin sản phẩm Tiếng anh</th>
							<th>Hình ảnh</th>
						</tr>
					) : (
						<tr>
							<th>STT</th>
							<th>Product name Vietnamese</th>
							<th>Product name English</th>
							<th>Price(AUD)</th>
							<th>Price(USD)</th>
							<th>Sale price(AUD)</th>
							<th>Sale price(USD)</th>
							<th>Description Vietnamese</th>
							<th>Description English</th>
							<th>Image</th>
						</tr>
					)}

					<tr>
						<th>index</th>
						<th>nameVi</th>
						<th>nameEn</th>
						<th>priceVi</th>
						<th>priceEn</th>
						<th>salePriceVi</th>
						<th>salePriceEn</th>
						<th>contentVi</th>
						<th>contentEn</th>
						<th>images</th>
					</tr>
				</thead>

				<tbody>
					{products.map((productItem, index) => {
						return (
							<tr key={productItem.id}>
								<td>{index + 1}</td>
								<td>{productItem.nameVi}</td>
								<td>{productItem.nameEn}</td>
								<td>{productItem.priceVi}</td>
								<td>{productItem.priceEn}</td>
								<td>{productItem.salePriceVi || "empty"}</td>
								<td>{productItem.salePriceEn || "empty"}</td>
								<td>
									{productItem.contentVi
										? productItem.contentVi.length > 32767
											? productItem.contentVi.substring(0, 32767)
											: productItem.contentVi
										: "empty"}
								</td>
								<td>
									{productItem.contentEn
										? productItem.contentEn.length > 32767
											? productItem.contentEn.substring(0, 32767)
											: productItem.contentEn
										: "empty"}
								</td>
								<td>
									{productItem.images.map((image, index) => {
										if (index === productItem.images.length - 1) {
											return image.src;
										}

										return image.src + ", ";
									})}
								</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		);
	};

	return (
		<Container fluid>
			{language ? (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item active>Tổng quan</Breadcrumb.Item>
					<Breadcrumb.Item active>Sản phẩm</Breadcrumb.Item>
				</Breadcrumb>
			) : (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item active>Product</Breadcrumb.Item>
				</Breadcrumb>
			)}

			<div className="d-block d-md-flex justify-content-between align-items-center mb-3">
				<div>
					<Form
						className="d-flex"
						onSubmit={(e) => {
							e.preventDefault();
							getAllProducts(keyword);
						}}
					>
						<Form.Control
							type="search"
							placeholder={language ? "Nhập tên sản phẩm" : "Enter the product name"}
							className="me-2"
							aria-label="Search"
							value={keyword}
							onChange={(e) => setKeyword(e.target.value)}
						/>
						<Button type="submit" variant="outline-secondary">
							<Icon icon="magnifying-glass" />
						</Button>
					</Form>
				</div>
				<div className="mt-3 mt-md-0">
					<Button variant="primary" size="sm" className="mt-2 mt-md-0" onClick={() => handleShowAddModal()}>
						<Icon icon="plus" /> {language ? "Thêm mới" : "Add new"}
					</Button>

					<Button variant="success" size="sm" className="mt-2 mt-md-0 mx-2" onClick={() => handleShowImportModal()}>
						<Icon icon="file-import" /> {language ? "Nhập file" : "Import file"}
					</Button>

					<Button variant="success" size="sm" className="mt-2 mt-md-0" onClick={() => handleXportProductList()}>
						<Icon icon="file-export" /> {language ? "Xuất file" : "Export file"}
					</Button>
				</div>
			</div>

			{isLoading ? (
				<Loading />
			) : (
				<>
					<DataTables data={products} columns={columns} />
					{RenderProductExportTable()}
				</>
			)}

			{showAddModal ? <ProductAddModal handleCloseModal={handleCloseAddModal} showModal={showAddModal} /> : ""}
			{showEditModal ? <ProductEditModal handleCloseModal={handleCloseEditModal} showModal={showEditModal} selectedProduct={selectedProduct} /> : ""}
			{showImportModal ? <ProductImportModal handleCloseModal={handleCloseImportModal} showModal={showImportModal} /> : ""}
		</Container>
	);
};

export default Product;
