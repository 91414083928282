import { useSelector } from "react-redux";

const Loading = () => {
	const language = useSelector((state) => state.language.isVietnamese);

	return (
		<div className="d-flex justify-content-center my-5">
			<div
				className="spinner-border"
				style={{ width: "3rem", height: "3rem" }}
				role="status"
			>
				<span className="sr-only">
					{language ? "Đang tải" : "Loading..."}
				</span>
			</div>
		</div>
	);
};

export default Loading;
