const messages = (key, lang = 'vi') => {
    const mess = {
        notFound: [
            "Không tìm thấy tài nguyên",
            "No resources found"
        ],
        notEmpty: [
            "Không được để trống",
            "Cannot be empty",
        ],
        isInt: (min, max) => [
            `Phải là kiểu số nguyên${min && max ? ` từ ${min} đến ${max}` : min ? ` lớn hơn hoặc bằng ${min}` : max ? ` nhỏ hơn hoặc bằng ${max}` : ""}`,
            `Must be an integer${min && max ? ` from ${min} to ${max}` : min ? ` greater than or equal to ${min}` : max ? ` less than or equal to ${max}` : ""}`
        ],
        isLength: (min, max) => [
            `${min && max ? `Từ ${min} - ${max} kí tự` : min ? `Lớn hơn ${min} kí tự` : max ? `Nhỏ hơn ${max} kí tự` : ""}`,
            `${min && max ? `From ${min} - ${max} characters` : min ? `Greater than ${min} characters` : max ? `Less than ${max} characters` : ""}`,
        ],
        isIn: (values) => [
            `Chỉ cho phép các giá trị ${values.join(", ")}`,
            `Only ${values.join(", ")} values are allowed`,
        ],
        isString: [
            "Phải là kiểu kí tự",
            "Must be a character type"
        ],
        isNumeric: [
            "Phải là kiểu số",
            "Must be numeric"
        ],
        isArray: [
            "Phải là kiểu mảng",
            "Must be an array type"
        ],
        isInvalidLink: (invalidLinks) => [
            `Đường dẫn không hợp lệ: ${invalidLinks.join(", ")}`,
            `Invalid link: ${invalidLinks.join(", ")}`
        ],
        isExisted: [
            "Đã tồn tại",
            "Already exist"
        ],
        isEmail: [
            "Sai định dạng email",
            "Wrong email format"
        ],
        isInvalidPhoneNumber: [
            "Sai định dạng số điện thoại",
            "Wrong phone number format"
        ],
        isDate: (format) => [
            `Định dạng: ${format}`,
            `Format: ${format}`
        ],
        confirmPassword: [
            "Xác nhận lại mật khẩu",
            "Confirm password"
        ],
        passwordMismatch: [
            "Không trùng khớp với mật khẩu",
            "Does not match password"
        ],
        unauthorized: [
            "Không được phép",
            "Unauthorized"
        ],
        unauthenticated: [
            "Chưa được xác thực",
            "Unverified"
        ],
        invalidToken: [
            "Token không hợp lệ",
            "Invalid Token"
        ],
        invalid: [
            "Không hợp lệ",
            "Illegal"
        ],
        duplicate: [
            "Trùng lặp giá trị",
            "Duplicate values"
        ],
        youWantToDelete: [
            'Bạn muốn xóa?',
            'You want to delete?'
        ],
        close: [
            'Đóng',
            'Close'
        ],
        delete: [
            'Xóa',
            'Delete'
        ],
        addNewSize: [
            'Thêm mới kích thước',
            'Add new size'
        ],
        deletedFailure: [
            'Xóa thất bại',
            'Deleted failure'
        ],
    };

    const iLang = lang === 'vi' ? 0 : 1;

    const result = mess[key] === undefined ? false : mess[key][iLang];

    return result;
}

module.exports = {
    messages
}