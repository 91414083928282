import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import MoneyFormat from "../../components/MoneyFormat";
import Icon from "../../components/Icon";
import Loading from "../../components/Loading";
import moment from "moment";
import { EN_ERROR_SEND_FORM, VI_ERROR_SEND_FORM } from "../../utils/message";
import swal from "sweetalert";

const orderStatuses = [
	{ id: 0, nameVi: "Tất cả", nameEn: "All" },
	{ id: 1, nameVi: "Chưa xác nhận", nameEn: "Not confirmed yet" },
	{ id: 2, nameVi: "Đã xác nhận", nameEn: "Confirmed" },
	{ id: 3, nameVi: "Đang vận chuyển", nameEn: "Is shipping" },
	{ id: 4, nameVi: "Hoàn thành", nameEn: "Completed" },
	{ id: 5, nameVi: "Đã hủy", nameEn: "Canceled" },
];

const OrderHistory = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const [orders, setOrders] = useState([]);
	const [statusSelected, setStatusSelected] = useState(0);

	const [isLoading, setIsLoading] = useState(false);
	const [paymentMethods, setPaymentMethods] = useState([]);

	useEffect(() => {
		if (localStorage.getItem("access_token")) {
			getOrderHistory();
		} else {
			getOrderByIds();
		}

		getAllPaymentMethods();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (localStorage.getItem("access_token")) {
			getOrderHistory();
		} else {
			getOrderByIds();
		}
	}, [statusSelected]); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getOrderHistory = () => {
		setIsLoading(true);
		axios
			.get(`/order/history`, {
				params: {
					status: statusSelected,
				},
			})
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					setOrders(res.data.orders);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getOrderByIds = () => {
		setIsLoading(true);
		axios
			.get(`/order/ids`, {
				params: {
					status: statusSelected,
					orderIds: JSON.parse(localStorage.getItem("anonymousUserOrder")),
				},
			})
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					setOrders(res.data.orders);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllPaymentMethods = () => {
		axios
			.get(`/payment/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setPaymentMethods(res.data.payments);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleCancelOrder = (data) => {
		swal({
			title: language ? `Bạn muốn hủy đơn hàng này` : "You want to cancel this order",
			icon: "warning",
			buttons: [language ? "Đóng" : "Close", language ? "Xóa" : "Delete"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				cancelOrder(data);
			}
		});
	};

	const cancelOrder = async (data) => {
		await axios
			.put(`/order/cancel/${data.id}`)
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});

					getOrderHistory();
				} else {
					toast(language ? VI_ERROR_SEND_FORM : EN_ERROR_SEND_FORM, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	const OrderItem = ({ orderItem }) => {
		let orderTotalVi = 0;
		let orderTotalEn = 0;

		orderItem.orderDetails.map((orderDetailItem) => {
			orderTotalVi += Number(orderDetailItem.quantity * orderDetailItem.priceVi);
			orderTotalEn += Number(orderDetailItem.quantity * orderDetailItem.priceEn);

			return orderDetailItem;
		});

		return (
			<Card className="mb-3">
				<Card.Header style={{ backgroundColor: "black", color: "white" }} className="text-end">
					{language
						? orderStatuses.find((status) => status.id === orderItem.orderStatusId)?.nameVi
						: orderStatuses.find((status) => status.id === orderItem.orderStatusId)?.nameEn}
				</Card.Header>

				<Card.Body>
					{orderItem.orderDetails.map((orderDetailItem) => {
						return (
							<Row key={orderDetailItem.id} className="border mb-2 mx-0 py-2">
								<Col xs="3" md="2" className="d-flex justify-content-center">
									<div
										style={{
											width: "5rem",
											height: "5rem",
											backgroundImage: `url("${orderDetailItem.product.images[0].src}")`,
										}}
										className="image-default"
									/>
								</Col>
								<Col xs="9" md="10" className="d-flex justify-content-between">
									<div className="w-75">
										<Card.Title className="text-overflow-2-line">
											{language ? orderDetailItem.product.nameVi : orderDetailItem.product.nameEn}
										</Card.Title>
										<div>
											<div>
												{language ? "Phân loại hàng:" : "Variation:"}{" "}
												{language
													? `${orderDetailItem.product.category.nameVi} - ${orderDetailItem.color?.nameVi} - `
													: `${orderDetailItem.product.category.nameEn} - ${orderDetailItem.color?.nameEn} - `}
												{orderDetailItem.size?.name}
											</div>
											<div>x {orderDetailItem.quantity}</div>
										</div>
									</div>

									<div className="w-25 text-end">
										<MoneyFormat money={language ? orderDetailItem.priceVi : orderDetailItem.priceEn} className={`fw-bold`} />
									</div>
								</Col>
							</Row>
						);
					})}
				</Card.Body>

				<Card.Footer className="py-3">
					<Row>
						<Col xs="12" md="6">
							<div className="d-flex justify-content-start">
								<div className="order-his-info">{language ? "Ngày đặt hàng:" : "Order date:"}</div>
								<div>
									{language
										? moment(orderItem.createdAt).format("DD-MM-YYYY, hh:mm")
										: moment(orderItem.createdAt).format("YYYY-MM-DD, hh:mm a")}
								</div>
							</div>
							<div className="d-flex justify-content-start">
								<div className="order-his-info">{language ? "Thanh toán:" : "Payment:"}</div>
								<div>
									{language
										? paymentMethods.find((payement) => payement.id === orderItem.paymentMethodId)?.nameVi
										: paymentMethods.find((payement) => payement.id === orderItem.paymentMethodId)?.nameEn}
								</div>
							</div>
							<div className="d-flex justify-content-start">
								<div className="order-his-info">{language ? "Số điện thoại:" : "Telephone:"}</div>
								<div>{orderItem.phonenumber}</div>
							</div>
							<div className="d-flex justify-content-start">
								<div className="order-his-info">{language ? "Địa chỉ:" : "Address:"}</div>
								<div>{orderItem.address}</div>
							</div>
						</Col>
						<Col xs="12" md="6">
							<div
								size="md"
								style={{
									borderRadius: 0,
								}}
								className="w-100 fw-bold d-flex justify-content-end align-items-center py-2"
							>
								<div className="me-3">
									<Icon icon="shield-halved" className="me-2" />
									{language ? "Tổng tiền hàng:" : "Order total: "}
								</div>

								<MoneyFormat money={language ? orderTotalVi : orderTotalEn} style={{ fontSize: "1.3rem" }} />
							</div>

							<div className="d-flex justify-content-end mt-3">
								{orderItem.orderStatusId < 3 ? (
									<Button size="md" className="mlb-button px-3 me-3" onClick={() => handleCancelOrder(orderItem)}>
										<Icon icon="xmark" className="me-2" />
										{language ? "Hủy" : "Cancel"}
									</Button>
								) : (
									""
								)}
							</div>
						</Col>
					</Row>
				</Card.Footer>
			</Card>
		);
	};

	return (
		<Container>
			<div className="title text-center my-3">{language ? "Lịch sử mua hàng" : "Order history"}</div>

			<div
				style={{
					backgroundColor: "black",
					overflowX: "scroll",
				}}
				className="d-flex justify-content-start justify-content-md-around fw-bold py-3 mb-3"
			>
				{orderStatuses.map((status) => {
					return (
						<div
							key={status.id}
							style={{
								cursor: "pointer",
								minWidth: "10rem",
								textAlign: "center",
							}}
							onClick={() => setStatusSelected(status.id)}
							className={statusSelected === status.id ? "cart-progress-item-active" : "cart-progress-item"}
						>
							{language ? status.nameVi : status.nameEn}
						</div>
					);
				})}
			</div>

			{isLoading ? (
				<Loading />
			) : orders.length !== 0 ? (
				orders.map((orderItem) => {
					return <OrderItem key={orderItem.id} orderItem={orderItem} />;
				})
			) : (
				<div className="text-center my-3">{language ? "Bạn chưa có đơn mua nào" : "You have no purchase"}</div>
			)}
		</Container>
	);
};

export default OrderHistory;
