import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Icon from "../../components/Icon";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";

const dashboardItems = [
	{
		id: 1,
		nameVi: "Sản phẩm",
		nameEn: "Product",
		icon: "shirt",
		name: "totalProduct",
	},
	{
		id: 2,
		nameVi: "Khách hàng",
		nameEn: "Customer",
		icon: "users",
		name: "totalCustomer",
	},
	{
		id: 3,
		nameVi: "Đơn hàng",
		nameEn: "Order",
		icon: "dolly",
		name: "totalOrder",
	},
	// { id: 4, nameVi: "Sản phẩm", nameEn: "Product" },
];

const Dashboard = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const [isLoading, setIsLoading] = useState(false);
	const [dashboard, setDashboard] = useState({
		totalProduct: 0,
		totalCustomer: 0,
		totalOrder: 0,
	});

	useEffect(() => {
		getDashboard();
	}, []);

	const getDashboard = async () => {
		setIsLoading(true);
		await axios
			.get(`/dashboard`)
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setDashboard({
						totalProduct: res.data.totalProduct,
						totalCustomer: res.data.totalCustomer,
						totalOrder: res.data.totalOrder,
					});
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Container className="pt-5 px-2 px-md-5">
			{isLoading ? (
				<Loading />
			) : (
				<Row>
					{dashboardItems.map((item) => {
						return (
							<Col
								key={item.id}
								xs="12"
								sm="12"
								md="6"
								xl="4"
								className="mb-4"
							>
								<div
									style={{
										backgroundColor: "black",
										color: "white",
									}}
									className="w-100 d-flex align-items-center p-3"
								>
									<div
										style={{ fontSize: "3rem" }}
										className="m-3"
									>
										<Icon icon={item.icon} />
									</div>
									<div>
										<div style={{ fontSize: "1.5rem" }}>
											{language
												? item.nameVi
												: item.nameEn}
										</div>
										<div
											style={{
												fontWeight: "600",
												fontSize: "2rem",
											}}
										>
											{dashboard[item.name]}
										</div>
									</div>
								</div>
							</Col>
						);
					})}
				</Row>
			)}
		</Container>
	);
};

export default Dashboard;
