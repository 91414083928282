import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import Input from "../../../components/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../utils/yupGlobal";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
	EN_EMAIL_FORMAT,
	EN_FIELD_REQUIRE,
	EN_PASSWORD_LENGTH,
	EN_PASSWORD_MATCH,
	EN_PHONE_FORMAT,
	EN_USER_LENGTH,
	VI_EMAIL_FORMAT,
	VI_FIELD_REQUIRE,
	VI_PASSWORD_LENGTH,
	VI_PASSWORD_MATCH,
	VI_PHONE_FORMAT,
	VI_USER_LENGTH,
} from "../../../utils/message";

const registerFormItems = [
	{
		id: 1,
		placeholderVi: "Tên đăng nhập",
		placeholderEn: "Username",
		name: "username",
	},
	{
		id: 2,
		placeholderVi: "Mật khẩu",
		placeholderEn: "Password",
		name: "password",
	},
	{
		id: 3,
		placeholderVi: "Nhập lại mật khẩu",
		placeholderEn: "Confirm Password",
		name: "confirmPassword",
	},
	{
		id: 4,
		placeholderVi: "Họ và tên",
		placeholderEn: "Fullname",
		name: "fullname",
	},
	{
		id: 5,
		placeholderVi: "Email",
		placeholderEn: "Email",
		name: "email",
	},
	{
		id: 6,
		placeholderVi: "Số điện thoại",
		placeholderEn: "Phone number",
		name: "phonenumber",
	},
	{
		id: 7,
		placeholderVi: "Địa chỉ",
		placeholderEn: "Address",
		name: "address",
	},
];

const Register = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const navigate = useNavigate();
	const [errMessage, setErrMessage] = useState({
		username: undefined,
		password: undefined,
		email: undefined,
		phonenumber: undefined,
		address: undefined,
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(language ? SchemaVi : SchemaEn),
	});

	useEffect(() => {
		document.title = language ? "Đăng ký" : "Register";

		if (
			localStorage.getItem("access_token") ||
			Number(localStorage.getItem("role")) === 1 ||
			Number(localStorage.getItem("role")) === 2
		) {
			navigate("/admin");
		} else if (
			localStorage.getItem("access_token") ||
			Number(localStorage.getItem("role")) === 3
		) {
			navigate("/");
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const registerUser = (data) => {
		axios
			.post(`/user/create`, {
				username: data.username,
				password: data.password,
				fullname: data.fullname,
				email: data.email,
				phonenumber: data.phonenumber,
				address: data.address,
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});

					navigate("/login");
				} else {
					setErrMessage(res.data.errMessage);
				}
			});
	};

	return (
		<Container>
			<div className="title text-center my-3">
				{language ? "Đăng ký" : "Register"}
			</div>

			<Row className="justify-content-center">
				<Col xs="12" sm="8" md="6">
					<Card className="p-0 p-xl-5 mb-5 border-0">
						<Form
							onSubmit={handleSubmit((data) => {
								registerUser(data);
							})}
						>
							{registerFormItems.map((item) => {
								return (
									<React.Fragment key={item.id}>
										<Input
											type={
												item.id === 2 || item.id === 3
													? "password"
													: "text"
											}
											placeholder={
												language
													? item.placeholderVi
													: item.placeholderEn
											}
											name={item.name}
											register={register(item.name)}
											errors={errors[item.name]}
											className="mb-3"
										/>

										{errMessage[item.name] && (
											<p
												style={{
													fontSize: 13,
													color: "red",
													marginTop: 6,
												}}
											>
												{errMessage[item.name]}
											</p>
										)}
									</React.Fragment>
								);
							})}

							<Button
								type="submit"
								size="lg"
								className="mlb-button w-100"
							>
								{language ? "Đăng ký" : "Register"}
							</Button>
						</Form>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

const SchemaVi = yup.object().shape({
	password: yup
		.string()
		.required(VI_FIELD_REQUIRE)
		.test(
			"len",
			VI_PASSWORD_LENGTH,
			(val) => val.length >= 6 && val.length <= 32,
		),
	username: yup
		.string()
		.required(VI_FIELD_REQUIRE)
		.test(
			"len",
			VI_USER_LENGTH,
			(val) => val.length >= 6 && val.length <= 32,
		),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), null], VI_PASSWORD_MATCH),
	fullname: yup.string().required(VI_FIELD_REQUIRE),
	phonenumber: yup.string().required(VI_FIELD_REQUIRE).phone(VI_PHONE_FORMAT),
	email: yup.string().email(VI_EMAIL_FORMAT).required(VI_FIELD_REQUIRE),
	address: yup.string().required(VI_FIELD_REQUIRE),
});

const SchemaEn = yup.object().shape({
	password: yup
		.string()
		.required(EN_FIELD_REQUIRE)
		.test(
			"len",
			EN_PASSWORD_LENGTH,
			(val) => val.length >= 6 && val.length <= 32,
		),
	username: yup
		.string()
		.required(EN_FIELD_REQUIRE)
		.test(
			"len",
			EN_USER_LENGTH,
			(val) => val.length >= 6 && val.length <= 32,
		),

	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), null], EN_PASSWORD_MATCH),
	fullname: yup.string().required(EN_FIELD_REQUIRE),
	phonenumber: yup.string().required(EN_FIELD_REQUIRE).phone(EN_PHONE_FORMAT),
	email: yup.string().email(EN_EMAIL_FORMAT).required(EN_FIELD_REQUIRE),
	address: yup.string().required(EN_FIELD_REQUIRE),
});

export default Register;
