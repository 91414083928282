import { useSelector } from "react-redux";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../utils/yupGlobal";
import Input from "../../components/Input";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
	EN_FIELD_REQUIRE,
	EN_PHONE_FORMAT,
	VI_FIELD_REQUIRE,
	VI_PHONE_FORMAT,
} from "../../utils/message";

const settingFormItems = [
	{
		id: 1,
		placeholderVi: "Tên cửa hàng",
		placeholderEn: "Shop name",
		name: "name",
	},
	{
		id: 2,
		placeholderVi: "Email",
		placeholderEn: "Email",
		name: "email",
	},
	{
		id: 3,
		placeholderVi: "Số điện thoại",
		placeholderEn: "Phone number",
		name: "phonenumber",
	},
	{
		id: 4,
		placeholderVi: "Địa chỉ",
		placeholderEn: "Address",
		name: "address",
	},
	{
		id: 5,
		placeholderVi: "Giờ mở cửa Tiếng việt",
		placeholderEn: "Vietnamese Openning Hour",
		name: "openningHourVi",
	},
	{
		id: 6,
		placeholderVi: "Giờ mở cửa Tiếng anh",
		placeholderEn: "English Openning Hour",
		name: "openningHourEn",
	},
	{
		id: 7,
		placeholderVi: "Slogan Tiếng việt",
		placeholderEn: "Vietnamese Slogan",
		name: "sloganVi",
	},
	{
		id: 8,
		placeholderVi: "Slogan Tiếng anh",
		placeholderEn: "English Slogan",
		name: "sloganEn",
	},
	{
		id: 9,
		placeholderVi: "Facebook",
		placeholderEn: "Facebook",
		name: "facebookLink",
	},
	{
		id: 10,
		placeholderVi: "Zalo",
		placeholderEn: "Zalo",
		name: "zaloNumber",
	},
	{
		id: 11,
		placeholderVi: "Script in Header",
		placeholderEn: "Script in Header",
		name: "headerScript",
	},
	{
		id: 12,
		placeholderVi: "Script in Footer",
		placeholderEn: "Script in Footer",
		name: "footerScript",
	},
];

const Setting = () => {
	const language = useSelector((state) => state.language.isVietnamese);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(language ? SchemaVi : SchemaEn),
	});

	useEffect(() => {
		getSetting();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getSetting = async () => {
		await axios
			.get(`/shop`)
			.then((res) => {
				if (res.data.errCode === 0) {
					reset({
						name: res.data.shop.name,
						email: res.data.shop.email,
						phonenumber: res.data.shop.phonenumber,
						address: res.data.shop.address,
						openningHourVi: res.data.shop.openningHourVi,
						openningHourEn: res.data.shop.openningHourEn,
						sloganVi: res.data.shop.sloganVi,
						sloganEn: res.data.shop.sloganEn,
						facebookLink: res.data.shop.facebookLink,
						zaloNumber: res.data.shop.zaloNumber,
						headerScript: res.data.shop.headerScript,
						footerScript: res.data.shop.footerScript,
					});
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateSetting = async (data) => {
		await axios
			.put(`/shop/update`, {
				name: data.name,
				email: data.email,
				phonenumber: data.phonenumber,
				address: data.address,
				openningHourVi: data.openningHourVi,
				openningHourEn: data.openningHourEn,
				sloganVi: data.sloganVi,
				sloganEn: data.sloganEn,
				facebookLink: data.facebookLink,
				zaloNumber: data.zaloNumber,
				headerScript: data.headerScript,
				footerScript: data.footerScript,
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<Container fluid className="mt-3">
			<Card>
				<Card.Header>
					<Card.Title>{language ? "Cài đặt" : "Setting"}</Card.Title>
				</Card.Header>

				<Card.Body>
					<Form
						onSubmit={handleSubmit((data) => {
							updateSetting(data);
						})}
					>
						{settingFormItems.map((formItem) => {
							return (
								<Form.Group key={formItem.id} as={Row}>
									<Form.Label
										column
										sm="3"
										className="fw-bold"
									>
										{language
											? formItem.placeholderVi
											: formItem.placeholderEn}
									</Form.Label>
									<Col sm="6">
										<Input
											placeholder={
												language
													? formItem.placeholderVi
													: formItem.placeholderEn
											}
											name={formItem.name}
											register={register(formItem.name)}
											errors={errors[formItem.name]}
											className="mb-3"
											as={
												formItem.id === 11 ||
												formItem.id === 12
													? "textarea"
													: "input"
											}
											rows={3}
										/>
									</Col>
								</Form.Group>
							);
						})}

						<Button
							type="submit"
							size="lg"
							className="mlb-button my-3 w-100"
						>
							{language ? "Cập nhật" : "Update"}
						</Button>
					</Form>
				</Card.Body>
			</Card>
		</Container>
	);
};

const SchemaVi = yup.object().shape({
	name: yup.string().required(VI_FIELD_REQUIRE),
	email: yup.string().required(VI_FIELD_REQUIRE),
	phonenumber: yup.string().required(VI_FIELD_REQUIRE).phone(VI_PHONE_FORMAT),
	zaloNumber: yup.string().phone(VI_PHONE_FORMAT),
});

const SchemaEn = yup.object().shape({
	name: yup.string().required(EN_FIELD_REQUIRE),
	email: yup.string().required(EN_FIELD_REQUIRE),
	phonenumber: yup.string().required(EN_FIELD_REQUIRE).phone(EN_PHONE_FORMAT),
	zaloNumber: yup.string().phone(EN_PHONE_FORMAT),
});

export default Setting;
