import React from "react";
import "./App.css";
import Router from "./routes/router";
import axios from "axios";
import { useEffect } from "react";

axios.defaults.baseURL = `${
	process.env.REACT_APP_BACKEND_URL || "http://localhost:8083/api/"
}/`;

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "*/*";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers["Authorization"] = localStorage.getItem("access_token")
	? "Bearer " + localStorage.getItem("access_token")
	: "";

axios.defaults.headers["Language"] =
	localStorage.getItem("language") === "true" ? "vi" : "en";

const isTokenExpired = (token) =>
	Date.now() >= JSON.parse(atob(token.split(".")[1])).exp * 1000;

export default function App() {
	useEffect(() => {
		if (localStorage.getItem("access_token")) {
			if (isTokenExpired(localStorage.getItem("access_token"))) {
				localStorage.removeItem("fullname");
				localStorage.removeItem("role");
				localStorage.removeItem("access_token");
				localStorage.removeItem("username");

				window.location.href = "/";
			}
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return <Router />;
}
