export const VI_LOGOUT_SUCCESS = "Đăng xuất thành công";
export const EN_LOGOUT_SUCCESS = "Logout successfull";

export const VI_FILE_REQUIRE = "Vui lòng chọn file ảnh";
export const EN_FILE_REQUIRE = "Please select an image file";

export const VI_FILE_REQUIRE_2_10 = "Vui lòng chọn 1 đến 10 file ảnh";
export const EN_FILE_REQUIRE_2_10 = "Please select 1 to 10 image files";

export const VI_FILE_SIZE = "File ảnh phải nhỏ hơn bằng 2MB";
export const EN_FILE_SIZE = "Image file must be less than or equal 2MB";

export const VI_CART_CREATE = "Đã thêm sản phẩm vào giỏ hàng";
export const EN_CART_CREATE = "The product has been added to cart";

export const VI_CART_REMOVE = "Đã xóa giỏ hàng";
export const EN_CART_REMOVE = "The shopping cart has been removed";

export const VI_FIELD_REQUIRE = "Không được bỏ trống";
export const EN_FIELD_REQUIRE = "Must be not empty";

export const VI_PHONE_FORMAT = "Số điện thoại không đúng định dạng";
export const EN_PHONE_FORMAT = "Phone format is wrong";

export const VI_EMAIL_FORMAT = "Email không đúng định dạng";
export const EN_EMAIL_FORMAT = "Email format is wrong";

export const VI_ERROR_SEND_FORM = "Có lỗi xảy ra, vui lòng kiểm tra lại thông tin";
export const EN_ERROR_SEND_FORM = "An error occurred, please check the information again";

export const VI_USER_LENGTH = "Tên đăng nhập nên dài từ 6-32 kí tự";
export const EN_USER_LENGTH = "Username length must be of 6-32 characters";

export const VI_PASSWORD_MATCH = "Mật khẩu không trùng khớp";
export const EN_PASSWORD_MATCH = "Password does not match";

export const VI_PASSWORD_LENGTH = "Mật khẩu nên dài từ 6-32 kí tự";
export const EN_PASSWORD_LENGTH = "Password length must be of 6-32 characters";

export const VI_PRODUCT_REQUIRE = "Vui lòng chọn ít nhất 2 sản phẩm";
export const EN_PRODUCT_REQUIRE = "Please select at least 2 products";
