import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";

import yup from "../../../utils/yupGlobal";
import { EN_FIELD_REQUIRE, VI_FIELD_REQUIRE } from "../../../utils/message";
import { messages } from "../../../utils/Messages";

const addColorFormItems = [
	{
		id: 1,
		placeholderVi: "Tên kích thước Tiếng việt",
		placeholderEn: "Vietnamese size name",
		name: "nameVi"
	},
	{
		id: 2,
		placeholderVi: "Tên kích thước Tiếng anh",
		placeholderEn: "English size name",
		name: "nameEn"
	}
];

const ColorAddModal = ({ handleCloseModal, showModal }) => {
	const language = useSelector((state) => state.language.isVietnamese);
	const lang = useSelector((state) => state.language.isVietnamese) ? 'vi' : 'en';
	const [errMessage, setErrMessage] = useState({
		nameVi: undefined,
		nameEn: undefined,
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(language ? SchemaVi : SchemaEn),
	});

	const createNewColor = async (data) => {
		await axios.post(`/size/create`, {
			nameVi: data.nameVi,
			nameEn: data.nameEn,
		}).then((res) => {
			if (res.status === 200) {
				toast(res.data.messages, { type: "success", autoClose: 1000, });
				handleCloseModal(true);
			}
		}).catch((e) => {
			if (e.response?.status === 400) {
				setErrMessage(e.response?.data.messages);
			}
		});
	};

	return (
		<Modal show={showModal} onHide={() => handleCloseModal(false)} backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>{messages('addNewSize', lang)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					id="add-color-hook-form"
					onSubmit={handleSubmit((data) => {
						createNewColor(data);
					})}
				>
					{addColorFormItems.map((formItem) => {
						return (
							<div key={formItem.id} className="mb-3">
								<Form.Floating>
									<Form.Control
										type="text"
										placeholder={language ? formItem.placeholderVi : formItem.placeholderEn}
										name={formItem.name}
										{...register(formItem.name)}
									/>

									<Form.Label>
										{language ? formItem.placeholderVi : formItem.placeholderEn}
										<i className="text-danger">*</i>
									</Form.Label>
								</Form.Floating>

								{errors[formItem.name] && (
									<p
										style={{
											fontSize: 13,
											color: "red",
											marginTop: 6,
										}}
									>
										{errors[formItem.name].message}
									</p>
								)}

								{errMessage[formItem.name] && (
									<p
										style={{
											fontSize: 13,
											color: "red",
											marginTop: 6,
										}}
									>
										{errMessage[formItem.name]}
									</p>
								)}
							</div>
						);
					})}
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button type="button" variant="secondary" onClick={() => handleCloseModal(false)}>
					{language ? "Đóng" : "Close"}
				</Button>
				<Button type="submit" form="add-color-hook-form" variant="success">
					{language ? "Lưu" : "Save"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const SchemaVi = yup.object().shape({
	nameVi: yup.string().required(VI_FIELD_REQUIRE),
	nameEn: yup.string().required(VI_FIELD_REQUIRE),
});

const SchemaEn = yup.object().shape({
	nameVi: yup.string().required(EN_FIELD_REQUIRE),
	nameEn: yup.string().required(EN_FIELD_REQUIRE),
});

export default ColorAddModal;
