import { createSlice } from "@reduxjs/toolkit";

export const userCartSlice = createSlice({
	name: "userCart",
	initialState: {
		userCart: JSON.parse(localStorage.getItem(`${localStorage.getItem(`username`)}Cart`)) || [],
	},
	reducers: {
		addToUserCart: (state, action) => {
			if (
				state.userCart.some(
					(item) => item.id === action.payload.id && item.color.id === action.payload.color.id && item.size.id === action.payload.size.id,
				)
			) {
				const newCart = state.userCart.map((item) => {
					if (item.id === action.payload.id && item.color.id === action.payload.color.id && item.size.id === action.payload.size.id) {
						return {
							...item,
							quantity: item.quantity + action.payload.quantity,
						};
					}
					return item;
				});

				state.userCart = newCart;
				localStorage.setItem(`${localStorage.getItem(`username`)}Cart`, JSON.stringify(newCart));
			} else {
				const newCart = [...state.userCart, action.payload];

				state.userCart = newCart;
				localStorage.setItem(`${localStorage.getItem(`username`)}Cart`, JSON.stringify(newCart));
			}
		},
		removeFromUserCart: (state, action) => {
			const newCart = state.userCart.filter((item) => {
				if (item.id === action.payload.id && item.color.id === action.payload.color.id && item.size.id === action.payload.size.id) {
					return false;
				}
				return true;
			});

			state.userCart = newCart.filter((item) => item.id !== 0);
			localStorage.setItem(`${localStorage.getItem(`username`)}Cart`, JSON.stringify(newCart));
		},
		updateUserCart: (state, action) => {
			const newCart = state.userCart.map((item) => {
				if (item.id === action.payload.id && item.color.id === action.payload.color.id && item.size.id === action.payload.size.id) {
					return {
						...item,
						quantity: item.quantity + action.payload.amount !== 0 ? item.quantity + action.payload.amount : item.quantity,
					};
				}
				return item;
			});

			state.userCart = newCart;
			localStorage.setItem(`${localStorage.getItem(`username`)}Cart`, JSON.stringify(newCart));
		},
		removeUserCart: (state) => {
			state.userCart = [];
			localStorage.setItem(`${localStorage.getItem(`username`)}Cart`, JSON.stringify([]));
		},
	},
});

export const { addToUserCart, removeFromUserCart, updateUserCart, removeUserCart } = userCartSlice.actions;

export default userCartSlice.reducer;
