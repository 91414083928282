import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { english, vietnamese } from "../store/slices/languageSlice";
import IconVi from "../assets/icon/ic_vi_VN.png";
import IconEn from "../assets/icon/ic_en_US.png";

const languageItems = [
	{
		id: 1,
		labelVi: "Tiếng Việt",
		labelEn: "Vietnamese",
		icon: IconVi,
		dispatch: vietnamese(),
	},
	{
		id: 2,
		labelVi: "English",
		labelEn: "Tiếng Anh",
		icon: IconEn,
		dispatch: english(),
	},
];

const LanguageToggle = () => {
	const dispatch = useDispatch();
	const language = useSelector((state) => state.language.isVietnamese);

	return (
		<Dropdown className="me-1">
			<Dropdown.Toggle
				style={{
					background: "none",
					border: "none",
					color: "black",
				}}
			>
				<img
					alt="Language"
					src={language ? IconVi : IconEn}
					style={{ height: 20, width: 32 }}
				/>
			</Dropdown.Toggle>

			<Dropdown.Menu>
				{languageItems.map((item) => {
					return (
						<Dropdown.Item
							key={item.id}
							onClick={() => {
								window.location.reload();
								dispatch(item.dispatch);
							}}
						>
							<img
								alt={language ? item.labelVi : item.labelEn}
								src={item.icon}
								style={{
									height: 20,
									width: 32,
								}}
								className="me-2"
							/>
							{language ? item.labelVi : item.labelEn}
						</Dropdown.Item>
					);
				})}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default LanguageToggle;
