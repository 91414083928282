import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";

import yup from "../../../utils/yupGlobal";
import { EN_FIELD_REQUIRE, VI_FIELD_REQUIRE } from "../../../utils/message";

const addColorFormItems = [
	{
		id: 1,
		placeholderVi: "Tên màu Tiếng việt",
		placeholderEn: "Vietnamese Color name",
		name: "nameVi",
	},
	{
		id: 2,
		placeholderVi: "Tên màu Tiếng anh",
		placeholderEn: "English Color name",
		name: "nameEn",
	},
];

const ColorEditModal = ({ handleCloseModal, showModal, selectedColor }) => {
	const language = useSelector((state) => state.language.isVietnamese);

	const [colorId, setColorId] = useState(0);

	const [errMessage, setErrMessage] = useState({
		nameVi: undefined,
		nameEn: undefined,
	});

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(language ? SchemaVi : SchemaEn),
	});

	useEffect(() => {
		getDetailColor(selectedColor);
	}, [selectedColor]); // eslint-disable-line react-hooks/exhaustive-deps

	const getDetailColor = (colorId) => {
		axios
			.get(`/color/${colorId}`)
			.then((res) => {
				if (res.data.errCode === 0) {
					reset({
						nameVi: res.data.color.nameVi,
						nameEn: res.data.color.nameEn,
					});
					setColorId(res.data.color.id);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateColor = async (data) => {
		await axios
			.put(`/color/update/${colorId}`, {
				nameVi: data.nameVi,
				nameEn: data.nameEn,
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});
					handleCloseModal(true);
				} else {
					setErrMessage(res.data.errMessage);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Modal show={showModal} onHide={() => handleCloseModal(false)} backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>{language ? "Cập nhật màu " : "Update color"}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form
					id="edit-color-hook-form"
					onSubmit={handleSubmit((data) => {
						updateColor(data);
					})}
				>
					{addColorFormItems.map((formItem) => {
						return (
							<div key={formItem.id} className="mb-3">
								<Form.Floating>
									<Form.Control
										type="text"
										placeholder={language ? formItem.placeholderVi : formItem.placeholderEn}
										name={formItem.name}
										{...register(formItem.name)}
									/>

									<Form.Label>
										{language ? formItem.placeholderVi : formItem.placeholderEn}
										<i className="text-danger">*</i>
									</Form.Label>
								</Form.Floating>

								{errors[formItem.name] && (
									<p
										style={{
											fontSize: 13,
											color: "red",
											marginTop: 6,
										}}
									>
										{errors[formItem.name].message}
									</p>
								)}

								{errMessage[formItem.name] && (
									<p
										style={{
											fontSize: 13,
											color: "red",
											marginTop: 6,
										}}
									>
										{errMessage[formItem.name]}
									</p>
								)}
							</div>
						);
					})}
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button type="button" variant="secondary" onClick={() => handleCloseModal(false)}>
					{language ? "Đóng" : "Close"}
				</Button>
				<Button type="submit" form="edit-color-hook-form" variant="dark">
					{language ? "Cập nhật" : "Update"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const SchemaVi = yup.object().shape({
	nameVi: yup.string().required(VI_FIELD_REQUIRE),
	nameEn: yup.string().required(VI_FIELD_REQUIRE),
});

const SchemaEn = yup.object().shape({
	nameVi: yup.string().required(EN_FIELD_REQUIRE),
	nameEn: yup.string().required(EN_FIELD_REQUIRE),
});

export default ColorEditModal;
