import { Breadcrumb, Button, Container, Form } from "react-bootstrap";
import DataTables from "../../../components/DataTables";

import Icon from "../../../components/Icon";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";

import swal from "sweetalert";

import CollectionAddModal from "./CollectionAddModal";
import CollectionEditModal from "./CollectionEditModal";
import Loading from "../../../components/Loading";

const Collection = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const [collections, setCollections] = useState([]);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [selectedCollection, setSelectedCollection] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [keyword, setKeyword] = useState("");

	useEffect(() => {
		getAllCollections();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Api
	const getAllCollections = async (keyword) => {
		setIsLoading(true);
		setKeyword(keyword);
		await axios
			.get(`/combo/list`, {
				params: {
					keyword: keyword,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setCollections(res.data.combos);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteCollection = async (collectionId) => {
		await axios
			.delete(`/combo/delete/${collectionId}`)
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});
					getAllCollections("");
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleDeleteCollection = (collectionId) => {
		swal({
			title: language
				? `Bạn muốn xóa bộ sưu tập này`
				: "You want to delete this collection",
			icon: "warning",
			buttons: [language ? "Đóng" : "Close", language ? "Xóa" : "Delete"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				deleteCollection(collectionId);
			}
		});
	};

	const handleCloseAddModal = (isReload) => {
		setShowAddModal(false);
		if (isReload) {
			getAllCollections("");
		}
	};
	const handleShowAddModal = () => {
		setShowAddModal(true);
		setSelectedCollection();
	};

	const handleCloseEditModal = (isReload) => {
		setShowEditModal(false);
		if (isReload) {
			getAllCollections("");
		}
	};
	const handleShowEditModal = (collectionId) => {
		setShowEditModal(true);
		setSelectedCollection(collectionId);
	};

	const columns = [
		{
			name: language ? "Hình ảnh" : "Image",
			style: {
				display: "flex",
				justifyContent: "center",
			},
			selector: (row) => (
				<div
					style={{
						width: "3rem",
						height: "3rem",
						backgroundImage: `url("${row.image}")`,
					}}
					className="image-default"
				/>
			),
		},
		{
			name: language ? "Tên bộ sưu tập" : "Collection Name",
			selector: (row) => (language ? row.nameVi : row.nameEn),
			wrap: true,
		},
		{
			name: "Slug",
			selector: (row) => row.slug,
			wrap: true,
		},
		{
			name: language ? "Cập nhật" : "Update",
			selector: (row) =>
				language
					? moment(row.updatedAt).format("YYYY-MM-DD, hh:mm a")
					: moment(row.updatedAt).format("DD-MM-YYYY, hh:mm"),
		},
		{
			name: language ? "Phương thức" : "Method",
			style: {
				display: "flex",
				justifyContent: "center",
			},
			selector: (row) => (
				<>
					<Button
						size="sm"
						variant="warning me-2"
						type="button"
						onClick={() => handleShowEditModal(row.id)}
						title="Chi tiết"
					>
						<Icon icon="pencil" />
					</Button>

					<Button
						size="sm"
						variant="danger"
						type="button"
						onClick={() => handleDeleteCollection(row.id)}
						title="Xóa"
					>
						<Icon icon="trash" />
					</Button>
				</>
			),
		},
	];

	return (
		<Container fluid>
			{language ? (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item active>Tổng quan</Breadcrumb.Item>
					<Breadcrumb.Item active>Bộ sưu tập</Breadcrumb.Item>
				</Breadcrumb>
			) : (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item active>Collection</Breadcrumb.Item>
				</Breadcrumb>
			)}

			<div className="d-block d-md-flex justify-content-between mb-3">
				<div>
					<Form
						className="d-flex"
						onSubmit={(e) => {
							e.preventDefault();
							getAllCollections(keyword);
						}}
					>
						<Form.Control
							type="search"
							placeholder={
								language
									? "Nhập tên bộ sưu tập"
									: "Enter the collection name"
							}
							className="me-2"
							aria-label="Search"
							value={keyword}
							onChange={(e) => setKeyword(e.target.value)}
						/>
						<Button variant="outline-secondary" type="submit">
							<Icon icon="magnifying-glass" />
						</Button>
					</Form>
				</div>
				<div className="mt-3 mt-md-0">
					<Button
						variant="primary"
						className="mt-2 mt-md-0"
						onClick={() => handleShowAddModal()}
					>
						<Icon icon="plus" /> {language ? "Thêm mới" : "Add new"}
					</Button>
				</div>
			</div>

			{isLoading ? (
				<Loading />
			) : (
				<DataTables data={collections} columns={columns} />
			)}

			{showAddModal ? (
				<CollectionAddModal
					handleCloseModal={handleCloseAddModal}
					showModal={showAddModal}
				/>
			) : (
				""
			)}

			{showEditModal ? (
				<CollectionEditModal
					handleCloseModal={handleCloseEditModal}
					showModal={showEditModal}
					selectedCollection={selectedCollection}
				/>
			) : (
				""
			)}
		</Container>
	);
};

export default Collection;
