import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ProductItem from "../../components/ProductItem";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Icon from "../../components/Icon";
import { toast } from "react-toastify";
import axios from "axios";
import Loading from "../../components/Loading";
import MyPagination from "../../components/MyPagination";

const itemsPerPage = 28;

const Category = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const [categories, setCategories] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);

	const [products, setProducts] = useState([]);
	const [productsIsLoading, setProductsIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalItems, setTotalItems] = useState(1);

	const [keyword, setKeyword] = useState("");

	useEffect(() => {
		document.title = language ? "Sản phẩm" : "Product";

		getAllCategories();
		getProductSearch([], "", 1);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getProductSearch(selectedCategories, keyword, currentPage);
	}, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getAllCategories = () => {
		axios
			.get(`/category/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setCategories(res.data.categories);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getProductSearch = async (categoryIds, keyword, page) => {
		setProductsIsLoading(true);

		await axios
			.get(`/search/product`, {
				params: {
					categoryIds: categoryIds,
					keyword: keyword,
					limit: itemsPerPage,
					page,
				},
			})
			.then((res) => {
				setProductsIsLoading(false);

				if (res.data.errCode === 0) {
					setProducts(res.data.products);
					setTotalItems(res.data.totalCount);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<Container>
			<Row className="my-4 my-md-5">
				<Col xs="12" md="3" className="mb-3">
					<div
						style={{
							position: "sticky",
							top: "5rem",
						}}
					>
						<Form.Control
							type="search"
							placeholder={
								language
									? "Nhập tên sản phẩm"
									: "Enter the product name"
							}
							className="mb-3"
							aria-label="Search"
							value={keyword}
							onChange={(e) => setKeyword(e.target.value)}
						/>

						<div className="d-flex justify-content-between">
							{/* <div
								style={{ fontSize: "1.2rem" }}
								className="fw-bold mb-3"
							>
								{language ? "Mức giá" : "Product Price"}
							</div> */}

							<div></div>

							<div
								style={{ fontSize: "0.8rem" }}
								className="product-item fw-bold fst-italic"
								onClick={() => setSelectedCategories([])}
							>
								{language ? "Bỏ chọn" : "Clear"}
							</div>
						</div>

						<div>
							<div
								style={{ fontSize: "1.2rem" }}
								className="fw-bold mb-3"
							>
								{language ? "Danh mục" : "Categories"}
							</div>

							{categories?.map((category) => {
								return (
									<div
										style={{
											fontSize: "0.8rem",
										}}
										key={category.id}
									>
										<div
											style={{
												fontWeight: 600,
												textTransform: "uppercase",
											}}
											className="d-flex justify-content-between align-items-center mb-2"
										>
											<div className="d-flex">
												<Form.Check
													type={`checkbox`}
													className="me-1"
													name="categoryIds"
													checked={selectedCategories.some(
														(id) =>
															id === category.id,
													)}
													onChange={(e) => {
														if (e.target.checked) {
															setSelectedCategories(
																[
																	...selectedCategories,
																	category.id,
																],
															);
														} else {
															setSelectedCategories(
																selectedCategories.filter(
																	(
																		selectedCategory,
																	) =>
																		selectedCategory !==
																		category.id,
																),
															);
														}
													}}
												/>

												<button
													style={{
														cursor: "pointer",
														background: "none",
														border: "none",
													}}
													onClick={() => {
														if (
															selectedCategories.some(
																(id) =>
																	id ===
																	category.id,
															)
														) {
															setSelectedCategories(
																selectedCategories.filter(
																	(
																		selectedCategory,
																	) =>
																		selectedCategory !==
																		category.id,
																),
															);
														} else {
															setSelectedCategories(
																[
																	...selectedCategories,
																	category.id,
																],
															);
														}
													}}
												>
													<div className="product-item fw-bold">
														{language
															? category.nameVi
															: category.nameEn}
													</div>
												</button>
											</div>

											{category.categories.length !==
											0 ? (
												<button
													style={{
														backgroundColor:
															"var(--white)",
														border: "none",
													}}
													data-bs-toggle="collapse"
													href={`#${category.slug}`}
													className="p-0 m-0"
												>
													<Icon icon="chevron-down" />
												</button>
											) : (
												""
											)}
										</div>

										<div
											className="collapse ps-3"
											id={category.slug}
										>
											{category.categories.map(
												(firstSubCategory) => {
													return (
														<div
															key={
																firstSubCategory.id
															}
														>
															<div
																style={{
																	fontWeight: 600,
																	textTransform:
																		"uppercase",
																}}
																className="d-flex justify-content-between align-items-center"
															>
																<div className="d-flex">
																	<Form.Check
																		type={`checkbox`}
																		className="me-1"
																		name="categoryIds"
																		checked={selectedCategories.some(
																			(
																				id,
																			) =>
																				id ===
																				firstSubCategory.id,
																		)}
																		onChange={(
																			e,
																		) => {
																			if (
																				e
																					.target
																					.checked
																			) {
																				setSelectedCategories(
																					[
																						...selectedCategories,
																						firstSubCategory.id,
																					],
																				);
																			} else {
																				setSelectedCategories(
																					selectedCategories.filter(
																						(
																							selectedCategory,
																						) =>
																							selectedCategory !==
																							firstSubCategory.id,
																					),
																				);
																			}
																		}}
																	/>

																	<button
																		style={{
																			cursor: "pointer",
																			background:
																				"none",
																			border: "none",
																		}}
																		onClick={() => {
																			if (
																				selectedCategories.some(
																					(
																						id,
																					) =>
																						id ===
																						firstSubCategory.id,
																				)
																			) {
																				setSelectedCategories(
																					selectedCategories.filter(
																						(
																							selectedCategory,
																						) =>
																							selectedCategory !==
																							firstSubCategory.id,
																					),
																				);
																			} else {
																				setSelectedCategories(
																					[
																						...selectedCategories,
																						firstSubCategory.id,
																					],
																				);
																			}
																		}}
																	>
																		<div className="product-item fw-bold mb-2">
																			{language
																				? firstSubCategory.nameVi
																				: firstSubCategory.nameEn}
																		</div>
																	</button>
																</div>

																{firstSubCategory
																	.categories
																	.length !==
																0 ? (
																	<button
																		style={{
																			backgroundColor:
																				"var(--white)",
																			border: "none",
																		}}
																		data-bs-toggle="collapse"
																		href={`#${firstSubCategory.slug}`}
																		className="p-0 m-0"
																	>
																		<Icon icon="chevron-down" />
																	</button>
																) : (
																	""
																)}
															</div>

															<div
																className="collapse ps-3"
																id={
																	firstSubCategory.slug
																}
															>
																{firstSubCategory.categories.map(
																	(
																		secondSubCategory,
																	) => {
																		return (
																			<div
																				key={
																					secondSubCategory.id
																				}
																				className="d-flex"
																			>
																				<Form.Check
																					type={`checkbox`}
																					className="me-1"
																					name="categoryIds"
																					checked={selectedCategories.some(
																						(
																							id,
																						) =>
																							id ===
																							secondSubCategory.id,
																					)}
																					onChange={(
																						e,
																					) => {
																						if (
																							e
																								.target
																								.checked
																						) {
																							setSelectedCategories(
																								[
																									...selectedCategories,
																									secondSubCategory.id,
																								],
																							);
																						} else {
																							setSelectedCategories(
																								selectedCategories.filter(
																									(
																										selectedCategory,
																									) =>
																										selectedCategory !==
																										secondSubCategory.id,
																								),
																							);
																						}
																					}}
																				/>

																				<button
																					style={{
																						cursor: "pointer",
																						background:
																							"none",
																						border: "none",
																					}}
																					onClick={() => {
																						if (
																							selectedCategories.some(
																								(
																									id,
																								) =>
																									id ===
																									secondSubCategory.id,
																							)
																						) {
																							setSelectedCategories(
																								selectedCategories.filter(
																									(
																										selectedCategory,
																									) =>
																										selectedCategory !==
																										secondSubCategory.id,
																								),
																							);
																						} else {
																							setSelectedCategories(
																								[
																									...selectedCategories,
																									secondSubCategory.id,
																								],
																							);
																						}
																					}}
																				>
																					<div className="product-item mb-2">
																						{language
																							? secondSubCategory.nameVi
																							: secondSubCategory.nameEn}
																					</div>
																				</button>
																			</div>
																		);
																	},
																)}
															</div>
														</div>
													);
												},
											)}
										</div>
									</div>
								);
							})}
						</div>

						<Button
							variant="outline-secondary"
							onClick={() => {
								getProductSearch(
									selectedCategories,
									keyword,
									1,
								);

								setCurrentPage(1);
							}}
							className="w-100 mt-3"
						>
							<Icon icon="magnifying-glass" />{" "}
							{language ? "Tìm kiếm" : "Search"}
						</Button>
					</div>
				</Col>

				<Col xs="12" md="9">
					<Row>
						{productsIsLoading ? (
							<Loading />
						) : products.length !== 0 ? (
							products.map((product, index) => {
								return (
									<Col
										key={product.id}
										xs="6"
										md="3"
										className="mb-3"
									>
										<ProductItem
											index={index + 1}
											item={product}
										/>
									</Col>
								);
							})
						) : (
							<div className="text-center">
								{language
									? "Không tìm thấy sản phẩm nào"
									: "No products found"}
							</div>
						)}
					</Row>

					{productsIsLoading ? (
						""
					) : (
						<MyPagination
							itemsCount={totalItems}
							itemsPerPage={itemsPerPage}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							alwaysShown={false}
						/>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default Category;
