import { useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import axios from "axios";
import { toast } from "react-toastify";

import Icon from "../../../components/Icon";
import DataTables from "../../../components/DataTables";
import Loading from "../../../components/Loading";
import ColorAddModal from "./ColorAddModal";
import ColorEditModal from "./ColorEditModal";
import { messages } from "../../../utils/Messages";

const Color = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const [colors, setColors] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [colorId, setColorId] = useState(0);

	useEffect(() => {
		getAllColors();
	}, []);

	// Api
	const getAllColors = async () => {
		setIsLoading(true);
		await axios
			.get(`/color/list`)
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setColors(res.data.colors);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteColor = async (colorId) => {
		await axios
			.delete(`/color/delete/${colorId}`)
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});

					getAllColors();
				} else {
					toast(messages('deletedFailure', language ? 'vi' : 'en'), { type: "error", autoClose: 1000, });
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleDeleteColor = (colorId) => {
		swal({
			title: language ? `Bạn muốn xóa màu này` : "You want to delete this color",
			icon: "warning",
			buttons: [language ? "Đóng" : "Close", language ? "Xóa" : "Delete"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				deleteColor(colorId);
			}
		});
	};

	const handleCloseAddModal = (isReload) => {
		setShowAddModal(false);
		if (isReload) {
			getAllColors();
		}
	};
	const handleShowAddModal = () => {
		setShowAddModal(true);
	};

	const handleCloseModal = (isReload) => {
		setShowEditModal(false);
		if (isReload) {
			getAllColors();
		}
	};
	const handleShowPermissionModal = (id) => {
		setColorId(id);
		setShowEditModal(true);
	};

	const columns = [
		{
			name: language ? "Tên màu" : "Color name",
			selector: (row) => (language ? row.nameVi : row.nameEn),
		},
		{
			name: language ? "Phương thức" : "Method",
			style: {
				display: "flex",
				justifyContent: "center",
			},
			selector: (row) => (
				<>
					<Button size="sm" variant="warning me-2" type="button" onClick={() => handleShowPermissionModal(row.id)}>
						<Icon icon="pencil" />
					</Button>

					<Button size="sm" variant="danger" type="button" onClick={() => handleDeleteColor(row.id)}>
						<Icon icon="trash" />
					</Button>
				</>
			),
		},
	];

	return (
		<Container fluid>
			{language ? (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item active>Tổng quan</Breadcrumb.Item>
					<Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
					<Breadcrumb.Item active>Màu sắc</Breadcrumb.Item>
				</Breadcrumb>
			) : (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item active>Setting</Breadcrumb.Item>
					<Breadcrumb.Item active>Color</Breadcrumb.Item>
				</Breadcrumb>
			)}

			<div className="d-block d-md-flex justify-content-between mb-3">
				<div className="mt-3 mt-md-0">
					<Button variant="primary" className="mt-2 mt-md-0" onClick={() => handleShowAddModal()}>
						<Icon icon="plus" /> {language ? "Thêm mới" : "Add new"}
					</Button>
				</div>
			</div>

			<Row>
				<Col xs={12} md={6}>
					{isLoading ? <Loading /> : <DataTables data={colors} columns={columns} />}
				</Col>
			</Row>

			{showAddModal ? <ColorAddModal handleCloseModal={handleCloseAddModal} showModal={showAddModal} /> : ""}

			{showEditModal ? <ColorEditModal handleCloseModal={handleCloseModal} showModal={showEditModal} selectedColor={colorId} /> : ""}
		</Container>
	);
};

export default Color;
