import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";
import ProductItem from "../../components/ProductItem";

import Icon from "../../components/Icon";
import Loading from "../../components/Loading";
import MyPagination from "../../components/MyPagination";

import { CATEGORY } from "../../routes/routerUrl";
import Carousel from "react-multi-carousel";

const responsiveBanner = {
	desktop: {
		breakpoint: { max: 3000, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};

const itemsPerPage = 28;

const Category = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const [categories, setCategories] = useState([]);
	const [category, setCategory] = useState({ images: [] });

	const [products, setProducts] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalItems, setTotalItems] = useState(1);

	const [categoriesIsLoading, setCategoriesIsLoading] = useState(false);
	const [productsIsLoading, setProductsIsLoading] = useState(false);

	let { slug } = useParams();

	useEffect(() => {
		document.title = language ? "Sản phẩm" : "Product";

		getAllCategories();
		getCategory(slug);
		getProductByCategory(slug, 1);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setCurrentPage(1);
		getCategory(slug);
		getProductByCategory(slug, 1);
	}, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getProductByCategory(slug, currentPage);
	}, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getAllCategories = () => {
		axios
			.get(`/category/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setCategories(res.data.categories);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getCategory = (slug) => {
		setCategoriesIsLoading(true);
		axios
			.get(`/category/client/${slug}`)
			.then((res) => {
				setCategoriesIsLoading(false);

				if (res.data.errCode === 0) {
					setCategory(res.data.category);
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getProductByCategory = async (slug, page) => {
		setProductsIsLoading(true);
		await axios
			.get(`/product/category/${slug}`, {
				params: {
					limit: itemsPerPage,
					page,
				},
			})
			.then((res) => {
				setProductsIsLoading(false);

				if (res.data.errCode === 0) {
					setProducts(res.data.products);
					setTotalItems(res.data.totalCount);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<>
			{categoriesIsLoading ? (
				""
			) : (
				<Carousel responsive={responsiveBanner} autoPlay autoPlaySpeed={6000} infinite={true} className="mb-3">
					{category.images?.map((item, index) => {
						return (
							<div
								key={index}
								style={{
									aspectRatio: "1430/640",
									backgroundImage: `url("${item.src}")`,
								}}
								className="image-default"
							/>
						);
					})}
				</Carousel>
			)}

			<div
				style={{
					aspectRatio: "1920/850",
					backgroundImage: `url("${category.image}")`,
					backgroundSize: "cover",
				}}
				className="d-md-none d-block image-default mb-3"
			/>

			<Container>
				<Row className="my-4 my-md-5">
					<Col xs="12" md="2" className="d-none d-md-block">
						<div className="mb-3">{language ? category.nameVi : category.nameEn}</div>

						<div style={{ fontSize: "1.2rem" }} className="fw-bold mb-3">
							{language ? "Danh mục" : "Categories"}
						</div>

						{categories?.map((category) => {
							return (
								<div
									style={{
										fontSize: "0.8rem",
									}}
									key={category.id}
								>
									<div
										style={{
											fontWeight: 600,
											textTransform: "uppercase",
										}}
										className="d-flex justify-content-between align-items-center mb-2"
									>
										<Link to={`${CATEGORY}/${category.slug}`} style={{ textDecoration: "none" }}>
											<div className="product-item">{language ? category.nameVi : category.nameEn}</div>
										</Link>

										{category.categories.length !== 0 ? (
											<button
												style={{
													backgroundColor: "var(--white)",
													border: "none",
												}}
												data-bs-toggle="collapse"
												href={`#${category.slug}`}
												className="p-0 m-0"
											>
												<Icon icon="chevron-down" />
											</button>
										) : (
											""
										)}
									</div>

									<div className="collapse ps-3" id={category.slug}>
										{category.categories.map((firstSubCategory) => {
											return (
												<div key={firstSubCategory.id}>
													<div
														style={{
															fontWeight: 600,
															textTransform: "uppercase",
														}}
														className="d-flex justify-content-between align-items-center"
													>
														<Link
															to={`${CATEGORY}/${firstSubCategory.slug}`}
															style={{
																textDecoration: "none",
															}}
														>
															<div className="product-item mb-2">
																{language ? firstSubCategory.nameVi : firstSubCategory.nameEn}
															</div>
														</Link>

														{firstSubCategory.categories.length !== 0 ? (
															<button
																style={{
																	backgroundColor: "var(--white)",
																	border: "none",
																}}
																data-bs-toggle="collapse"
																href={`#${firstSubCategory.slug}`}
																className="p-0 m-0"
															>
																<Icon icon="chevron-down" />
															</button>
														) : (
															""
														)}
													</div>

													<div className="collapse ps-3" id={firstSubCategory.slug}>
														{firstSubCategory.categories.map((secondSubCategory) => {
															return (
																<Link
																	key={secondSubCategory.id}
																	to={`${CATEGORY}/${secondSubCategory.slug}`}
																	style={{
																		textDecoration: "none",
																	}}
																>
																	<div className="product-item mb-2">
																		{language ? secondSubCategory.nameVi : secondSubCategory.nameEn}
																	</div>
																</Link>
															);
														})}
													</div>
												</div>
											);
										})}
									</div>
								</div>
							);
						})}
					</Col>

					<Col xs="12" md="10">
						<div style={{ fontSize: "1.2rem" }} className="d-block d-md-none fw-bold mb-3">
							{language ? category.nameVi : category.nameEn}
						</div>

						<Row>
							{productsIsLoading ? (
								<Loading />
							) : products.length !== 0 ? (
								products.map((product, index) => {
									return (
										<Col key={product.id} xs="6" md="3" className="mb-3">
											<ProductItem index={index + 1} item={product} />
										</Col>
									);
								})
							) : (
								<div className="text-center">{language ? "Chưa có sản phẩm nào" : "No products found"}</div>
							)}
						</Row>

						{productsIsLoading ? (
							""
						) : (
							<MyPagination
								itemsCount={totalItems}
								itemsPerPage={itemsPerPage}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
								alwaysShown={false}
							/>
						)}
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Category;
