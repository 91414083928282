import { useEffect, useState } from "react";
import { Breadcrumb, Button, Container, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import axios from "axios";
import { toast } from "react-toastify";

import Icon from "../../../components/Icon";
import DataTables from "../../../components/DataTables";
import Loading from "../../../components/Loading";
import StaffAddModal from "./StaffAddModal";
import StaffPermissionModal from "./StaffPermissionModal";

const Staff = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const [staffs, setStaffs] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [keyword, setKeyword] = useState("");
	const [showAddModal, setShowAddModal] = useState(false);
	const [showPermissionModal, setShowPermissionModal] = useState(false);
	const [staffId, setStaffId] = useState(0);

	useEffect(() => {
		getAllStaffs();
	}, []);

	// Api
	const getAllStaffs = async (keyword) => {
		setIsLoading(true);
		await axios
			.get(`/user/list`, {
				params: {
					role: 2,
					keyword: keyword,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setStaffs(res.data.users);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteUser = async (userId) => {
		await axios
			.delete(`/user/delete/${userId}`)
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});

					getAllStaffs("");
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleDeleteUser = (userId) => {
		swal({
			title: language
				? `Bạn muốn xóa nhân viên này`
				: "You want to delete this staff",
			icon: "warning",
			buttons: [language ? "Đóng" : "Close", language ? "Xóa" : "Delete"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				deleteUser(userId);
			}
		});
	};

	const handleCloseAddModal = (isReload) => {
		setShowAddModal(false);
		if (isReload) {
			getAllStaffs("");
		}
	};
	const handleShowAddModal = () => {
		setShowAddModal(true);
	};

	const handleClosePermissionModal = (isReload) => {
		setShowPermissionModal(false);
		if (isReload) {
			getAllStaffs("");
		}
	};
	const handleShowPermissionModal = (id) => {
		setStaffId(id);
		setShowPermissionModal(true);
	};

	const columns = [
		{
			name: language ? "Họ và tên" : "Fullname",
			selector: (row) => row.fullname,
		},
		{
			name: language ? "Số điện thoại" : "Phone number",
			selector: (row) => row.phonenumber,
		},
		{
			name: "Email",
			selector: (row) => row.email,
		},
		{
			name: language ? "Địa chỉ" : "Address",
			selector: (row) => row.address,
			wrap: true,
		},
		{
			name: language ? "Phương thức" : "Method",
			style: {
				display: "flex",
				justifyContent: "center",
			},
			selector: (row) => (
				<>
					<Button
						size="sm"
						variant="warning me-2"
						type="button"
						onClick={() => handleShowPermissionModal(row.id)}
						title="Chi tiết"
					>
						<Icon icon="pencil" />
					</Button>

					<Button
						size="sm"
						variant="danger"
						type="button"
						onClick={() => handleDeleteUser(row.id)}
						title="Xóa"
					>
						<Icon icon="trash" />
					</Button>
				</>
			),
		},
	];

	return (
		<Container fluid>
			{language ? (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item active>Tổng quan</Breadcrumb.Item>
					<Breadcrumb.Item active>Nhân viên</Breadcrumb.Item>
				</Breadcrumb>
			) : (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item active>Staff</Breadcrumb.Item>
				</Breadcrumb>
			)}

			<div className="d-block d-md-flex justify-content-between mb-3">
				<div>
					<Form
						className="d-flex"
						onSubmit={(e) => {
							e.preventDefault();
							getAllStaffs(keyword);
						}}
					>
						<Form.Control
							type="search"
							placeholder={
								language
									? "Nhập tên nhân viên"
									: "Enter the staff name"
							}
							className="me-2"
							aria-label="Search"
							value={keyword}
							onChange={(e) => setKeyword(e.target.value)}
						/>
						<Button variant="outline-secondary" type="submit">
							<Icon icon="magnifying-glass" />
						</Button>
					</Form>
				</div>
				<div className="mt-3 mt-md-0">
					<Button
						variant="primary"
						className="mt-2 mt-md-0"
						onClick={() => handleShowAddModal()}
					>
						<Icon icon="plus" /> {language ? "Thêm mới" : "Add new"}
					</Button>
				</div>
			</div>

			{isLoading ? (
				<Loading />
			) : (
				<DataTables data={staffs} columns={columns} />
			)}

			{showAddModal ? (
				<StaffAddModal
					handleCloseModal={handleCloseAddModal}
					showModal={showAddModal}
				/>
			) : (
				""
			)}

			{showPermissionModal ? (
				<StaffPermissionModal
					handleCloseModal={handleClosePermissionModal}
					showModal={showPermissionModal}
					staffId={staffId}
				/>
			) : (
				""
			)}
		</Container>
	);
};

export default Staff;
