import { Breadcrumb, Button, Col, Container, Form, Row } from "react-bootstrap";
import DataTables from "../../../components/DataTables";
import Icon from "../../../components/Icon";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import MoneyFormat from "../../../components/MoneyFormat";
import { useSelector } from "react-redux";
import moment from "moment";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading";
import Select from "react-select";

const orderStatusOptions = [
	{ id: 0, nameVi: "Tất cả", nameEn: "All" },
	{ id: 1, nameVi: "Chưa xác nhận", nameEn: "Not confirmed yet" },
	{ id: 2, nameVi: "Đã xác nhận", nameEn: "Confirmed" },
	{ id: 3, nameVi: "Đang vận chuyển", nameEn: "Is shipping" },
	{ id: 4, nameVi: "Hoàn thành", nameEn: "Completed" },
	{ id: 5, nameVi: "Đã hủy", nameEn: "Canceled" },
];

const statusButton = [
	{ id: 1, labelVi: "Xác nhận", labelEn: "Confirm", variant: "dark", orderStatus: 2 },
	{ id: 2, labelVi: "Giao hàng", labelEn: "To Ship", variant: "primary", orderStatus: 3 },
	{ id: 3, labelVi: "Thanh toán", labelEn: "Payment", variant: "success", orderStatus: 4 },
	{ id: 4, labelVi: "Hủy", labelEn: "Cancel", variant: "warning", orderStatus: 5 },
	{ id: 5, labelVi: "Xóa", labelEn: "Delete", variant: "danger", orderStatus: 0 },
];

const Order = () => {
	const language = useSelector((state) => state.language.isVietnamese);

	const [orders, setOrders] = useState([]);
	const [selectedOrders, setSelectedOrders] = useState([]);

	const [isLoading, setIsLoading] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState({
		id: 0,
		nameVi: "Tất cả",
		nameEn: "All",
	});
	const [keyword, setKeyword] = useState("");

	useEffect(() => {
		getAllOrders("", 0);
	}, []);

	// API
	const getAllOrders = (keyword, statusId) => {
		setIsLoading(true);
		axios
			.get(`/order/list`, {
				params: {
					keyword: keyword,
					orderStatus: statusId,
				},
			})
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					const newOrders = res.data.orders?.map((order) => {
						let totalVi = 0;
						let totalEn = 0;

						order.orderDetails?.map((orderDetail) => {
							totalVi += Number(orderDetail.priceVi * orderDetail.quantity);
							totalEn += Number(orderDetail.priceEn * orderDetail.quantity);
							return orderDetail;
						});

						return {
							...order,
							totalVi: totalVi,
							totalEn: totalEn,
						};
					});

					setOrders(newOrders);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteOrder = async (orderId) => {
		await axios
			.delete(`/order/delete/${orderId}`)
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});

					getAllOrders(keyword, selectedStatus.id);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDeleteOrder = (orderId) => {
		swal({
			title: language ? `Bạn muốn xóa đơn hàng này` : "You want to delete this order",
			icon: "warning",
			buttons: [language ? "Đóng" : "Close", language ? "Xóa" : "Delete"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				deleteOrder(orderId);
			}
		});
	};

	const handleUpdateOrderStatus = (item) => {
		swal({
			title: language ? `Bạn muốn ${item.labelVi.toLowerCase()} những đơn hàng này` : `You want to ${item.labelEn.toLowerCase()} this order`,
			icon: "warning",
			buttons: [language ? "Đóng" : "Close", language ? item.labelVi : item.labelEn],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				updateOrderStatus(item.orderStatus);
			}
		});
	};

	const updateOrderStatus = async (orderStatus) => {
		await axios
			.put(`/order/updateStatus`, { orderIds: selectedOrders.map((orderItem) => orderItem.id), orderStatus: orderStatus })
			.then((res) => {
				if (res.data.errCode === 0) {
					getAllOrders(keyword, selectedStatus.id);
					setSelectedOrders([]);

					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	const columns = [
		{
			name: language ? "Khách hàng" : "Customer",
			selector: (row) => row.fullname,
			wrap: true,
		},
		{
			name: language ? "Tổng giá trị" : "Total order",
			style: {
				display: "flex",
				justifyContent: "end",
			},
			selector: (row) => <MoneyFormat money={language ? row.totalVi : row.totalEn} />,
			wrap: true,
		},
		{
			name: language ? "Trạng thái" : "Status",
			headerStyle: { textAlign: "center" },

			selector: (row) =>
				language
					? orderStatusOptions.find((statusItem) => statusItem.id === row.orderStatusId)?.nameVi
					: orderStatusOptions.find((statusItem) => statusItem.id === row.orderStatusId)?.nameEn,
			style: {
				display: "flex",
				justifyContent: "center",
			},
		},
		{
			name: language ? "Cập nhật" : "Update",
			selector: (row) => (language ? moment(row.updatedAt).format("DD-MM-YYYY, hh:mm") : moment(row.updatedAt).format("YYYY-MM-DD, hh:mm a")),
		},
		{
			name: language ? "Phương thức" : "Method",
			style: {
				display: "flex",
				justifyContent: "center",
			},
			selector: (row) => (
				<>
					<Link to={`/admin/order/${row.id}`}>
						<Button size="sm" variant="warning me-2" type="button">
							<Icon icon="pencil" />
						</Button>
					</Link>

					<Button size="sm" variant="danger" type="button" onClick={() => handleDeleteOrder(row.id)}>
						<Icon icon="trash" />
					</Button>
				</>
			),
		},
	];

	const productColumns = [
		{
			name: language ? "Tên sản phẩm" : "Product Name",
			selector: (row) => (language ? row.product.nameVi : row.product.nameEn),
			wrap: true,
		},
		{
			name: language ? "Giá" : "Price",
			style: {
				display: "flex",
				justifyContent: "end",
			},
			selector: (row) => <MoneyFormat money={language ? row.priceVi : row.priceEn} />,
			wrap: true,
		},
		{
			name: language ? "Màu sắc" : "Color",
			selector: (row) => (language ? row.color?.nameVi : row.color?.nameEn),
			wrap: true,
		},
		{
			name: language ? "Kích cỡ" : "Size",
			selector: (row) => row.size?.name,
			wrap: true,
		},
		{
			name: language ? "Số lượng" : "Quantity",
			selector: (row) => row.quantity,
			wrap: true,
		},
	];

	// Render
	const ProductTable = ({ data }) => {
		if (!data) {
			return <i className="text-muted ms-5"> {language ? "Không có dữ liệu!" : "No data"}</i>;
		}

		return <DataTables className="ms-5" data={data.orderDetails} columns={productColumns} />;
	};

	return (
		<Container fluid>
			{language ? (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item active>Tổng quan</Breadcrumb.Item>
					<Breadcrumb.Item active>Đơn hàng</Breadcrumb.Item>
				</Breadcrumb>
			) : (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item active>Order</Breadcrumb.Item>
				</Breadcrumb>
			)}

			{selectedOrders.length > 0 ? (
				statusButton.map((buttonItem) => {
					return (
						<Button
							key={buttonItem.id}
							variant={buttonItem.variant}
							size="md"
							className="px-3 me-2 mb-3"
							onClick={() => handleUpdateOrderStatus(buttonItem)}
						>
							{language ? buttonItem.labelVi : buttonItem.labelEn}
						</Button>
					);
				})
			) : (
				<Row className="mb-3">
					<Col xs={12} md={5}>
						<Form
							className="d-flex"
							onSubmit={(e) => {
								e.preventDefault();
								getAllOrders(keyword, selectedStatus.id);
							}}
						>
							<Form.Control
								type="search"
								placeholder={language ? "Nhập tên khách hàng" : "Enter customer name"}
								aria-label="Search"
								value={keyword}
								onChange={(e) => setKeyword(e.target.value)}
							/>

							<Button variant="outline-secondary" type="submit">
								<Icon icon="magnifying-glass" />
							</Button>
						</Form>
					</Col>

					<Col xs={12} md={3}>
						<Select
							options={orderStatusOptions}
							placeholder={<div>{language ? "Chọn trạng thái đơn hàng" : "Select order status"}</div>}
							noOptionsMessage={() => (language ? "Không có lựa chọn" : "No options")}
							getOptionLabel={(option) => (language ? option.nameVi : option.nameEn)}
							getOptionValue={(option) => option.id}
							value={selectedStatus}
							onChange={(choice) => {
								setSelectedStatus(choice);
								setKeyword("");
								getAllOrders("", choice.id);
							}}
							menuPortalTarget={document.body}
							styles={{
								menuPortal: (base) => ({
									...base,
									zIndex: 9999,
								}),
							}}
						/>
					</Col>
				</Row>
			)}

			<DataTables
				data={orders}
				columns={columns}
				selectableRows
				onSelectedRowsChange={(item) => {
					setSelectedOrders(item.selectedRows);
				}}
				expandableRows
				expandableRowsComponent={ProductTable}
				progressPending={isLoading}
				progressComponent={<Loading />}
			/>
		</Container>
	);
};

export default Order;
