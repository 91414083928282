import React, { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Icon from "../../components/Icon";
import { useState } from "react";

const navbarItems = [
	{
		id: 1,
		labelVi: "Tổng quan",
		labelEn: "Dashboard",
		icon: "gauge",
		link: "",
	},
	{
		id: 2,
		labelVi: "Đơn hàng",
		labelEn: "Order",
		icon: "dolly",
		link: "order",
		code: "alc_order",
	},
	{
		id: 3,
		labelVi: "Khách hàng",
		labelEn: "Customer",
		icon: "users",
		link: "customer",
		code: "alc_order",
	},
	{
		id: 4,
		labelVi: "Tài khoản khách hàng",
		labelEn: "Customer account",
		icon: "users",
		link: "user",
		code: "alc_customer",
	},
	{
		id: 6,
		labelVi: "Sản phẩm",
		labelEn: "Product",
		icon: "shirt",
		link: "product",
		code: "alc_product",
	},
	{
		id: 7,
		labelVi: "Bộ sưu tập",
		labelEn: "Collection",
		icon: "shirt",
		link: "collection",
		code: "alc_collection",
	},
	{
		id: 8,
		labelVi: "Nhân viên",
		labelEn: "Staff",
		icon: "users-gear",
		link: "staff",
		code: "alc_setting",
	},
];

const settingItems = [
	{
		id: 0,
		labelVi: "Cửa hàng",
		labelEn: "Shop",
		icon: "shop",
		link: "setting",
		code: "alc_shop",
	},
	{
		id: 1,
		labelVi: "Danh mục sản phẩm",
		labelEn: "Category",
		icon: "tags",
		link: "category",
		code: "alc_category",
	},
	{
		id: 2,
		labelVi: "Màu sắc",
		labelEn: "Color",
		icon: "palette",
		link: "color",
		code: "alc_color",
	},
	{
		id: 3,
		labelVi: "Kích thước",
		labelEn: "Size",
		icon: "up-right-and-down-left-from-center",
		link: "size",
		code: "alc_color",
	},
];

const Sidebar = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const location = useLocation();

	const [userPermission, setUserPermission] = useState([]);

	useEffect(() => {
		getUserPermission();
	}, []);

	const getUserPermission = async () => {
		await axios
			.get(`/permission/user`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setUserPermission(res.data.permissions);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<nav className="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion">
			<div className="sb-sidenav-menu">
				<div className="nav">
					{navbarItems.map((item) => {
						if (userPermission.find((permission) => permission.code === item.code) || item.id === 1) {
							return (
								<Link
									key={item.id}
									to={`/admin/${item.link}`}
									className={`nav-link ${item.id === 1
										? location.pathname === "/admin" || location.pathname === "/admin/"
											? "active"
											: ""
										: location.pathname.startsWith(`/admin/${item.link}`)
											? "active"
											: ""
										}`}
								>
									<div className="sb-nav-link-icon me-2">
										<Icon icon={item.icon} />
									</div>
									{language ? item.labelVi : item.labelEn}
								</Link>
							);
						}

						return "";
					})}

					<Link
						className="nav-link-hover nav-link collapsed"
						data-bs-toggle="collapse"
						data-bs-target={`#setting`}
						aria-expanded="false"
						aria-controls={`setting`}
					>
						<div className="sb-nav-link-icon">
							<Icon icon="gear" />
						</div>
						{language ? "Cài đặt" : "Setting"}
						<div className="sb-sidenav-collapse-arrow">
							<Icon icon="chevron-down" />
						</div>
					</Link>
					<div id={`setting`} className="collapse" aria-labelledby="headingOne">
						<nav className="sb-sidenav-menu-nested nav">
							{settingItems.map((item) => {
								return (
									<Link
										key={item.id}
										to={item.link}
										className={`nav-link nav-link-hover ${location.pathname.startsWith(`/admin/${item.link}`) ? "nav-link-on-hover active" : ""
											}`}
									>
										<div className="sb-nav-link-icon">
											<Icon icon={item.icon} />
										</div>
										{language ? item.labelVi : item.labelEn}
									</Link>
								);
							})}
						</nav>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Sidebar;
