import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import "./style.css";
import { toast } from "react-toastify";
import axios from "axios";
// import FacebookMessenger from "../../components/FacebookMessenger";

function MasterLayout() {
	const [showSideBar, setShowSideBar] = useState(false);
	const [shop, setShop] = useState({});
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		getSetting();
		getAllCategories();
	}, []);

	// API
	const getSetting = async () => {
		await axios
			.get(`/shop`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setShop(res.data.shop);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllCategories = () => {
		axios
			.get(`/category/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setCategories(res.data.categories);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<div style={{ backgroundColor: "var(--white)" }} className="app">
			<Navbar setShowSideBar={setShowSideBar} showSideBar={showSideBar} shop={shop} categories={categories} />

			<Sidebar setShowSideBar={setShowSideBar} showSideBar={showSideBar} shop={shop} categories={categories} />

			<div onClick={() => setShowSideBar(false)}>
				<div className={`${showSideBar ? "curtain active" : "curtain"}`} />

				<Outlet />
			</div>

			{/* <FacebookMessenger /> */}

			<Footer shop={shop} />
		</div>
	);
}

export default MasterLayout;
