import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";

import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "../../store/slices/cartSlice";

import MoneyFormat from "../../components/MoneyFormat";

import axios from "axios";
import { EN_CART_CREATE, VI_CART_CREATE } from "../../utils/message";
import { addToUserCart } from "../../store/slices/userCartSlice";
import { PRODUCT } from "../../routes/routerUrl";

const CollectionDetail = () => {
	let { slug } = useParams();

	const dispatch = useDispatch();
	const language = useSelector((state) => state.language.isVietnamese);

	const [collection, setCollection] = useState({
		category: {
			nameVi: "",
			nameEn: "",
		},
		images: [],
		nameVi: "",
		nameEn: "",
	});

	useEffect(() => {
		getDetailCollection(slug);
	}, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		document.title = language ? collection.nameVi : collection.nameEn;
	}, [language]); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getDetailCollection = async (collectionSlug) => {
		await axios
			.get(`/combo/client/${collectionSlug}`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setCollection(res.data.combo);

					document.title = language ? res.data.combo.nameVi : res.data.combo.nameEn;
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<Container>
			<Row className="my-5">
				<Col xs="12" md="6" className="position-relative justify-content-center">
					<div
						style={{
							aspectRatio: "600/900",
							backgroundImage: `url("${collection.image}")`,
							backgroundSize: "cover",
						}}
						className="image-default"
					/>
				</Col>

				<Col xs="12" md="6" className="mt-3 mt-md-0">
					<div style={{ fontSize: "1.5rem" }} className="fw-bold">
						<div className="text-overflow-2-line">{language ? collection.nameVi : collection.nameEn}</div>
					</div>

					<div className="my-4 border-top" />

					{collection.productCombos?.map((productCombo) => {
						return (
							<Row key={productCombo.id} className="mb-3">
								<Col xs="4" className="d-flex align-items-center">
									<div
										style={{
											aspectRatio: 1,
											backgroundImage: `url("${productCombo.product.images[0].src}")`,
											backgroundSize: "cover",
										}}
										className="image-default"
									/>
								</Col>
								<Col xs="8">
									<Link
										to={`${PRODUCT}/${productCombo.product.slug}`}
										style={{ textDecoration: "none", height: "3rem", color: "black" }}
										className="responsive-text text-overflow-2-line"
									>
										{language
											? `${productCombo.product.nameVi} - ${productCombo.product.colors[0].color?.nameVi} - ${productCombo.product.sizes[0].size?.name}`
											: `${productCombo.product.nameEn} - ${productCombo.product.colors[0].color?.nameEn} - ${productCombo.product.sizes[0].size?.name}`}
									</Link>

									<div className="d-flex align-items-center">
										<MoneyFormat
											money={
												language
													? productCombo.product.salePriceVi
														? productCombo.product.salePriceVi
														: productCombo.product.priceVi
													: productCombo.product.salePriceEn
													? productCombo.product.salePriceEn
													: productCombo.product.priceEn
											}
											className="fw-bold responsive-text text-overflow-1-line me-2"
										/>
										<MoneyFormat
											money={
												language
													? productCombo.product.salePriceVi
														? productCombo.product.priceVi
														: ""
													: productCombo.product.salePriceEn
													? productCombo.product.priceEn
													: ""
											}
											style={{ fontSize: "1rem", color: "#929292", textDecoration: "line-through" }}
										/>
									</div>

									<Button
										className="mlb-button px-4 py-2 mt-3"
										onClick={() => {
											if (localStorage.getItem("access_token")) {
												dispatch(
													addToUserCart({
														id: productCombo.product.id,
														image: productCombo.product.images[0].src,
														nameVi: productCombo.product.nameVi,
														nameEn: productCombo.product.nameEn,
														priceVi: productCombo.product.salePriceVi
															? productCombo.product.salePriceVi
															: productCombo.product.priceVi,
														priceEn: productCombo.product.salePriceEn
															? productCombo.product.salePriceEn
															: productCombo.product.priceEn,
														quantity: 1,
														color: {
															id: productCombo.product.colors[0].color.id,
															nameVi: productCombo.product.colors[0].color.nameVi,
															nameEn: productCombo.product.colors[0].color.nameEn,
														},
														size: {
															id: productCombo.product.sizes[0].size.id,
															name: productCombo.product.sizes[0].size.name,
														},
													}),
												);
											} else {
												dispatch(
													addToCart({
														id: productCombo.product.id,
														image: productCombo.product.images[0].src,
														nameVi: productCombo.product.nameVi,
														nameEn: productCombo.product.nameEn,
														priceVi: productCombo.product.salePriceVi
															? productCombo.product.salePriceVi
															: productCombo.product.priceVi,
														priceEn: productCombo.product.salePriceEn
															? productCombo.product.salePriceEn
															: productCombo.product.priceEn,
														quantity: 1,
														color: {
															id: productCombo.product.colors[0].color.id,
															nameVi: productCombo.product.colors[0].color.nameVi,
															nameEn: productCombo.product.colors[0].color.nameEn,
														},
														size: {
															id: productCombo.product.sizes[0].size.id,
															name: productCombo.product.sizes[0].size.name,
														},
													}),
												);
											}

											toast(language ? VI_CART_CREATE : EN_CART_CREATE, {
												type: "success",
												autoClose: 2000,
											});
										}}
									>
										{language ? "Thêm Vào Giỏ Hàng" : "Add To Cart"}
									</Button>
								</Col>
							</Row>
						);
					})}
				</Col>
			</Row>
		</Container>
	);
};

export default CollectionDetail;
