import { useEffect, useState } from "react";
import axios from "axios";
import {
	Breadcrumb,
	Button,
	Col,
	Container,
	Form,
	Row,
	Table,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import * as XLSX from "xlsx-js-style";

import Icon from "../../../components/Icon";
import DataTables from "../../../components/DataTables";
import Loading from "../../../components/Loading";
import swal from "sweetalert";

const Customer = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const [customers, setCustomers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [keyword, setKeyword] = useState("");

	useEffect(() => {
		getAllCustomers();
	}, []);

	// Api
	const getAllCustomers = async (keyword) => {
		setIsLoading(true);
		await axios
			.get(`/order/customer`, {
				params: {
					role: 3,
					keyword: keyword,
				},
			})
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					setCustomers([
						...new Map(
							res.data.orders.map((item) => [
								item["phonenumber"],
								item,
							]),
						).values(),
					]);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	// Logic
	const xport = () => {
		const wb = XLSX.utils.book_new();

		const table = document.getElementById("customers-table");
		var ws = XLSX.utils.table_to_sheet(table, { raw: true });

		const colAlpha = ["A", "B", "C", "D"];

		colAlpha.map((alpha) => {
			ws[`${alpha}1`].s = {
				font: {
					name: "Times New Roman",
					bold: true,
				},
				alignment: {
					vertical: "center",
					horizontal: "center",
					wrapText: true,
				},
				border: {
					top: { style: "thin" },
					bottom: { style: "thin" },
					left: { style: "thin" },
					right: { style: "thin" },
				},
			};

			return alpha;
		});

		for (let i = 2; i < customers.length + 2; i++) {
			colAlpha.map((alpha) => {
				if (ws[`${alpha}${i}`].v === "empty") {
					ws[`${alpha}${i}`].v = " ";
				}

				ws[`${alpha}${i}`].s = {
					font: {
						name: "Times New Roman",
					},
					alignment: {
						vertical: "center",
						horizontal:
							alpha === "C" || alpha === "A" ? "center" : "left",
						wrapText: true,
					},
					border: {
						top: { style: "thin" },
						bottom: { style: "thin" },
						left: { style: "thin" },
						right: { style: "thin" },
					},
				};

				return alpha;
			});
		}

		ws["!cols"] = [{ wch: 5 }, { wch: 25 }, { wch: 15 }, { wch: 40 }];

		XLSX.utils.book_append_sheet(
			wb,
			ws,
			language ? "Danh sách khách hàng" : "Customer list",
		);
		XLSX.writeFile(
			wb,
			language ? "Danh sách khách hàng.xlsx" : "Customer list.xlsx",
		);
	};

	const handleExportExcel = () => {
		swal({
			title: language ? `Xác nhận` : "Confirm",
			text: language
				? `Bạn muốn xuất danh sách khách hàng`
				: "You want to export customer list",
			icon: "info",
			buttons: [language ? "Đóng" : "Close", "Export"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				xport();
			}
		});
	};
	// Logic

	const columns = [
		{
			name: language ? "Họ và tên" : "Fullname",
			selector: (row) => row.fullname,
			wrap: true,
		},
		{
			name: language ? "Số điện thoại" : "Phone number",
			selector: (row) => row.phonenumber,
			wrap: true,
		},
		{
			name: language ? "Địa chỉ" : "Address",
			selector: (row) => row.address,
			wrap: true,
		},
	];

	return (
		<Container fluid>
			{language ? (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item active>Tổng quan</Breadcrumb.Item>
					<Breadcrumb.Item active>Khách hàng</Breadcrumb.Item>
				</Breadcrumb>
			) : (
				<Breadcrumb className="mt-2">
					<Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item active>Customer</Breadcrumb.Item>
				</Breadcrumb>
			)}

			<Row className="justify-content-between mb-3">
				<Col xs="12" md="4">
					<Form
						className="d-flex"
						onSubmit={(e) => {
							e.preventDefault();
							getAllCustomers(keyword);
						}}
					>
						<Form.Control
							type="search"
							placeholder={
								language
									? "Nhập tên khách hàng"
									: "Enter the customer name"
							}
							className="me-2"
							aria-label="Search"
							value={keyword}
							onChange={(e) => setKeyword(e.target.value)}
						/>
						<Button type="submit" variant="outline-secondary">
							<Icon icon="magnifying-glass" />
						</Button>
					</Form>
				</Col>

				<Col
					xs="12"
					md="4"
					className="text-start text-md-end mt-3 mt-md-0"
				>
					<Button
						variant="success"
						onClick={() => {
							if (customers.length === 0) {
								toast(
									language
										? "Không tìm thấy khách hàng nào"
										: "No customer found",
									{
										type: "error",
										autoClose: 2000,
									},
								);
								return;
							}

							handleExportExcel();
						}}
						disabled={customers.length === 0}
					>
						{language ? "Xuất file" : "Export file"}{" "}
						<Icon icon="file-export" />
					</Button>
				</Col>
			</Row>

			{isLoading ? (
				<Loading />
			) : (
				<DataTables data={customers} columns={columns} />
			)}

			<Table id="customers-table" className="d-none">
				<thead>
					<tr className="text-center align-middle">
						<th>{language ? "STT" : "No"}</th>
						<th>{language ? "Họ và tên" : "Fullname"}</th>
						<th>{language ? "Số điện thoại" : "Phone number"}</th>
						<th>{language ? "Địa chỉ" : "Address"}</th>
					</tr>
				</thead>
				<tbody>
					{customers.map((customer, index) => {
						return (
							<tr key={customer.id}>
								<td>{index + 1}</td>
								<td>
									{customer.fullname
										? customer.fullname
										: "empty"}
								</td>
								<td>
									{customer.phonenumber
										? customer.phonenumber
										: "empty"}
								</td>
								<td>
									{customer.address
										? customer.address
										: "empty"}
								</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</Container>
	);
};

export default Customer;
