import { useEffect, useState } from "react";
import Icon from "./Icon";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const OrderNotification = () => {
	const language = useSelector((state) => state.language.isVietnamese);
	const [orders, setOrders] = useState([]);

	useEffect(() => {
		getNotificationOrders();
	}, []);

	const getNotificationOrders = async () => {
		await axios
			.get(`/order/notification`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setOrders(res.data.orders);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
			<div className="showNotifi me-3">
				<button style={{ border: "none", background: "none" }} className="justify-content-center align-items-center position-relative">
					<Icon icon="bell" style={{ fontSize: "1.2rem" }} />
					<span className="position-absolute start-100 translate-middle badge rounded-pill bg-danger" style={{ top: "15%" }}>
						{orders.length !== 0 ? orders.length : ""}
					</span>
				</button>

				<div className="frame-Notifi d-flex justify-content-end position-absolute">
					<div className="shadow-app body-Notifi p-2">
						{/* Header */}
						<Row className="mx-0 py-1">
							<div>
								{orders.filter((order) => order.orderStatusId === 1).length > 0
									? language
										? `Có ${orders.filter((order) => order.orderStatusId === 1).length} đơn hàng đang chờ xử lý...`
										: `There are ${orders.filter((order) => order.orderStatusId === 1).length} orders pending...`
									: ""}
							</div>
							<div>
								{orders.filter((order) => order.orderStatusId === 5 && new Date().getTime() - new Date(order.updatedAt).getTime() <= 86400000)
									.length > 0
									? language
										? `Có ${
												orders.filter(
													(order) =>
														order.orderStatusId === 5 && new Date().getTime() - new Date(order.updatedAt).getTime() <= 86400000,
												).length
										  } đã bị hủy...`
										: `There was ${
												orders.filter(
													(order) =>
														order.orderStatusId === 5 && new Date().getTime() - new Date(order.updatedAt).getTime() <= 86400000,
												).length
										  } order that was cancelled...`
									: ""}
							</div>
						</Row>
						<hr className="my-1" />

						<div className="scroll-Notifi">
							{orders.length > 0 ? (
								orders?.map((orderItem) => (
									<Link key={orderItem.id} to={`/admin/order/${orderItem.id}`} style={{ textDecoration: "none" }}>
										<Row className="navbarSelect-Color mx-0 p-2">
											<Col xs={2} className="d-flex justity-content-center align-items-center">
												<Icon icon={orderItem.orderStatusId === 5 ? "xmark" : "cart-shopping"} />
											</Col>
											<Col xs={9}>
												<b className="text-overflow-1-line">
													{language ? `Khách hàng: ${orderItem.fullname}` : `Customer: ${orderItem.fullname}`}
												</b>
												<div>
													{language
														? moment(orderItem.updatedAt).format("DD-MM-YYYY, hh:mm")
														: moment(orderItem.updatedAt).format("YYYY-MM-DD, hh:mm a")}
												</div>
											</Col>
										</Row>

										<hr className="my-1" />
									</Link>
								))
							) : (
								<div className="text-center">{language ? "Không có đơn hàng mới nào" : "No new orders found"}</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default OrderNotification;
