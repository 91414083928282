import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const SizeEditModal = ({ handleCloseModal, showModal, sizeData }) => {
	const language = useSelector((state) => state.language.isVietnamese);

	const [size, setSize] = useState(sizeData);

	const [errMessage, setErrMessage] = useState({
		id: undefined,
		nameVi: undefined,
		nameEn: undefined,
	});

	const handleInput = (e) => {
		let field = e.target.name;
		let value = e.target.value;

		setSize({ ...size, [field]: value });
		setErrMessage({ ...errMessage, [field]: undefined });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		await axios.put(`/size/update`, {
			id: size.id,
			nameVi: size.nameVi,
			nameEn: size.nameEn,
		}).then((res) => {
			if (res.status === 200) {
				toast(res.data.messages, { type: "success", autoClose: 1000, });
				handleCloseModal(true);
			}
		}).catch((e) => {
			setErrMessage(e.response?.data.messages);
		});
	};

	return (
		<Modal show={showModal} onHide={() => handleCloseModal(false)} backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>{language ? "Cập nhật kích thước " : "Update size"}</Modal.Title>
			</Modal.Header>
			<Form onSubmit={handleSubmit} >
				<Modal.Body>
					<Form.Floating className="mb-2">
						<Form.Control
							className={errMessage.nameVi ? "is-invalid" : ""}
							type="text"
							placeholder={language ? 'Tên kích thước Tiếng Việt' : 'Vietnamese size name'}
							name="nameVi"
							value={size.nameVi}
							onChange={handleInput}
						/>

						<Form.Label>
							{language ? 'Tên kích thước Tiếng Việt' : 'Vietnamese size name'}<i className="text-danger">*</i>
						</Form.Label>
						{errMessage.nameVi ? <i className="text-danger">{errMessage.nameVi}</i> : <></>}
					</Form.Floating>

					<Form.Floating>
						<Form.Control
							className={errMessage.nameEn ? "is-invalid" : ""}
							type="text"
							placeholder={language ? 'Tên kích thước Tiếng Anh' : 'English size name'}
							name="nameEn"
							value={size.nameEn}
							onChange={handleInput}
						/>

						<Form.Label>
							{language ? 'Tên kích thước Tiếng Anh' : 'English size name'}<i className="text-danger">*</i>
						</Form.Label>
						{errMessage.nameEn ? <i className="text-danger">{errMessage.nameEn}</i> : <></>}
					</Form.Floating>
				</Modal.Body>

				<Modal.Footer>
					<Button type="button" variant="secondary" onClick={() => handleCloseModal(false)}>
						<FontAwesomeIcon icon={faTimes} />  {language ? "Đóng" : "Close"}
					</Button>
					<Button type="submit" variant="success">
						<FontAwesomeIcon icon={faCheck} /> {language ? "Cập nhật" : "Update"}
					</Button>
				</Modal.Footer>
			</Form>

		</Modal >
	);
};

export default SizeEditModal;
