import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import UserLayout from "../layouts/user/MasterLayout";

import Home from "../pages/user/Home";
import Category from "../pages/user/Category";
import Product from "../pages/user/Product";
import Cart from "../pages/user/Cart";
import Login from "../pages/user/auth/Login";
import Register from "../pages/user/auth/Register";
import OrderHistory from "../pages/user/OrderHistory";
import OrderSuccess from "../pages/user/OrderSuccess";
import Collection from "../pages/user/Collection";
import CollectionDetail from "../pages/user/CollectionDetail";
import Search from "../pages/user/Search";

import AdminLayout from "../layouts/admin/MasterLayout";
import Dashboard from "../pages/admin/Dashboard";

import OrderAdmin from "../pages/admin/Order/Order";
import OrderDetailAdmin from "../pages/admin/Order/OrderDetail";
import CategoryAdmin from "../pages/admin/Category/Category";
import ProductAdmin from "../pages/admin/Product/Product";
import CollectionAdmin from "../pages/admin/Collection/Collection";
import UserAdmin from "../pages/admin/User/User";
import CustomerAdmin from "../pages/admin/User/Customer";
import StaffAdmin from "../pages/admin/Staff/Staff";
import ColorAdmin from "../pages/admin/Color/Color";

import Setting from "../pages/admin/Setting";

// URLs
import { CART, CATEGORY, LOGIN, PRODUCT, REGISTER } from "./routerUrl";
import NotFound from "../pages/NotFound";
import SizeIndex from "../pages/admin/size/SizeIndex";

export default function router() {
	return (
		<>
			<ToastContainer position="top-right" theme="dark" />

			<BrowserRouter>
				<ScrollToTop />
				<Routes>
					<Route path="/" element={<UserLayout />}>
						<Route index element={<Home />} />
						<Route path={LOGIN} element={<Login />} />
						<Route path={REGISTER} element={<Register />} />
						<Route path={`search`} element={<Search />} />
						<Route path={`${CATEGORY}/:slug`} element={<Category />} />
						<Route path={`${PRODUCT}/:slug`} element={<Product />} />
						<Route path={`/collection`} element={<Collection />} />
						<Route path={`/collection/:slug`} element={<CollectionDetail />} />

						<Route path={`/orderHistory`} element={<OrderHistory />} />
						<Route path={`/orderSuccess`} element={<OrderSuccess />} />
						<Route path={CART} element={<Cart />} />

						<Route path="*" element={<NotFound path={"/"} />} />
					</Route>

					<Route path="/admin" element={<AdminLayout />}>
						<Route index element={<Dashboard />} />
						<Route path="dashboard" element={<Dashboard />} />

						<Route path="order" element={<OrderAdmin />} />
						<Route path="order/:id" element={<OrderDetailAdmin />} />
						<Route path="customer" element={<CustomerAdmin />} />

						<Route path="user" element={<UserAdmin />} />
						<Route path="category" element={<CategoryAdmin />} />
						<Route path="product" element={<ProductAdmin />} />
						<Route path="color" element={<ColorAdmin />} />
						<Route path="collection" element={<CollectionAdmin />} />
						<Route path="staff" element={<StaffAdmin />} />
						<Route path="setting" element={<Setting />} />
						<Route path="size" element={<SizeIndex />} />

						<Route path="*" element={<NotFound path={"/admin"} />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</>
	);
}
