import React, { useEffect, useReducer, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";
import Section from "../../components/Section";
import ProductItem from "../../components/ProductItem";
import MoneyFormat from "../../components/MoneyFormat";
import Loading from "../../components/Loading";
import Carousel from "react-multi-carousel";
import Banner from "../../assets/banner/banner.jpg";

import "./style.css";
import { CATEGORY, PRODUCT } from "../../routes/routerUrl";

import { INITIAL_STATE, fetchReducer } from "../../reducer/fetchReducer";
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from "../../reducer/dataAction";

const responsiveBanner = {
	desktop: {
		breakpoint: { max: 3000, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};

const responsiveProduct = {
	large: {
		breakpoint: { max: 3000, min: 1200 },
		items: 4,
		slidesToSlide: 1,
	},
	desktop: {
		breakpoint: { max: 1200, min: 768 },
		items: 4,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 768, min: 576 },
		items: 3,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 576, min: 0 },
		items: 2,
		slidesToSlide: 1,
	},
};

const Home = () => {
	const language = useSelector((state) => state.language.isVietnamese);

	const [category, categoryDispatch] = useReducer(fetchReducer, INITIAL_STATE);
	const [collection, collectionDispatch] = useReducer(fetchReducer, INITIAL_STATE);
	const [popular, popularDispatch] = useReducer(fetchReducer, INITIAL_STATE);

	const [carouselItem, setCarouselItem] = useState([Banner]);

	useEffect(() => {
		document.title = "Bonnie Kids";

		getCategoryHome();
		getPopularProducts();
		getAllCollections();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getCategoryHome = async () => {
		categoryDispatch({ type: FETCH_START });
		await axios
			.get(`/category/home`)
			.then((res) => {
				if (res.data.errCode === 0) {
					categoryDispatch({ type: FETCH_SUCCESS, payload: res.data.categories });

					setCarouselItem(carouselItem.concat([]));
					let bannerArray = [];
					res.data.categories.map((category) => {
						bannerArray = bannerArray.concat(category.images.map((image) => image.src));

						return category;
					});
					setCarouselItem(carouselItem.concat(bannerArray));
				} else {
					categoryDispatch({ type: FETCH_ERROR });
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				categoryDispatch({ type: FETCH_ERROR });
				console.log(err);
			});
	};

	const getPopularProducts = async () => {
		popularDispatch({ type: FETCH_START });

		await axios
			.get(`/popular/product`)
			.then((res) => {
				if (res.data.errCode === 0) {
					popularDispatch({ type: FETCH_SUCCESS, payload: res.data.products });
				} else {
					popularDispatch({ type: FETCH_ERROR });
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				popularDispatch({ type: FETCH_ERROR });
				console.log(err);
			});
	};

	const getAllCollections = async () => {
		collectionDispatch({ type: FETCH_START });
		await axios
			.get(`/combo/home`)
			.then((res) => {
				if (res.data.errCode === 0) {
					collectionDispatch({ type: FETCH_SUCCESS, payload: res.data.combos });
				} else {
					collectionDispatch({ type: FETCH_ERROR });

					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				collectionDispatch({ type: FETCH_ERROR });

				console.log(err);
			});
	};
	// API

	return (
		<>
			<Carousel responsive={responsiveBanner} autoPlay autoPlaySpeed={6000} infinite={true} className="mb-3">
				{carouselItem.map((item, index) => {
					return (
						<div
							key={index}
							style={{
								aspectRatio: "1430/640",
								backgroundImage: `url("${item}")`,
							}}
							className="image-default"
						/>
					);
				})}
			</Carousel>

			<Container>
				{popular.loading ? (
					<Loading />
				) : (
					<Section title={language ? "Mẫu bán chạy nhất" : "Most Popular"}>
						<Carousel responsive={responsiveProduct} removeArrowOnDeviceType={["tablet", "mobile"]} infinite={true} className="mb-5">
							{popular.data.map((popularItem) => {
								return <ProductItem key={popularItem.id} className="me-3" item={popularItem} />;
							})}
						</Carousel>
					</Section>
				)}

				{/* Hide 20240410 */}
				{/* {collection.loading ? (
					<Loading />
				) : (
					<Section title={language ? "Bộ sưu tập" : "Collection"}>
						<Row>
							{collection.data.map((collectionItem) => {
								return (
									<Col xs="6" md="3" key={collectionItem.id}>
										<div
											style={{
												aspectRatio: "600/900",
												backgroundImage: `url("${collectionItem.image}")`,
											}}
											className="image-default mb-3"
										/>

										<div className="fw-bold my-3 text-uppercase">{language ? collectionItem.nameVi : collectionItem.nameEn}</div>

										{collectionItem.productCombos.map((productCombo) => {
											return (
												<Link
													key={productCombo.id}
													to={`${PRODUCT}/${productCombo.product.slug}`}
													style={{
														textDecoration: "none",
													}}
												>
													<Row className="product-item mb-3">
														<Col xs="4" className="d-flex align-items-center">
															<div
																style={{
																	aspectRatio: 1,
																	backgroundImage: `url("${productCombo.product.images[0].src}")`,
																	backgroundSize: "cover",
																}}
																className="image-default"
															/>
														</Col>
														<Col xs="8">
															<div
																style={{
																	height: "3rem",
																}}
																className="responsive-text text-overflow-2-line"
															>
																{language ? productCombo.product.nameVi : productCombo.product.nameEn}
															</div>

															<MoneyFormat
																money={
																	language
																		? productCombo.product.salePriceVi
																			? productCombo.product.salePriceVi
																			: productCombo.product.priceVi
																		: productCombo.product.salePriceEn
																		? productCombo.product.salePriceEn
																		: productCombo.product.priceEn
																}
																className="fw-bold responsive-text text-overflow-1-line"
															/>
														</Col>
													</Row>
												</Link>
											);
										})}
									</Col>
								);
							})}
						</Row>
					</Section>
				)} */}

				{category.loading ? (
					<Loading />
				) : (
					<Section title={language ? "Khám phá thêm" : "Explore more"}>
						<Row className="mb-3 mb-md-5">
							{category.data.map((categoryItem) => {
								return (
									<Col key={categoryItem.id} xs="12" md="3" className="mb-4">
										<Link
											key={categoryItem.id}
											style={{
												textDecoration: "none",
											}}
											to={`${CATEGORY}/${categoryItem.slug}`}
										>
											<div
												style={{
													aspectRatio: "1430/640",
													backgroundImage: `url("${categoryItem?.images[0]?.src}")`,
													backgroundSize: "cover",
												}}
												className="image-default"
											/>
										</Link>
									</Col>
								);
							})}
						</Row>
					</Section>
				)}
			</Container>
		</>
	);
};

export default Home;
