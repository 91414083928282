import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<footer className="py-2 mt-auto">
			<div className="container-fluid px-4">
				<div className="d-flex align-items-center justify-content-between small">
					<div className="text-muted">Copyright by Katec</div>
					<div>
						<Link className="text-muted" to="#!">
							Chính sách bảo mật
						</Link>
						<span className="text-muted">&nbsp;|&nbsp;</span>
						<Link className="text-muted" to="#!">
							Điều khoản
						</Link>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
