import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./slices/languageSlice";
import cartReducer from "./slices/cartSlice";
import userCartReducer from "./slices/userCartSlice";

export default configureStore({
	reducer: {
		language: languageReducer,
		cart: cartReducer,
		userCart: userCartReducer,
	},
});
