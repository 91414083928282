import "./style.css";

import { Link } from "react-router-dom";
import MoneyFormat from "./MoneyFormat";
import { PRODUCT } from "../routes/routerUrl";
import { useSelector } from "react-redux";

const ProductItem = ({ item, style, className }) => {
	const language = useSelector((state) => state.language.isVietnamese);

	return (
		<Link to={`${PRODUCT}/${item.slug}`} style={{ textDecoration: "none" }}>
			<div style={style} className={`product-item position-relative ${className}`}>
				<div
					style={{
						aspectRatio: 1,
						backgroundImage: `url("${item.images[0]?.src}")`,
						backgroundSize: "cover",
					}}
					className="image-default"
				/>

				<div style={{ height: "3rem" }} className="product-title my-3 text-overflow-2-line">
					{language ? item.nameVi : item.nameEn}
				</div>
				<MoneyFormat
					money={
						language
							? (
								!isNaN(item.salePriceVi)
									? item.salePriceVi
									: item.priceVi
							)
							: !isNaN(item.salePriceEn)
								? item.salePriceEn
								: item.priceEn
					}
					className="product-price"
				/>
			</div>
		</Link>
	);
};

export default ProductItem;
